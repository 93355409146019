import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import apiService from "../services/apiServices";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const responsive = {
  desktopXL: {
    breakpoint: { max: 3000, min: 2300 },
    items: 6,
    slidesToSlide: 3, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 2300, min: 1800 },
    items: 5,
    slidesToSlide: 3, // optional, default to 1.
  },
  laptopBig: {
    breakpoint: { max: 1800, min: 1300 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  laptopSmall: {
    breakpoint: { max: 1300, min: 992 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tabletBig: {
    breakpoint: { max: 992, min: 800 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 800, min: 500 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobileBig: {
    breakpoint: { max: 500, min: 400 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobileSmall: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Suggestions = ({ amis, deviceType, loading }) => {
  return (
    <div className="suggestion-wrapper">
      <h4>Suggestions de contacts</h4>
      <div className="suggestion-container">
        { loading ? <Skeleton /> : (
          amis.length !== 0 && (
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={6000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={deviceType}
            >
              {amis ? (
                amis.map((ami, idx) => {
                  return (
                    <a href={"/profile/" + ami._id}>
                      <Card
                        style={{ height: 70 }}
                        className="suggestion-card"
                        key={idx}
                      >
                        <Card.Body>
                          <div className="user-avatar">
                            <img
                              src={ami.img_url ? ami.img_url.data : null}
                              alt="user"
                              className="img-fluid"
                              style={{ height: "auto", width: "100%" }}
                            />
                          </div>
                          <Card.Title style={{ textTransform: "uppercase" }}>
                            {ami.lastName ? ami.lastName : ""}
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </a>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </Carousel>
          )
        ) }
      </div>
    </div>
  );
};

export default Suggestions;
