import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import toast, { Toaster } from "react-hot-toast";
import { Col, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import classnames from "classnames";
import apiService from "../../services/apiServices";
import MesAnnonces from "../../components/profile/MesAnnonces";
import TopHeader from "../../components/menu/TopHeader";
import Footer from "../../components/menu/Footer";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import ProfilePosts from "../../components/profile/ProfilePosts/ProfilePosts";
import ProfileAbout from "../../components/profile/ProfileAbout/ProfileAbout";
import ProfileMedia from "../../components/profile/ProfileMedia/ProfileMedia";
import ProfileFriends from "../../components/profile/ProfileFriends/ProfileFriends";
import ProfileMessaging from "../../components/profile/ProfileMessaging/ProfileMessaging";
import { getMyself } from "../../utils/getSetUser";

const Profile = ({ history, userId }) => {
  const [user, setUser] = useState();
  const [announcer, setAnnouncer] = useState();
  const [originalUser, setOriginalUser] = useState();
  const [publications, setPublications] = useState([]);
  const [userAnnounces, setUserAnnounces] = useState();
  const [userInvitations, setUserInvitations] = useState([]);
  const [tab, setTab] = useState('posts');
  const [disabled, setDisabled] = useState(true);
  const location = useLocation();

  const me = getMyself();

  const appendInput = () => {
    setDisabled(!disabled);
  };

  const onCancel = () => {
    setUser(originalUser);
    appendInput();
    setTab("about");
  };

  const handleEditProfile = () => {
    appendInput();
    setTab("about")
  }

  const getData = useCallback(async () => {
    try {
      const profileData = await apiService.getMyNews(userId);
      setUser(profileData.user);
      setAnnouncer(profileData.announcer);
      setOriginalUser(profileData.user);
      setPublications(profileData.publications);
      setUserInvitations(profileData.invitations);
      if (location.state) {
        setTab(location.state);
        history.replace({ state: null }, '/profile/me');
      }
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  }, [history, location.state, userId])

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  return (
    <div>
      <Toaster position="top-center" />
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        <TopHeader annonceur={announcer} />
        {user ? (
          <div id="content" className="content content-full-width">
            <ScrollUpButton />
            <div className="profile profile-wrapper">
              <div className="profile-header user-header">
                <div className="profile-header-content">
                  <div className="profile-header-img">
                    <img
                      src={originalUser?.img_url ? originalUser?.img_url.data : null}
                      alt="Profile Pic"
                    />
                  </div>
                  <div className="profile-header-info">
                    <h4 className="profile-header-name">
                      <b>
                        {user.username} {user.lastName}
                      </b>
                    </h4>
                    <p className="profile-header-job">
                      {user.activite
                        ? user.activite.metier
                          ? user.activite.metier
                          : ""
                        : ""}
                    </p>
                  </div>
                  {user?._id === me?._id ? (
                    <Col offset={8}>
                      <Button type="default" className="btn-with-icon" onClick={handleEditProfile}>
                          Modifier le profil
                          <EditOutlined/>
                      </Button>
                    </Col>
                  ) : null}
                </div>

                <ul className="profile-header-tab nav nav-tabs">
                  <Nav>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: tab === "posts" })}
                        data-toggle="tab"
                        onClick={() => setTab("posts")}
                      >
                        Publications
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: tab === "about" })}
                        data-toggle="tab"
                        onClick={() => setTab("about")}
                      >
                        Profil
                      </NavLink>
                    </NavItem>
                    {user?._id === me?._id ? (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tab === "announces",
                          })}
                          data-toggle="tab"
                          onClick={() => setTab("announces")}
                        >
                          Petites annonces
                        </NavLink>
                      </NavItem>
                    ) : (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tab === "announces",
                          })}
                          data-toggle="tab"
                          onClick={() => setTab("announces")}
                        >
                          Mes petites annonces
                        </NavLink>
                      </NavItem>
                    )}

                    {user?._id  && (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tab === "messaging",
                          })}
                          data-toggle="tab"
                          onClick={() => setTab("messaging")}
                        >
                          Messagerie
                        </NavLink>
                      </NavItem>
                    )}

                    <NavItem>
                      <NavLink
                        className={classnames({ active: tab === "media" })}
                        data-toggle="tab"
                        onClick={() => setTab("media")}
                      >
                        Média
                      </NavLink>
                    </NavItem>
                    {user?._id === me?._id ? (
                      <NavItem>
                        <NavLink
                          className={classnames({ active: tab === "friends" })}
                          data-toggle="tab"
                          onClick={() => setTab("friends")}
                        >
                          Réseau
                        </NavLink>
                      </NavItem>
                    ) : (
                      <NavItem>
                        <NavLink
                          className={classnames({ active: tab === "friends" })}
                          data-toggle="tab"
                          onClick={() => setTab("friends")}
                        >
                          Mon réseau
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                </ul>
              </div>
              <div className="profile-content">
                {tab === "posts" ? (
                  <ProfilePosts
                    publications={publications}
                    user={user}
                    getdata={getData}
                  />
                ) : tab === "about" ? (
                  <ProfileAbout
                    getdata={getData}
                    user={user}
                    disabled={disabled}
                    onCancel={onCancel}
                    appendInput={appendInput}
                  />
                ) : tab === "announces" ? (
                  <MesAnnonces announces={userAnnounces} user={user} />
                ) : tab === "media" ? (
                  <ProfileMedia publications={publications} getdata={getData} />
                ) : tab === "messaging" ? (
                  <ProfileMessaging user={user} getdata={getData} />
                ) : tab === "friends" ? (
                  <ProfileFriends
                    user={user}
                    invitations={userInvitations}
                    getdata={getData}
                  />
                ) : (
                  <ProfilePosts
                    publications={publications}
                    getdata={getData}
                    user={user}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 400, marginBottom: 400 }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
