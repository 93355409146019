import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import TopHeader from "../../components/menu/TopHeader";
import Footer from "../../components/menu/Footer";
import apiService from "../../services/apiServices";
import { ToastsStore } from "react-toasts";
import StripeBtn from "./stripeBtn";
import PublicationForm from "./PublicationForm";
import queryString from "query-string";

const NewPublication = ({ location }) => {
  const [title, setTitle] = useState(location.state.title || "");
  const [coupon, setCoupon] = useState(false);
  const [id_coupon, setId_coupon] = useState();
  const [errMsgCoupon, setErrMsgCoupon] = useState();
  const [publication, setPublication] = useState();
  const [annonceur, setAnnonceur] = useState(location.state.announcer || {});
  const [user, setUser] = useState(location.state.user);
  const [factureTTC, setFactureTTC] = useState(0);
  const [factureHT, setFactureHT] = useState(0);
  const [bill, setBill] = useState();
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleChange = async (id, value) => {
    setPublication({
      ...publication,
      [id]: value,
    });
  };

  const onChangeShow1 = () => {
    setShow1(false);
  };

  const onChangeShow2 = () => {
    setShow2(false);
  };

  const onChangeImage = async (e) => {
    try {
      const images = e.target.files;
      const image = images[0];

      const reader = new FileReader();
      let img = {
        name: image.name,
        type: image.type,
        lastModified: image.lastModified,
      };
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        img.data = e.target.result;
      };
      setPublication({
        ...publication,
        image: img,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addPublication = async () => {
    const pub = publication;
    pub.title = title;
    pub.annonceur = annonceur;
    await apiService.postSponsPublication(pub);
    ToastsStore.success("La publication a été crée");
  };

  const CalculFacture = (numberOfChecked) => {
    if (publication.date_debut && publication.date_fin && publication.content) {
      const start_date = new Date(publication.date_debut).getTime();
      const end_date = new Date(publication.date_fin).getTime();
      const today = new Date().getTime();
      if (start_date >= today && end_date > start_date) {
        const days = Math.floor(
          (end_date - start_date) / (1000 * 60 * 50 * 24)
        );
        const prixHT = days * 500 + numberOfChecked * 50;
        const prixTTC = prixHT * 1.2;
        setShow(false);
        setFactureHT(prixHT);
        setFactureTTC(prixTTC);
      } else {
        setShow2(true);
      }
    } else {
      setShow1(true);
    }
  };

  const addFacture = async () => {
    const b = bill;
    b.id_annonceur = annonceur;
    b.date = new Date();
    b.amountHt = factureHT / 100;
    b.amountTTC = factureTTC / 100;
    b.publication = publication.title;
    await apiService.postBill(b);
    ToastsStore.success("Votre facture est disponible");
  };

  const codePromo = () => {
    setCoupon(!coupon);
  };

  const reduction = async () => {
    try {
      const coupons = await apiService.getCoupons(`id=${codePromo}`);
      if (coupons.length !== 0) {
        const delai = coupons[0];
        const today = new Date();
        if (delai > today || !coupons[0].expired) {
          const reduction = (factureTTC * coupons[0].percent_off) / 100;
          const newAmount = factureTTC - reduction;
          setFactureTTC(newAmount);
          if (coupons[0].duraction === "once") {
            coupons[0].expired = true;
            await apiService.updateCoupon(coupons[0], coupons[0]._id);
          }
        } else {
          setErrMsgCoupon("Le code promotionnel n'est plus valable");
        }
      } else {
        setErrMsgCoupon("Le code promotionnel est invalide");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const set_coupon = (value) => {
    setId_coupon(value);
    reduction(value);
  };

  return (
    <div>
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        {annonceur ? <TopHeader user={annonceur.user} /> : null}
        <div className="row">
          <div className="col-lg-6">
            <section
              className="pull-center"
              style={{ marginTop: 70, marginLeft: 70 }}
            >
              <div className="row ">
                <div className="col jobs_index_middle_panels pull-center">
                  <div className="card">
                    <div className="card-block">
                      <h4 className="card-title">Nouvelle Publication </h4>
                      <h6 className="card-subtitle mb-2 text-muted">
                        publication sponsorisée
                      </h6>
                    </div>
                    <div className="card-body">
                      <PublicationForm
                        show2={show2}
                        show1={show1}
                        title={title}
                        publication={publication}
                        onChangeShow1={onChangeShow1}
                        onChangeShow2={onChangeShow2}
                        handleChange={handleChange}
                        onChangeImage={onChangeImage}
                        CalculFacture={CalculFacture}
                      />
                      <button
                        className="btn btn-primary btn-md rounded-corner "
                        onClick={addPublication}
                      >
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/*<div className="col-4" >
                        <section className="col pull-center">
                            <div className="card " style={{'marginTop': 70 }}>
                                <div className="card-block">
                                    <h4 className="card-title">Somme à payer</h4>
                                </div>
                                <div className="card-body" >
                                    <div className="row d-flex justify-content-center">
                                    <h3 className="mx-1">Prix HT : </h3>    <h3 style={{color: "rgb(66, 133, 244)"}}>  {factureHT / 100} €</h3>
                                    </div>
                                    <br/>
                                    <div className="row d-flex justify-content-center">
                                    <h3 className="mx-1">Prix TTC :</h3>    <h3 style={{color: "rgb(66, 133, 244)"}}> {factureTTC /100} €</h3>
                                    </div>
                                    <div>
                                        <a href="#" onClick={CodePromo}> * Avez vous un code promotion ?</a>
                                        <br/>
                                        {coupon?
                                            <input type="text" className="form-control w-50" placeholder="code promo.." value={state.id_coupon} onChange={e => setCoupon(e.target.value)}/>
                                        :null}
                                        {(errMsgCoupon!== '') ?
                                            <p style={{color:'red'}}>{errMsgCoupon}</p>
                                            :null}
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <StripeBtn disabled={state.disabled}  facture ={factureTTC} AddPublication={AddPublication} AddFacture={AddFacture}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>*/}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default withRouter(NewPublication);
