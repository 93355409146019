import React  from 'react';
import { Form, Select } from 'antd';
import categories from '../../../../CentresInteret/activites.json'

const { Item } = Form;

const ActivityFormItem = ({ disabled, setCategory }) => {
  return (
    <Item
      label="Catégorie"
      name="activite.categorie"
    >
      <Select
        disabled={disabled}
        allowClear
        onChange={value => setCategory(value || '')}
        options={categories.map((categorie) => ({label: categorie.label, value: categorie.label}))}
      />
    </Item>
  );
};

export default ActivityFormItem;
