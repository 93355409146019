import React, { useState, useEffect } from "react";
import TopHeader from "../../components/menu/TopHeader";
import Footer from "../../components/menu/Footer";
import { Link } from "react-router-dom";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import "react-pagination-library/build/css/index.css";
import apiService from "../../services/apiServices";
import DetailsLibraryModal from "./DetailsLibraryModal";
import DefaultDocImg from "../../assets/img/default-document.jpg";
import activities from "./activities";
import { Select, Input, Row, Col } from 'antd';
import { getMyself } from "../../utils/getSetUser";

const Library = () => {
  const [user, setUser] = useState();
  const [documents, setDocuments] = useState();
  const [allDocuments, setAllDocuments] = useState();
  const [searchDoc, setSearchDoc] = useState();
  const [category, setCategory] = useState();
  const [announcer, setAnnouncer] = useState();
  
  useEffect(() => {
    (async () => {
      try {
        const me = getMyself();
        const docs = await apiService.getDocuments(`status=PUBLISH`);
        const annonceurs = await apiService.getAnnonceurs(`user=${me._id}`);
        setUser(me);
        setDocuments(docs);
        setAllDocuments(docs);
        if (annonceurs.length > 0) {
          setAnnouncer(annonceurs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    const docBySearch = (
      searchDoc
        ? allDocuments.filter((doc) => doc.title.includes(searchDoc))
        : allDocuments
    );

    const docByCategory = (
      category
        ? docBySearch.filter((doc) => doc.category && doc.category.includes(category))
        : docBySearch
    );

    setDocuments(docByCategory);
  }, [searchDoc, category, allDocuments]);

  return (
    <div>
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        <TopHeader user={user} annonceur={announcer} />
        <div
          id="content"
          className="profile-content content-full-width library-wrapper"
        >
          <div className="tab-pane fade in show active" id="profile-Amis">
            <ScrollUpButton />
            <h4 className="m-t-0 m-b-20">Bibliothèque</h4>
            <div
              className="library-content-wrapper"
            >
              {user ? (
                user.activite?.categorie === "distributeur" ? (
                  <div className="row">
                    <div className="col flex-row align-right">
                      <div>
                        <Link
                          to={{
                            pathname: "/NewLibrary",
                            state: { announcer: announcer },
                          }}
                          className="btn btn-ms rounded-corner"
                          style={{ backgroundColor: "#448cb1", color: "#FFF" }}
                        >
                          Nouveau document
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : null
              ) : null}
              <Row gutter={16} style={{ marginBottom: 16 }}>
                <Col>
                  <Input
                    placeholder="Entrez votre recherche"
                    style={{ minWidth: 200 }}
                    allowClear
                    onChange={(input) => setSearchDoc(input.target.value)}
                  />
                </Col>
                <Col>
                  <Select
                    allowClear
                    showSearch
                    style={{ minWidth: 200 }}
                    placeholder={"Catégorie"}
                    onChange={(input) => setCategory(input)}
                    options={activities.map((activity) => ({label: activity, value: activity}))}
                  />
                </Col>
              </Row>
              <div className="row" style={{ padding: '0 10px', alignItems: 'stretch', rowGap: 20 }}>
                {documents ? (
                  documents.length !== 0 ? (
                    documents.map((document, i) => {
                      return (
                        <div key={i} className="media-left col-xxl-2 col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-b-2">
                          <div className="library-document-card">
                            <div className="media media-xs">
                              <div
                                className="media-body valign-middle"
                              >
                                <img
                                  src={DefaultDocImg}
                                  alt=""
                                />
                              </div>
                              <div className="document-info">
                                <p className="document-name">
                                  {document.title}
                                </p>
                                <p className="document-description">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua. Ut
                                  enim ad minim veniam, quis nostrud exercitation
                                  ullamco laboris nisi ut aliquip ex ea commodo
                                  consequat.
                                </p>
                                <div
                                  className="overflow-visible"
                                >
                                  <DetailsLibraryModal
                                    user={user}
                                    document={document}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="d-flex justify-content-center">
                      <h6>Aucun document trouvé</h6>
                    </div>
                  )
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Library;
