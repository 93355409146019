import React from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

const AddressNumberFormItem = () => {
  return (
    <Item
      name="address.number"
      label="Numéro"
      wrapperCol={{ span: 15 }}
      labelCol={{ span: 9 }}
    >
      <Input type="number"/>
    </Item>
  );

};

export default AddressNumberFormItem;