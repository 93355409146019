import React, { useState, useEffect } from "react";
import TopHeader from "../../components/menu/TopHeader";
import Footer from "../../components/menu/Footer";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import { Link, withRouter } from "react-router-dom";
import { Card, Carousel } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { ToastsStore } from "react-toasts";
import apiService from "../../services/apiServices";

const DetailsAnnounce = ({ match, history }) => {
  const [announce, setAnnounce] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    (async () => {
      try {
        const usr = await apiService.getUser(apiService.getUserId());
        const petitesAnnonce = await apiService.getPetitesAnnonce(
          match.params.id
        );
        setAnnounce(petitesAnnonce);
        setUser(usr);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [match.params.id]);


  const signalAnnounce = async () => {
    try {
      const newAnnounce = announce;
      newAnnounce.signalee++;
      await apiService.updatePetiteAnnonceDetails(newAnnounce, newAnnounce._id);
      ToastsStore.success("Vous avez signalé cette annnonce");
    } catch (error) {
      ToastsStore.error("Votre annonce n'a pas été émis");
    }
  };

  const deleteAnnounce = async () => {
    try {
      const announceId = match.params.id;
      await apiService.deletePetitesAnnonce(announceId);
      ToastsStore.success("L'annonce est supprimée");
      setTimeout(() => {
        history.push("/announces");
      }, 0);
    } catch (error) {
      ToastsStore.error("L'annonce n'a pas été supprimée");
    }
  };

  return (
    <div>
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        {user ? <TopHeader user={user} /> : null}
        <Container
          fluid
          style={{
            height: "calc(100vh - 100px)",
            maxHeight: "calc(100vh - 100px)",
            padding: '20px 5vw',
            overflow: "auto",
          }}
        >
          <ScrollUpButton />
          <h3 style={{ color: '#fff', marginBottom: 20 }}>
            Détail de l'annonce
          </h3>
          {announce ? (
            <div style={{ display: "flex", flexWrap: 'wrap' }}>
              <Col xs={12} sm={12} md={9} xl={8}>
                <Card
                  className="announce-detail-card"
                >
                  <Carousel>
                    {announce?.imgurls ? (
                      announce?.imgurls.map((url, i) => {
                        return (
                          <Carousel.Item key={i}>
                            <img
                              width="100%"
                              height="auto"
                              className="d-block w-100"
                              src={url ? url.data : null}
                              alt="First slide"
                            />
                          </Carousel.Item>
                        );
                      })
                    ) : (
                      <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: 50 }}
                      >
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </Carousel>
                  <Card.Body className="text-left">
                    <Card.Title>
                      <h4>{announce?.title}</h4>
                      <p>
                        {announce?.price ? announce?.price : 0}€
                      </p>
                    </Card.Title>
                    <Card.Text>
                      <h5 className="announce-date">
                        {new Date(announce?.date_creation).toLocaleString()
                          ? new Date(announce?.date_creation).toLocaleString()
                          : ""}
                      </h5>  
                      {announce?.duration &&
                        <h5>Durée: <span>{announce.duration}</span> jours</h5>
                      }
                      <div className="pull-center announce-description">
                        <p className="text-justify">{announce?.content}</p>
                      </div>
                      {announce.address && (
                        <h5 className="announce-address">
                          <i class="fa fa-map-marker 2x" />
                          {Object.keys(announce.address).map(element => {
                            return `${announce.address[element]}${element !== 'country' && element !== 'number' ? ', ' : ' '}`
                          })}
                        </h5>
                      )}
                    </Card.Text>
                    <div className="row">
                      {announce?.id_user ? (
                        announce?.id_user?._id === user?._id ? (
                          <center>
                            <Button
                              type="button"
                              variant="danger"
                              className="btn-circle"
                              onClick={deleteAnnounce}
                            >
                              Supprimer
                            </Button>
                            <Link to={"/editannounce/" + announce?._id}>
                              <Button
                                type="button"
                                className="btn rounded-corner blue-btn"
                              >
                                Editer
                              </Button>
                            </Link>
                          </center>
                        ) : null
                      ) : null}
                      <button
                        type="button"
                        className="btn rounded-corner blue-btn"
                        onClick={signalAnnounce}
                      >
                        <i className="fa fa-flag 2x" style={{ marginRight: 8 }} />
                        Signaler l'annonce
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={3} xl={2}>
                <Card className="announce-detail-user">
                  <Card.Body>
                    <Card.Title>
                      <div className="user-card">
                        <i className="fa fa-user fa-3x" />
                        {" "}
                        {announce?.id_user
                          ? announce?.id_user?.username
                          : null}{" "}
                        {announce?.id_user ? announce?.id_user.lastName : null}
                      </div>
                    </Card.Title>
                    <Card.Text></Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </Container>
        <Footer />
      </div>
    </div>
  );
};

export default withRouter(DetailsAnnounce);
