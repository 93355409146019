import React  from 'react';
import { Form, Select } from 'antd';

const { Item } = Form;

const CategoryFormItem = () => {

  const categories = [
    {value: "materiaux", label: "Matériaux"},
    {value: "vehicules", label: "Véhicules"},
    {value: "services", label: "Services"},
    {value: "outilages", label: "Outilages"}
  ];
  return (
    <Item
      name="category"
      label="Catégorie"
      rules={[
        {
          required: true,
          message: "Merci de choisir une catégorie."
        }
      ]}
    >
      <Select
        options={categories.map(category => ({"value": category.value, "label": category.label}))}
      />
    </Item>
  );
};

export default CategoryFormItem;
