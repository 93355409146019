import React, { useState } from "react";
import { Form, Input, Row, Col, Select, Alert } from "antd";
import interets from "../../components/CentresInteret/activites";

const PublicationForm = ({
  handleChange,
  handleChangeParent,
  handleChangeChild,
  title,
  perimetre,
  CalculFacture,
  onChangeImage,
  show1,
  show2,
  onChangeShow1,
  onChangeShow2,
  content,
  image,
  selectedOption,
  childValue,
  isImgLoaded,
}) => {
  const [nowDate, setNowDate] = useState(new Date());
  return (
    <Form>
      <Form.Item label="Titre">
        <Input
          id="title"
          value={title}
          onChange={(e) => handleChange(e.target.id, e.target.value)}
        />
      </Form.Item>

      <Form.Item label="Contenu">
        <Input.TextArea
          rows={3}
          placeholder="Ecrivez votre description ici..."
          id="content"
          value={content}
          onChange={(e) => handleChange(e.target.id, e.target.value)}
        />
      </Form.Item>

      <Form.Item label="Périmètre">
  <Select
    allowClear
    showSearch
    style={{ minWidth: 200 }}
    placeholder={"Périmètre"}
    onChange={handleChangeParent}
    CalculFacture={CalculFacture}
    options={interets}
    value={childValue}
  />
  {selectedOption && selectedOption.children && selectedOption.children.length > 0 && (
    <Select
      allowClear
      showSearch
      style={{ minWidth: 200 }}
      placeholder={"Périmètre"}
      onChange={handleChangeChild}
      CalculFacture={CalculFacture}
      options={selectedOption.children.map(child => ({ label: child.label, value: child.value }))}
      value={childValue[1]}
    />
  )}
</Form.Item>


      <Form.Item label="Image">
        <input
          hidden
          type="file"
          id="image"
          name="image"
          className="form-control rounded-corner"
          onChange={(e) => onChangeImage(e)}
        />
        <label
          className="btn blue-btn f-s-12 rounded-corner"
          htmlFor="image"
          style={{ marginLeft: 0 }}
        >
          <i className="material-icons">photo_camera</i>
          Photo
        </label>
        {image && image.data && isImgLoaded && (
          <img
            style={{ height: "100px" }}
            className="preview-img"
            src={image.data}
            onLoad={() => console.log('image loaded')}
          ></img>
        )}
      </Form.Item>

      {show1 ? (
        <Alert variant="danger" onClose={onChangeShow1} dismissible>
          Veuillez remplir tous les champs obligatoires !
        </Alert>
      ) : null}
    </Form>
  );
};

export default PublicationForm;
