import React from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;
const { TextArea } = Input;

const DescriptionFormItem = ({ disabled }) => {
  return (
    <Item
      name="description"
      label="Description"
    >
      <TextArea disabled={disabled}/>
    </Item>
  );
};

export default DescriptionFormItem;