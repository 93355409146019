import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';

const FacturesDataTable = ({ columns, data }) => {
  const useStyles = makeStyles((theme) => ({
    tableContainer: {
      overflowX: 'auto',
      [theme.breakpoints.down('md')]: {
        overflowX: 'scroll',
      },
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.tableContainer}>
      <Paper>
        <MaterialTable title="Tableau des factures" columns={columns} data={data} />
      </Paper>
    </div>
  );
};

export default FacturesDataTable;
