const addressList = [
    {
        title: "Fédération Française du Bâtiment",
        address: "33 Avenue Kléber, Paris, France",
        tel: "01 40 69 51 00",
        mail: "cuchevala@national.ffbatiment.fr",
        link: "www.ffbatiment.fr"
    },
    {
        title: "Capeb",
        address: "2 rue Beranger, 75140, Paris CEDEX 03",
        tel: "01 53 60 50 00",
        mail: "capeb@caped.fr",
        link: "www.capeb.fr"
    },
    {
        title: "Chambre des Métiers",
        address: "72 rue de Reuilly, 75592, Paris CEDEX 12",
        tel: "01 53 33 53 33",
        mail: "cuchevala@national.ffbatiment.fr",
        link: "www.cma-paris.fr"
    },
    {
        title: "Pro BTP",
        address: "7 rue du Regard 75006 Paris",
        tel: "04.92.38.33.30",
        mail: "-",
        link: "www.probtp.com"
    },
    {
        title: "Prevention BTP OPPBTP",
        address: "25 Avenue Du General Leclerc, 92100, Boulogne-Billancourt",
        tel: "08 25 03 50 50",
        mail: "-",
        link: "www.oppbtp.com"
    },
];

export default addressList;