import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import apiService from "../services/apiServices";
import toast, { Toaster } from "react-hot-toast";

const PostShare = ({ publication, user, getdata, id }) => {
  const [userConnected, setUserConnected] = useState(user);
  const [publicationUser, setPublicationUser] = useState();
  const [comment, setComment] = useState();

  useEffect(() => {
    (async () => {
      try {
        const usr = await apiService.getUser(publication?.id_publication?.user);
        setPublicationUser(usr);
        const getUserId = apiService.getUserId();
        const getUser = await apiService.getUser(getUserId);
        setUserConnected(getUser._id);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [publication]);

  const addLike = async () => {
    try {
      const pub = publication;
      const notif = {};
      const like = {};
      like.publication = pub;
      like.user = userConnected;
      notif.user = pub.user;
      notif.like = like;
      pub.likes.push(userConnected._id);
      await apiService.updateShare(pub, pub._id);
      await apiService.postNotification(notif);
      await getdata();
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  const addComment = async () => {
    try {
      const pub = publication;
      const newComment = {};
      const notif = {};
      newComment.id_user = userConnected;
      newComment.content = comment;
      newComment.creationDate = new Date();
      notif.user = pub.user;
      pub.comments.push(newComment);
      await apiService.updateShare(pub, pub._id);
      await apiService.postNotification(notif);
      setComment('');
      await getdata();
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  const deleteComment = async (id) => {
    try {
      const comments = publication.comments;
      comments.map((comment, index) => {
        if (comment._id === id) {
          comments.splice(index, 1);
        }
      });
      await apiService.updateShare(publication, publication._id);
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  const formatDate = () => {
    const fullDate = new Date(publication.creationDate);
    try {
      if (fullDate.getMinutes() < 10) {
        return `${fullDate.getHours()}:0${fullDate.getMinutes()}`;
      }
      return `${fullDate.getHours()}:${fullDate.getMinutes()}`;
    } catch (error) {
      console.log(error);
    }
  };

  const deletePublication = async () => {
    try {
      await apiService.deleteShare(id);
      await getdata();
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  const imageUrl = (url) => {
    const img = url.split("watch?v=");
    return `https://img.youtube.com/vi/${img[1]}/mqdefault.jpg`;
  };

  return (
    <li>
      <Toaster position="top-center" />
      <div className="timeline-icon">
        <span>&nbsp;</span>
      </div>

      {/* begin timeline-body */}
      {publicationUser ? (
        <div className="timeline-body">
          <div className="timeline-header">
            <span>
              <span className="userimage">
                <img
                  src={
                    publication.id_user.img_url
                      ? publication.id_user.img_url.data
                      : null
                  }
                  alt="user"
                />
              </span>
              <span className="username">
                <Link to="">
                  {" "}
                  {publication.id_user.lastName} {publication.id_user.username}{" "}
                </Link>
              </span>
              &nbsp;a partagé la publication de
              <small />
              &nbsp;
              <b style={{ color: "#0100B9", textTransform: "uppercase" }}>
                {publicationUser.lastName} {publicationUser.username}{" "}
              </b>
              <span className="post-date">
                <span>{new Date(publication.creationDate).toLocaleDateString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                <span>{formatDate()}</span>
              </span>
            </span>
           
 
            {/* <span className="pull-right text-muted">{nbOfViews} vue{(nbOfViews > 1) ? "s" : ""}</span> */}
            {userConnected === publication.id_user._id ? (
              <button
                className="pull-right btn-outline-light "
                onClick={deletePublication}
              >
                <i className="fa fa-trash 2x"></i>{" "}
              </button>
            ) : null}
          </div>

          <div className="timeline-content">
            <p>{publication.id_publication.content}</p>
            {publication.id_publication.image ? (
              <div
                className="col-md-8 col-sm-4 m-b-2"
                style={{ paddingLeft: 0 }}
              >
                <img
                  src={
                    publication.id_publication.image
                      ? publication.id_publication.image.data
                      : null
                  }
                  className="width-full"
                  alt="media-element"
                />
              </div>
            ) : publication.id_publication.url ? (
              <div
                className="col-md-8 col-sm-4 m-b-2"
                style={{ paddingLeft: 0 }}
              >
                <a href={publication.id_publication.url} data-lity>
                  <img
                    src={imageUrl(publication.id_publication.url)}
                    className="width-full"
                    alt="media-element"
                  />
                </a>
              </div>
            ) : null}
          </div>

          <div className="timeline-likes">
            <div className="stats-right">
              <span className="stats-text">
                {publication.comments.length} Commentaires
              </span>
              <span className="stats-text">
                {publication.id_publication.nbViews} vue
                {publication.id_publication.nbViews > 1 ? "s" : ""}
              </span>
            </div>
            <div className="stats-left">
              <div className="stats">
                <a onClick={() => addLike(publication._id)}>
                  <i className="fa fa-thumbs-up fa-fw fa-lg m-r-3" />
                </a>
                <span className="stats-total">
                  {publication.likes ? publication.likes.length : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="timeline-comment-box">
            <div className="input">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control rounded-corner"
                  placeholder="Rédiger un commentaire..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <span className="input-group-btn p-l-10">
                  <button
                    className="btn f-s-12 rounded-corner yellow-btn"
                    onClick={addComment}
                  >
                    Commenter
                  </button>
                </span>
              </div>
            </div>
            <div>
              {publication.comments
                ? publication.comments.map((comment, i) => {
                    return (
                      <div key={i} className="timeline-comment">
                        <div className="user">
                          <img
                            src={
                              publication.id_user.img_url
                                ? publication.id_user.img_url.data
                                : null
                            }
                            alt="user"
                          />
                        </div>
                        
                        <i className="timeline-comment-user">
                          {comment.id_user ? comment?.id_user?.lastName : null}{" "}
                          {comment.id_user ? comment?.id_user?.username : null} :{" "}
                        </i>
                        <p>{comment.content}</p>
                        {userConnected === comment?.id_user?._id ? (
                          <button
                            className="pull-right btn-link"
                            onClick={() => deleteComment(comment._id)}
                          >
                            <i className="fa fa-trash 2x"></i>{" "}
                          </button>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {/* end timeline-body */}
    </li>
  );
};

export default PostShare;
