import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { ToastsStore } from "react-toasts";
import apiService from "../../services/apiServices";
import Modal from "react-bootstrap/Modal";
import DefaultDocImg from "../../assets/img/default-document.jpg";

const DetailsLibraryModal = ({ document, user }) => {
  const [open, setOpen] = useState();

  const closeModal = () => {
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const deleteDocument = async () => {
    try {
      await apiService.deleteDocument(document._id);
      ToastsStore.error("Le document a été supprimé");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <button
        type="button"
        className="btn blue-btn rounded-corner"
        onClick={showModal}
        style={{ float: 'right', marginBottom: 16 }}
      >
        Consulter
      </button>

      <Modal show={open} onHide={closeModal} aria-labelledby="ModalHeader" className="library-modal">
        <Modal.Header closeButton>
          <Modal.Title id="ModalHeader">
            {document.title || null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="media-body valign-middle">
            <img src={DefaultDocImg} alt="" />
          </div>
          <div className="text-container">
            <Link to={"/contact/" + document.id_annonceur?._id}>
              {document.id_annonceur ? document.id_annonceur.nom : null}
            </Link>
            <p style={{ marginTop: 10 }}>{new Date(document.date_creation).toLocaleDateString()}</p>
            <p>{document.description || null}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link
            to={document.fileUrl || null}
            target="_blank"
            className="btn btn-primary rounded-corner blue-btn"
            download
          >
            Télécharger &nbsp; <i className="fa fa-download"></i>
          </Link>
          {document.id_user?._id === user._id ? (
            <button
              className="btn btn-danger rounded-corner"
              onClick={deleteDocument}
            >
              {" "}
              Supprimer &nbsp; <i className="fa fa-trash"></i>
            </button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withRouter(DetailsLibraryModal);
