import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import apiService from "../../services/apiServices";
import { ToastsStore } from "react-toasts";
import TopHeader from "../../components/menu/TopHeader";
import Footer from "../../components/menu/Footer";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import MaViePrivee from "../../components/Settings/MaViePrivee";
import Documents from "../../components/Settings/Documents";
import Desinscription from "../../components/Settings/Desinscription";
import Paiement from "../../components/Settings/Paiement";

const Settings = ({ history }) => {
  const [user, setUser] = useState({});
  const [cgv, setCgv] = useState(null);
  const [announcer, setAnnouncer] = useState(null);
  const [factures, setFactures] = useState([]);
  const [tab, setTab] = useState("changementDeMotDePasse");

  useEffect(() => {
    (async () => await getdata())();
  }, []);

  const getdata = async () => {
    try {
      const usr = await apiService.getUser(apiService.getUserId());
      usr.date_naissance = new Date(usr.date_naissance).toLocaleDateString();
      const annonceurs = await apiService.getAnnonceurs(`user=${usr._id}`);
      if (annonceurs.length > 0) {
        const bills = await apiService.getBills(
          `id_annonceur=${annonceurs[0]._id}`
        );
        setAnnouncer(annonceurs[0]);
        setFactures(bills);
      }
      setUser(usr);
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = async (id, value) => {
    setUser({
      ...user,
      [id]: value,
    });
    setTab("changementDeMotDePasse");
  };

  const onCancel = async () => {
    history.push("/");
  };

  return (
    <div>
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        {user ? <TopHeader user={user} /> : null}
        {user ? (
          <div id="content" className="content content-full-width">
            <ScrollUpButton />
            <div className="profile">
              <div className="profile-header user-header">
                {/* <div className="profile-header-cover" /> */}
                <div className="profile-header-content">
                  <div className="profile-header-img">
                    <img
                      src={user.img_url ? user.img_url.data : null}
                      alt="Profile Pic"
                    />
                  </div>
                  <div
                    className="profile-header-info"
                    style={{ marginLeft: 110, marginTop: 15 }}
                  >
                    <h4 className="profile-header-name">
                      <b>
                        {user.username} {user.lastName}
                      </b>
                    </h4>
                    <p style={{ margin: "8px 0", fontStyle: "italic" }}>
                      {user.activite ? user.activite.categorie : ""}
                    </p>
                  </div>
                </div>

                <ul
                  className="profile-header-tab nav nav-tabs"
                  style={{ width: "100%" }}
                >
                  <Nav>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: tab === "changementDeMotDePasse",
                        })}
                        data-toggle="tab"
                        onClick={() => setTab("changementDeMotDePasse")}
                      >
                        CHANGEMENT DE MOT DE PASSE
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: tab === "desinscription",
                        })}
                        data-toggle="tab"
                        onClick={() => setTab("desinscription")}
                      >
                        DESINSCRIPTION
                      </NavLink>
                    </NavItem>
                  </Nav>
                </ul>
              </div>
              <div className="profile-content">
                {/* Affichage de la bonne tab */}
                {/*this.state.tabContent*/}
                {tab === "changementDeMotDePasse" ? (
                  <MaViePrivee user={user} />
                ) : (
                  <Desinscription user={user} />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 400, marginBottom: 350 }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

Settings.defaultProps = {
  id: null,
};

Settings.propTypes = {
  id: PropTypes.string,
};

export default withRouter(Settings);
