import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { notification } from 'antd';
import StripeBtn from "../../NewPublicationSponsorise/stripeBtn";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PaymentGocardless from "../../Payment/PaymentGocardless";
import { useLocation, useHistory } from "react-router-dom";
import { completeRedirectionFlow, pay } from "../../Payment/GCUtils";
import apiService from "../../../services/apiServices";
import { ToastsStore } from "react-toasts";

const subscriptionTypes = [
  {
    denomination: "Full Pack",
    label: "Abonnement de 12 mois",
    description: "Abonnement Full Pack",
    avantage_list: [
      "Logo de votre entreprise dans notre section 'Nos partenaires premiums'",
      "Bibliothèque",
      "Annonces",
      "Accès à votre espace annonceur"
    ],
    price: 1200,
    id: 0,
    duration: 12,
  },
  {
    denomination: " Abonnement 12 mois",
    label: "Abonnement périodique",
    description: "Abonnement système d'annonceur-périodicité 1 an",
    avantage_list: [
      "Publications sponsorisées",
      "Accès à la bibliothèque produit pendant 12 mois",
      "Accès à votre espace annonceur"
    ],
    price: 700,
    id: 1,
    duration: 12,
  },
  {
    denomination: "Abonnement 6 mois",
    label: "Abonnement périodique",
    description: "Abonnement système d'annonceur-périodicité 6 mois",
    avantage_list: [
      "Publications sponsorisées",
      "Accès à la bibliothèque produit pendant 6 mois",
      "Accès à votre espace annonceur"
    ],
    price: 300,
    id: 2,
    duration: 6,
  },
  {
    denomination: "Abonnement 3 mois",
    label: "Abonnement périodique",
    description: "Abonnement système d'annonceur-périodicité 3 mois",
    avantage_list: [
      "Publications sponsorisées",
      "Accès à la bibliothèque produit pendant 3 mois",
      "Accès à votre espace annonceur"
    ],
    price: 200,
    id: 3,
    duration: 3,
  },

  /*    {
        denomination: 'Formule un mois gratuit',
        label: 'Formule 1 mois gratuit',
        description: 'Formule un mois gratuit avec pre abonnement de 3 mois',
        avantage_list: ["Publications sponsorisées", "Accès à la bibliothèque produit pendant 3 mois"],
        price: 200,
        id: 4,
        duration: 4,
    },*/
];

/**
 *
 * @param {*} user The current user
 * @returns React object
 */
const Subscription = ({ announcer }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const amount = params.get("amount");
  const label = params.get("label");
  const interval = params.get("interval");
  const redirectFlow = params.get("redirect_flow_id");
  const [discount, setDiscount] = useState(0);
  const [subscription, setSubscription] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [plans, setPlans] = useState([]);
  const [message, setMessage] = useState('');
  const [discountMsg, setDiscountMsg] = useState("");
  const [coupon, setCoupon] = useState(null);
  const [total, setTotal] = useState(amount);
  const subId = announcer?.subscription?.id;
  const announcerId = announcer?._id;
  const [isLoading, setIsLoading] = useState(false);
  const [listOfFeatured, setListOfFeatured] = useState([]);
  const [invoicePdfLinks, setInvoicePdfLinks] = useState([]);
  const [selected, setSelected] = useState(
    announcer && announcer.subscription && announcer.subscription.is_subscribe
      ? subscriptionTypes.filter(
          (type) =>
            type.denomination === announcer.subscription.subscription_type
        )[0].id
      : -1
  );
  const history = useHistory();
  const [renewable, setRenewable] = useState(
    announcer.renewable ? announcer.renewable : false
  );
  const [cancel, setCancel] = useState(
    announcer.cancel ? announcer.cancel : false
  );

  useEffect(() => {
    if (amount && label && redirectFlow && announcer) {
      (async () => {
        if (announcer.gcMandate === undefined) {
          const newUser = await completeRedirectionFlow(
            announcer,
            redirectFlow
          );
          await pay("subscription", newUser, total, label, interval);
        }
      })();
    }
    
    const fetchSubscriptions = async () => {
      try {
        const response = await apiService.fetchSubscriptions();
        setSubscriptions(response.data.data);
        setIsLoading(true);
      } catch (error) {
        console.error("Error fetching subscription:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchSubscriptions();
    if(isLoading == true ) {
    }
    const fetchPlans = async () => {
      try {
        const response = await apiService.fetchPlans();
        setPlans(response.data);
        setIsLoading(true);
      } catch (error) {
        console.error("Error fetching plans:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchPlans();
    
    const fetchSubscription = async () => {
      try {
        const response = await apiService.fetchSubscription(subId);        
        setSubscription(response.data);
        
      } catch (error) {
        console.error('Error fetching subscription:', error);
      }
    };
    fetchSubscription();
    const fetchInvoicePdfLinks = async () => {
      try {
        const response = await apiService.fetchInvoicePdfLinks(announcerId);        
        setInvoicePdfLinks(response.data);
        
      } catch (error) {
        console.error('Error fetching subscription:', error);
      }
    };
    fetchInvoicePdfLinks();
  }, [amount, announcer, interval, label, redirectFlow, total]);
  const remainingDays = () => {
    if (subscription && subscription.current_period_end) {
      const endDate = new Date(subscription.current_period_end * 1000);
      const today = new Date();
      const remainingTime = endDate
      - today;
      const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
      return remainingDays;
    }
    
    return 0;
};
  const handelChange = async () => {
    try {
      setRenewable(!renewable);
      await apiService.updateAnnonceurDetails(
        { renewable: renewable },
        announcer._id
      );
      if (renewable)
        ToastsStore.success(
          "Votre abonnement sera renouvelé automatiquement."
        );
      else
        ToastsStore.success(
          "Votre abonnement sera résilié à la fin de la période."
        );
      window.location.reload(false);
    } catch (e) {
      ToastsStore.error("Erreur ! Veuillez réessayer s'il vous plait.");
      console.error(e);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await apiService.deleteSubscription({ id: subId, announcerId: announcer._id });
      setMessage('Abonnement annulé avec succès.');
      const announcerId = announcer._id;
  
      // Créez une copie de l'objet subscription avec la propriété active définie sur false
      const updatedSubscription = { ...announcer.subscription, status: false };
      await apiService.updateAnnonceurDetails({ subscription: updatedSubscription }, announcerId);
      notification.error({
        message: "Erreur lors de la résilation",
        description: "La résilisation de votre abonnement a échoué, Veuillez réessayer.",
      });
      
    } catch (error) {
      
      notification.success({
        message: "Abonnement résilié !",
        description: "Votre abonnement a été résilié avec succès !",
        onClose: () => {
          history.push("../");
        },
      });
    }
  };  
  

  const dispayUserSubscription = (announcer) => {
    if (announcer && announcer.subscription && remainingDays() > 0) {
      const {
        label,
        amount,
        created_at,
        status,
        start_date,
        interval_unit,
        end_date,
      } = announcer.subscription;
      return (
        <div className="announcer-subscription-card active-subscription">
          {subscription && remainingDays() > 0 && (
            <>
              <Card border="selected">
                <Card.Header>{label} </Card.Header>
                <Card.Body>
                  <div className="card-content">
                    <Card.Title>
                      {" "}
                      {subscription?.plan.amount / 100} €{" "}
                      <span className="text-muted"> (20% TVA)</span>{" "}
                    </Card.Title>
                    <Card.Text>
                      {" "}
                      <strong>Date de création</strong>{" "}:{" "}
                      {new Date(subscription?.current_period_start * 1000).toLocaleDateString()}
                    </Card.Text>
                    {cancel ? (
                      <Card.Text>
                        {" "}
                        <strong> Date d'expiration</strong>{" "}:{" "}
                        {new Date(subscription?.current_period_end * 1000).toLocaleDateString()}
                      </Card.Text>
                    ) : (
                      <Card.Text>
                        {" "}
                        <strong> Date de prélévement</strong>{" "}:{" "}
                        {new Date(subscription.current_period_start * 1000).toLocaleDateString()}
                      </Card.Text>
                    )}
                  </div>
                  {subscription && subscription?.status && subscription?.status !== 'canceled' && (
                  <Button
                    className="rounded-corner yellow-btn"
                    onClick={handleSubmit}
                  >
                    Résilier mon abonnement
                  </Button>
                )}
                <p className="text-muted text-sm-left">
                  <small>
                    * Nous vous informons qu'aucun futur paiement ne sera
                    effectué à partir du moment de la résiliation de votre
                    abonnement et vous allez continuer à profiter de vos
                    avantages sur MySocialBat. Veuillez contacter
                    contact@mysocialbat.fr pour tout renseignement.
                  </small>
                </p>
                {!cancel && (
                  <div
                    style={{ display: 'flex', marginTop: 12, gap: 8 }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={announcer.renewable && announcer.renewable}
                      onChange={() => handelChange()}
                    />
                    <label className="form-check-label text-muted">
                      Renouveler automatiquement
                    </label>
                  </div>
                )}
                </Card.Body>
              </Card>
            </>
          )}
        </div>
      );
    }
  };

  const discountSubscription = async () => {
    try {
      const response = await apiService.getCoupons(
        `id=${coupon}&expired=false`
      );
      const toDay = new Date();
      if (response[0] && new Date(response[0].redeem_by) > toDay) {
        setDiscount(response[0].percent_off);
        const price = subscriptionTypes[selected].price;
        const newAmount = price - (price * response[0].percent_off) / 100;
        setTotal(newAmount);
        setDiscountMsg(
          `Vous profitez de ${response[0].percent_off} % de réduction sur votre abonnement. \n Vous allez payer ${newAmount} € TTC.`
        );
        if (response[0].duration === "once")
          await apiService.updateCoupon({ expired: true }, response[0]._id);
      } else {
        setDiscountMsg("Code incorrect ou expiré");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const gcCancelSubscription = async (value) => {
    try {
      await setCancel(value);
      await setRenewable(!value);
      await apiService.updateAnnonceurDetails(
        { renewable: !value, cancel: value },
        announcer._id
      );
      ToastsStore.success("Votre abonnement a bien été résilié.");
      window.location.reload(false);
    } catch (e) {
      ToastsStore.error("Erreur ! Veuillez réessayer s'il vous plait. ");
      console.error(e);
    }
  };

  const displayItem = (item, fromSelection) => {
    const features = Object.entries(item.metadata)
  .filter(([key, _]) => key.startsWith("featured"))
  .map(([_, value]) => value);
    return (
      <div key={item.id} className={"announcer-subscription-card"}>
        <Card
          border={item.id === selected ? "selected" : "info"}
          className={item.id === 0 && "announcer-subscription-card"}
        >
          <Card.Header>
            {listOfFeatured}
            {item.name}
            <p>{item.id === 0 && item.metadata.label}</p>
          </Card.Header>
          <Card.Body>
            <div className="card-content">
              <Card.Title>
                {item.description}: {item.metadata.price} €
              </Card.Title>
              {features.map((feature, index) => (
                <Card.Text key={index}>{feature}</Card.Text>
              ))}
            </div>
            {fromSelection && item.id !== selected && !subscription  && (
              <Button
                className={"rounded-corner blue-btn"}
                onClick={() => {
                  setSelected(true);
                  setTotal(item.id);
                  history.push(`../subscription/${item.default_price}`)
                }}
              >
                Sélectionner
              </Button>
            ) || fromSelection && item.id !== selected && subscription && subscription?.status == "canceled" && (
              <Button
              className={"rounded-corner blue-btn"}
              onClick={() => {
                setSelected(true);
                setTotal(item.id);
                history.push(`../subscription/${item.default_price}`)
              }}
            >
              Sélectionner
            </Button>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  };

  const displaySelection = () => {
    return (
      <div>
        <h3 className="my-selection">Ma sélection</h3>
        {displayItem(subscriptions[selected], false)}
        <div className="input-group">
          <div>
            <label>Avez-vous un code promo ?</label>
            <span style={{ width: 200 }}>
              <input
                type="text"
                className="form-control"
                placeholder="Entrer le code promo"
                onChange={(e) => setCoupon(e.target.value)}
              />
            </span>
          </div>
          <a
            className="btn rounded-corner blue-btn"
            onClick={discountSubscription}
          >
            {" "}
            Appliquer
          </a>
          <span className="text-muted">
            <strong> {discountMsg}</strong>
          </span>
        </div>
      </div>
    );
  };
  const allInformations = (announcer) => {
    if (
      announcer.nom &&
      announcer.email &&
      announcer.activite &&
      announcer.phone &&
      announcer.siret &&
      announcer.tranche &&
      announcer.website &&
      announcer.description &&
      announcer.pictureUrl &&
      announcer.address.number &&
      announcer.address.street &&
      announcer.address.postcode &&
      announcer.address.city &&
      announcer.address.country
    ) {
      return false;
    } else return true;
  };

  const isSubscribe = announcer.subscription && announcer.subscription && remainingDays() > 0;
  /*  const subscriptionValid = new Date(announcer.subscription.expiredAt )> new Date();*/

  return (
    <div className="announcer-promote-wrapper">
      {allInformations(announcer) && (
        <Row>
          <div className="alert alert-warning" role="alert">
            Attention ! Veuillez remplir toutes les informations requises et
            votre logo pour poouvoir prendre un abonnement sur MySocialBat.
          </div>
        </Row>
      )}
      <Row>
        <Col xs={12} style={{ paddingBottom: 32 }}>
          {isSubscribe && (
            <h3 className="my-selection">Mon abonnement</h3>
          )}
          {dispayUserSubscription(announcer)}
          {selected !== -1 && (
            <>
              {displaySelection()}
              {!isSubscribe && !allInformations(announcer) && (
                <PaymentGocardless
                  amount={
                    total !== "0" ? total : subscriptionTypes[selected].price
                  }
                  label={subscriptionTypes[selected].denomination}
                  interval={subscriptionTypes[selected].duration}
                  type="subscription"
                  user={announcer}
                />
              )}
              {/*{!(isSubscribe||subscriptionValid)&&<StripeBtn subscriptionTypes={subscriptionTypes[selected]} userId={announcer._id}  />}*/}
            </>
          )}
        </Col>
        <Col xs={12}>
          <h3>Liste des abonnements</h3>
          { <Row className="subscriptions-list">{plans  && plans?.data?.map((item) => displayItem(item, true))}</Row> }
        </Col>
      </Row>
    </div>
  );
};

export default Subscription;
