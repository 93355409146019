import React from 'react';
import { Form, Select } from 'antd';
import listCountries from '../../../../utils/listCountries';

const { Item } = Form;

const AddressCountryFormItem = () => {
  return (
    <Item
      name="address.country"
      label="Pays"
      wrapperCol={{ span: 15 }}
      labelCol={{ span: 9 }}
    >
      <Select showSearch style={{ minWidth: 200 }}>
        {listCountries}
      </Select>
    </Item>
  );
};

export default AddressCountryFormItem;