import React from 'react';
import Profile from './Profile';
import apiService from '../../services/apiServices';

const MyProfile = ({ match , history }) => {
  const userId = apiService.getUserId();
  return (
    <Profile match={match} history={history} userId={userId} />
  );
};

export default MyProfile;
