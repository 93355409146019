import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Select } from 'antd';
import TopHeader from "../../components/menu/TopHeader";
import apiService from "../../services/apiServices";
import { ToastsStore } from "react-toasts";
import activities from "./activities";

const NewLibrary = ({ location, history }) => {
  const announcer = location.state.announcer || null;
  const [document, setDocument] = useState();
  const [msgError, setMsgError] = useState();
  const [user, setUser] = useState();
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState("");

const handleFileChange = (file) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    setPdfPreviewUrl(reader.result);
  };
  reader.readAsDataURL(file);
};


  useEffect(() => {
    (async () => {
      try {
        const usr = await apiService.getUser(apiService.getUserId());
        setUser(usr);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const addDocument = async () => {
    try {
      const doc = document;
      doc.id_user = user._id;
      doc.id_annonceur = announcer._id;
      
      if (doc.title.length > 0 && doc.fileUrl && doc.fileUrl !== '') {
        await apiService.postDocument(doc);
        ToastsStore.success("Le document a bien été crée");
        setTimeout(() => {
          history.goBack();
        }, 2000);
      } else {
        setMsgError("Veuillez remplir tous les champs du formulaire");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (id, value, file) => {
    if (id === "fileUrl") {
      handleFileChange(file);
    }
    setDocument({
      ...document,
      [id]: value,
    });
  };
  

  const onCancel = () => {
    history.push("/library");
  };

  return (
    <div
      id="page-container"
      className="page-container page-without-sidebar page-header-fixed page-with-top-menu library-new"
    >
      {user ? <TopHeader user={user} /> : null}
      {announcer ? (
        <div id="content" className="content content-full-width">
          <div className="profile">
            <div className="profile-header">
              <div className="profile-header-content">
                <div className="profile-header-img">
                  <img
                    src={announcer.pictureUrl && announcer.pictureUrl.data}
                    alt="Profile Pic"
                  />
                </div>
                <div className="profile-header-info">
                  <h4 className="profile-header-name">
                    {announcer.nom && announcer.nom}
                  </h4>
                  <p className="profile-header-job">
                    {announcer.activite && announcer.activite}{" "}
                    {announcer.description && announcer.description}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Container>
            <Row>
              <Col xs={12} md={8}>
                <center>
                  <h4>Nouveau document</h4>
                </center>
                <Form>
                  <Form.Group>
                    <Form.Label>
                      Title <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Titre"
                      id="title"
                      value={document ? document.title : ""}
                      onChange={(e) =>
                        handleChange(e.target.id, e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      id="description"
                      value={document ? document.description : ""}
                      onChange={(e) =>
                        handleChange(e.target.id, e.target.value)
                      }
                    />
                  </Form.Group>
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    style={{ minWidth: '100%' }}
                    placeholder={"Catégorie"}
                    onChange={(e) =>
                      handleChange("category", e)
                    }
                    options={activities.map((activity) => ({label: activity, value: activity}))}
                  />
                  <Form.Group>
                    <Form.Label>
                      Document <span className="text-danger">*</span>
                    </Form.Label>
                    <div>
                    <input
                    hidden
                    type="file"
                    multiple={false}
                    id="fileUrl"
                    className="form-control rounded-corner"
                    onChange={(e) =>
                      handleChange(
                        e.target.id,
                        e.target.value,
                        e.target.files && e.target.files[0]
                      )
                    }
                    />
                    <label className="rounded-corner btn blue-btn" htmlFor="fileUrl">
                      Insérer votre document
                    </label>
                </div>
                <div>
                  {pdfPreviewUrl && (
                  <object data={pdfPreviewUrl} type="application/pdf" width="100%" height="600px">
                    <p>Votre navigateur ne supporte pas l'affichage des PDFs. Veuillez télécharger le fichier pour le voir.</p>
                  </object>
                 )}
                </div>
                  </Form.Group>

                  <div className="row">
                    <Button
                      className="rounded-corner btn blue-btn"
                      type="button"
                      block
                      onClick={onCancel}
                    >
                      Annuler
                    </Button>
                    <Button
                      className="rounded-corner btn yellow-btn"
                      type="button"
                      block
                      onClick={addDocument}
                    >
                      Valider
                    </Button>
                  </div>
                  <div className="col-sm-12">
                    <p style={{ color: "red" }}> {msgError} </p>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 350, marginBottom: 350 }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewLibrary;
