import React, { useState, useEffect } from "react";
import TopHeader from "../../components/menu/TopHeader";
import Footer from "../../components/menu/Footer";
import { Row } from "react-bootstrap";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import manomano from "../../assets/img/mano_mano.jpg";
import apiService from "../../services/apiServices";

const MarketPlace = () => {
  const [announcer, setAnnouncer] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    (async () => {
      try {
        const usr = await apiService.getUser(apiService.getUserId());
        const annonceurs = await apiService.getAnnonceurs(`user=${usr._id}`);
        setUser(usr);
        if (annonceurs.length > 0) {
          setAnnouncer(annonceurs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div>
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        <TopHeader user={user} annonceur={announcer} />
        <div
          id="content"
          className="profile-content content-full-width library-wrapper"
        >
          <div className="library-content-wrapper">
            Page en développement.
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MarketPlace;
