import React  from 'react';
import { Form, Select } from 'antd';

const { Item } = Form;

const CivilityFormItem = ({ disabled }) => {
  const titles = ["M.", "Mme", "Autre"];
  return (
    <Item
      name="civilite"
      label="Civilité"
      required
    >
      <Select
        disabled={disabled}
        options={titles.map(title => ({"value": title, "label": title}))}
      />
    </Item>
  );
};

export default CivilityFormItem;
