import React from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

const FirstNameFormItem = ({ disabled }) => {
  return (
    <Item 
      name="username"
      label="Prénom"
      rules={[
        {
          required: true,
          message: "Merci d'entrer votre prénom."
        }
      ]}
    >
      <Input disabled={disabled}/>
    </Item>
  );
};

export default FirstNameFormItem;
