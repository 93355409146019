import React, { useState } from "react";
import { Link } from "react-router-dom";

const menuStyle = {
  position: "absolute",
  willChange: "transform",
  top: "0px",
  left: "0px",
  transform: "translate3d(-136px, 34px, 0px)",
  width: "100px",
};

const FriendsDropdown = ({ show, contact, deleteFriend }) => {
  const [visible, setVisible] = useState(show);

  const toggleNotif = () => {
    setVisible(!visible);
  };

  return (
    <div className="btn-group dropdown">
      {/* <Link to={"/profile/" + contact._id} className="btn btn-link">
        Voir
      </Link> */}
      <div
        data-toggle="dropdown"
        className={
          visible
            ? "btn btn-link dropdown show"
            : "btn btn-link dropdown"
        }
        onClick={() => toggleNotif()}
      >
        <span className="caret"></span>
      </div>
      <ul
        className={
          visible
            ? "dropdown-menu dropdown-menu-right show"
            : "dropdown-menu dropdown-menu-right"
        }
        style={menuStyle}
      >
        <li>
          <span
            className="dropdown-item text-danger"
            onClick={() => deleteFriend(contact)}
          >
            <i
              className="fa fa-trash"
              aria-hidden="true"
              style={{ marginRight: 10 }}
            />{" "}
            Supprimer
          </span>
        </li>
        <li>
          <span className="dropdown-item text-danger">
            <i
              className="fa fa-ban"
              aria-hidden="true"
              style={{ marginRight: 10 }}
            />{" "}
            Bloquer
          </span>
        </li>
      </ul>
    </div>
  );
};

export default FriendsDropdown;
