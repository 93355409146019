import React from 'react';
import { Row, Col } from 'antd';
import AddressAnnexFormItem from './AddressAnnexFormItem/AddressAnnexFormItem';
import AddressCityFormItem from './AddressCityFormItem/AddressCityFormItem';
import AddressCountryFormItem from './AddressCountryFormItem/AddressCountryFormItem';
import AddressNumberFormItem from './AddressNumberFormItem/AddressNumberFormItem';
import AddressPostalCodeFormItem from './AddressPostalCodeFormItem/AddressPostalCodeFormItem';
import AddressStreetFormItem from './AddressStreetFormItem/AddressStreetFormItem';

const AddressFormItem = () => {
  return (
    <>
      <Row>
        <AddressNumberFormItem/>
        <AddressStreetFormItem/>
      </Row>
      <Row>
        <AddressAnnexFormItem/>
        <AddressPostalCodeFormItem/>
      </Row>
      <Row>
        <AddressCityFormItem/>
        <AddressCountryFormItem/>
      </Row>
    </>
  )
};

export default AddressFormItem;
