import React, { useState, useEffect, useCallback } from "react";
import Post from "./Post";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import apiService from "../services/apiServices";
import PostNew from "../components/postAnnouncer/Postnew";
import PostBirthday from "./PostBirthday";
import PostShare from "./PostShares";
import AnnouncerModal from "../pages/Announcer/announcerModal";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Suggestions from "./Suggestions";
import PostS from "./postAnnouncer/Post";
import Skeleton from "react-loading-skeleton";
import toast, { Toaster } from "react-hot-toast";
import "react-loading-skeleton/dist/skeleton.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Wall = ({ user, annonceur, refresh, setRefresh }) => {
  const [loading, setLoading] = useState(true);
  const [publications, setPublications] = useState([]);
  const [birthday, setBirthday] = useState(false);
  const [index, setIndex] = useState(0);
  const [annonceurs, setAnnonceurs] = useState([]);
  const [frequence, setFrequence] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const [sponsPublication, setSponsPublication] = useState([]);
  const [reload, setReload] = useState(false);

  const handleBirthday = useCallback(() => {
    try {
      const date = new Date();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      if (user) {
        const user_month = new Date(user.date_naissance).getMonth() + 1;
        const user_day = new Date(user.date_naissance).getDate();
        if (month === user_month && day === user_day) {
          setBirthday(true);
        }
      }
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  }, [user]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (user && user._id) {
        const datas = await apiService.getNews(user._id);
        setPublications(datas.publications);
        setAnnonceurs(datas.annonceurs);
        setSponsPublication(datas.sponspublications);
        setSuggestions(datas.suggestions);
        setFrequence(datas.frequence);
        handleBirthday();
        setLoading(false);
      }
    } catch (error) {
      if (error.response) toast.error(error.response.status);
      setLoading(false);
    }
  }, [handleBirthday, user]);

  const commonCentresInterets = (param1, param2) => {
    try {
      return param1.some((item) => param2.includes(item));
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData, reload]);

  return (
    <>
      <Toaster position="top-center" />
    <div className="row wall-row">
      <div className="col-xs-12 col-lg-10 col-xl-9 col-xxl-8">
        <div className="profile-content">
          {/* begin tab-content */}
          <div className="tab-content p-0">
            <div className="tab-pane fade show active" id="profile-post">
              {user ? (
                <div>
                  {publications ? (
                    <>
                      {!(user.activite && user.activite.categorie) ? (
                        <div
                          className="timeline-body"
                          style={{
                            marginBottom: "10px"
                        }}
                        >
                          { loading ? <Skeleton className="timeline-body loading-card" style={{ height: '100px' }} /> : (
                            <Card text="black" className="text-center complete-profile-card">
                              <Card.Body>
                                <Card.Text>
                                  Aidez nous à cibler le contenu qui vous
                                  intéresse en complétant votre profil !
                                </Card.Text>
                                <Link to="/profile/me">
                                  {" "}
                                  <Button className="btn blue-btn rounded-corner btn-block">Mon profil</Button>
                                </Link>
                              </Card.Body>
                            </Card>
                          ) }
                        </div>
                      ) : (
                        <Suggestions amis={suggestions} user={user} loading={loading} />
                      )}

                      {birthday === true ? (
                        <div>
                          <PostBirthday user={user} />
                        </div>
                      ) : (
                        ""
                      )}
                      <ul className="timeline">
                        <PostNew getdata={getData} reload={reload} setReload={setReload} />
                        { loading ? <Skeleton className="timeline-body loading-card" style={{ height: '500px' }} /> : (
                        publications
                          .sort(
                            (publication1, publication2) =>
                              new Date(publication2.creationDate).getTime() -
                              new Date(publication1.creationDate).getTime()
                          )
                          .map((publication, index) => {
                            if (publication.resource === 'sponsPublications') {
                              return (
                                <PostS
                                  publication={publication} key={index} getdata={getData}
                                />
                              );
                            }
                            if (publication.resource === 'shares')
                              return (
                                <PostShare
                                  key={publication._id}
                                  publication={publication}
                                  getdata={getData}
                                  userConnected={user}
                                />
                              );

                            return (
                              <Post
                                key={publication._id}
                                userConnected={user}
                                publication={publication}
                                id={publication._id}
                                date={publication.creationDate}
                                userId={publication.user}
                                userName={publication.user?.username}
                                lastName={publication.user?.lastName}
                                nbOfViews={publication.nbviews}
                                nbLikes={publication.likes.length}
                                nbShares={publication?.Shares?.length}
                                nbComments={
                                  publication.comments
                                    ? publication.comments.length
                                    : 0
                                }
                                content={publication.content}
                                image={publication.image}
                                video={publication.url}
                                comments={publication.comments}
                                getdata={getData}
                              />
                            );
                          })
                        )}

                        <li>
                          <div className="timeline-icon">
                            <span>&nbsp;</span>
                          </div>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: 350, marginBottom: 350 }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-lg-2 col-xl-3 col-xxl-4">
        <Card
          className="announcer-card"
        >
          <Card.Body>
            <Card.Title>Nos partenaires premium </Card.Title>
            <div className="card-content">
              <AutoPlaySwipeableViews
                index={index}
                onChangeIndex={(idx) => setIndex(idx)}
              >
                {annonceurs ? (
                  annonceurs.length === 0 ? (
                    <Skeleton className="timeline-body loading-card" style={{ height: '500px' }} />
                  ) : (
                    annonceurs.map((announcer, idx) => {
                      return (
                        <div className="announcer-card-meta" key={idx}>
                          <div
                            className="container mb-3"
                            style={{ textAlign: "center" }}
                          >
                            <img
                              src={
                                announcer.pictureUrl
                                  ? announcer.pictureUrl.data
                                  : null
                              }
                              alt="annonceur"
                              className="img-fluid"
                            />
                          </div>
                          <Link
                            to={{
                              pathname: "/announcer",
                              state: { announcer: announcer, user: user },
                            }}
                            style={{
                              width: "100%",
                              borderBottom: "1px solid rgba(19, 19, 19, 0.2)",
                            }}
                          >
                          <h5>
                            {announcer.nom ? announcer.nom : ""}
                          </h5>
                          </Link>
                          <div style={{ marginTop: 10 }}>
                            <p
                              style={{
                                color: "#0100B9",
                                fontWeight: 500,
                                fontSize: 18,
                                marginBottom: 10,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <i
                                className="material-icons"
                                style={{ marginRight: 8 }}
                              >
                                location_city
                              </i>
                              <span style={{ paddingTop: 4 }}>
                                {announcer.address
                                  ? announcer.address.city
                                  : ""}
                              </span>
                            </p>
                            <p style={{ color: "#131313", fontWeight: 300 }}>
                              {announcer.description
                                ? announcer.description
                                : ""}
                            </p>
                          </div>
                        </div>
                      );
                    })
                  )
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </AutoPlaySwipeableViews>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
    </>
  );
};

export default Wall;
