import React, { Component, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import "antd/dist/antd.css";
import apiService from "../../services/apiServices";
import FacturesDataTable from "../DataTables/FacturesDataTable";
import { Col, Container, Row } from "react-bootstrap";
import { ThemeProvider, createTheme } from '@mui/material/styles';


const columns = [
  {
    title: "Numéro de facture",
    field: "number_invoices",
  },
  {
    title: "Date de création",
    field: "creation_date",
  },
  {
    title: "Date de prélévement",
    field: "start_date",
  },
  {
    title: "Montant  (€)",
    field: "amount",
  },
  {
    title: "Télécharger",
    field: "fileUrl",
    render: (rowData) => (
      <a href={rowData.fileUrl} download>
        Télécharger
      </a>
    ),
  },  
];

const Paiement = ({factures}) => {
  const theme = createTheme({
  });
  
  const announcerId = useParams().id;
  const [invoicePdfLinks, setInvoicePdfLinks] = useState([]);

  useEffect(() => {
    const fetchInvoicePdfLinks = async () => {
      try {
        const response = await apiService.fetchInvoicePdfLinks(announcerId);       
        setInvoicePdfLinks(response.data);
        
      } catch (error) {
        console.error('Error fetching subscription:', error);
      }
    };
    if (announcerId) {
      fetchInvoicePdfLinks();
    }
  }, []);

  
  return (
    <Container>
      <Row justify="center">
        <Col xs={24}>
          <div className="announcer-promote-wrapper">
            <center>
              <h3 style={{ marginBottom: 25 }}>Factures</h3>
            </center>
            <ThemeProvider theme={theme}>
              <FacturesDataTable columns={columns} data={invoicePdfLinks} />
            </ThemeProvider>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Paiement;
