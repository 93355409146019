import React, { useState, useEffect } from "react";
import TopHeader from "../../components/menu/TopHeader";
import Footer from "../../components/menu/Footer";
import Wall from "../../components/Wall";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import apiService from "../../services/apiServices";
import toast, { Toaster } from "react-hot-toast";
import { getMyself } from "../../utils/getSetUser";

const Feed = () => {
  const [type, setType] = useState("publications");
  const [user, setUser] = useState({});
  const [announcer, setAnnouncer] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [refresh, setRefresh] = useState(false);
  
  const getdata = async () => {
    const me = getMyself();
    try {
      const annonceurs = await apiService.getAnnonceurs(`user=${me._id}`);
      const notifs = await apiService.getNotifications(
        `receiver=${me._id}&read=false`
      );
      setUser(me);
      setNotifications(notifs);
      if (annonceurs.length !== 0) {
        setAnnouncer(annonceurs[0]);
      }
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getdata();
    })();
  }, [refresh]);

  return (
    <div>
      <Toaster position="top-center" />
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        <TopHeader
          user={user}
          annonceur={announcer}
          notifications={notifications}
          getdata={getdata}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        <div id="content" className="content content-full-width">
          <ScrollUpButton type={type} />
          <Wall user={user} annonceur={announcer} refresh={refresh} setRefresh={setRefresh} />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Feed;
