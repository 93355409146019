import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import apiService from "../../services/apiServices";
import ForgotPwdForm from '../../pages/Login/ForgotPwdForm';

const LoginForm = () => {
  const [email, setEmail] = useState();
  const [pwd, setPwd] = useState();
  const [login, setLogin] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tryValid, setTryValid] = useState(true);
  const [formType, setFormType] = useState('login');
  const [showForgotPwd, setShowForgotPwd] = useState(false);

  const switchForm = (type) => {
    setFormType(type);
  };

  const handleLogin = async ({ setToken, setUser }) => {
    try {
      setLoading(true);
      await apiService.signInWithEmailAndPassword(email, pwd);
      if (
        apiService.getAccessToken() !== "undefined" &&
        apiService.getAccessToken() !== null
      ) {
        setLogin(true);
        setTryValid(false);
      } else {
        setTryValid(false);
        toast.error("Renseigner le bon mail et/ou mot de passe");
        setLoading(false);
      }
    } catch (error) {
      if (error.response) toast.error(error.response.status)
    }
  };

  const toggleRememberMe = () => {
    const value = !rememberMe;
    setRememberMe(value);
  };

  const toggleShowForgotPwd = () => {
    setShowForgotPwd(!showForgotPwd);
  };

  return (
    <>
      {login ? (
        <Redirect to="/profile/me" />
      ) : (
        <div className="register-content">
          <form className="margin-bottom-0">
            <Toaster position="top-center" />
            <label className="control-label">
              Email <span className="text-danger">*</span>
            </label>
            <div className="row m-b-15">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <label className="control-label">
              Mot de passe <span className="text-danger">*</span>
            </label>
            <div className="row m-b-15">
              <div className="col-md-12">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Mot de passe"
                  onChange={(e) => setPwd(e.target.value)}
                />
              </div>
            </div>

            <div className="checkbox checkbox-css m-b-30">
              <input
                type="checkbox"
                id="rememberMe"
                value=""
                name="terms"
                onChange={toggleRememberMe}
              />
              <label htmlFor="rememberMe">Se souvenir de moi </label>
            </div>
            {loading ? (
              <div className="register-buttons">
                <div className
            ="btn blue-btn rounded-corner btn-block btn-lg">
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="register-buttons">
          <div
            type="submit"
            className="btn blue-btn rounded-corner btn-block btn-lg"
            onClick={handleLogin}
            style={{ justifyContent: "center" }}
          >
            Connexion
          </div>
        </div>
      )}
      <div className="m-t-20 m-b-10 p-b-10 text-center">
        <a href="#" onClick={toggleShowForgotPwd}>Mot de passe oublié ?</a> <br />
        <Link to="/register">Cliquez ici pour vous inscrire.</Link>
      </div>
    </form>
    {showForgotPwd && <ForgotPwdForm switchForm={switchForm} toggleShowForgotPwd={toggleShowForgotPwd} />}
  </div>
)}
</>
);
};

export default LoginForm;