import React from 'react';
import { AuthContextProvider } from './contexts/AuthContext';
import Router from './pages/Router';

const App = () => {
  return (
    <AuthContextProvider>
      <Router />
    </AuthContextProvider>
  );
};

export default App;
