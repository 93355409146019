import React from "react";
import Dropdown from "react-bootstrap/Dropdown";


const NotifDropdown = ({ notifications, getdata }) => {

  const formatDate = (date) => {
    const fullDate = new Date(date);
    try {
      if (fullDate.getMinutes() < 10) {
        return `${fullDate.getHours()}:0${fullDate.getMinutes()}`;
      }
      return `${fullDate.getDate()}/${parseInt(
        fullDate.getMonth() + 1
      )}/${fullDate.getFullYear()}   ${fullDate.getHours()}:${fullDate.getMinutes()}`;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dropdown drop="start">
      <Dropdown.Toggle className="dropdown show notification-toggle">
        <span
          data-toggle="dropdown"
          className="dropdown-toggle icon notification-btn"
        >
          {notifications && notifications.length > 0 ? (
            <>
              <i className="material-icons active">notifications_active</i>
              <span className="label">
                {notifications ? notifications.length : null}
              </span>
            </>
          ) : (
            <i className="material-icons">notifications_none</i>
          )}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="notification-menu">
        {
        notifications && notifications
          .sort((notif1, notif2) => new Date(notif2.creation_date).getTime() - new Date(notif1.creation_date).getTime())
          .map((notif, index) => 
          {
            if (notif.like) {
              return (
                <Dropdown.Item className="dropdown-item">
                  <div className="media-left">
                    <img
                      src={
                        notif.like.user.img_url
                          ? notif.like.user.img_url.data
                          : ""
                      }
                      alt="user"
                      className="media-object"
                    />
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      {notif.like.user ? notif.like.user.lastName : ""}
                      &nbsp;
                      {notif.like.user ? notif.like.user.username : ""}
                    </h6>
                    <p>a aimé votre publication</p>
                    <div className="f-s-11">
                      {formatDate(notif.creation_date)}
                    </div>
                  </div>
                </Dropdown.Item>
              )
            }
            if (notif.comment) {
              return (
                <Dropdown.Item className="dropdown-item">
                  <div className="media-left">
                    <img
                      src={
                        notif.comment.user.img_url
                          ? notif.comment.user.img_url.data
                          : ""
                      }
                      alt="user"
                      className="media-object"
                    />
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      {notif.comment.user
                        ? notif.comment.user.lastName
                        : ""}
                      &nbsp;
                      {notif.comment.user
                        ? notif.comment.user.username
                        : ""}
                    </h6>
                    <p>a commenté votre publication</p>
                    <div className="f-s-11">
                      {formatDate(notif.creation_date)}
                    </div>
                  </div>
                </Dropdown.Item>
              )
            }
            if (notif.share) {
              return (
                <Dropdown.Item className="dropdown-item">
                  <div className="media-left">
                    <img
                      src={
                        notif.share.user.img_url
                          ? notif.share.user.img_url.data
                          : ""
                      }
                      alt="user"
                      className="media-object"
                    />
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      {notif.share.user ? notif.share.user.lastName : ""}
                      &nbsp;
                      {notif.share.user ? notif.share.user.username : ""}
                    </h6>
                    <p>a partagé votre publication</p>
                    <div className="f-s-11">
                      {formatDate(notif.creation_date)}
                    </div>
                  </div>
                </Dropdown.Item>
              )
            }
            if (notif.invitation) {
              return (
                <Dropdown.Item className="dropdown-item">
                  <div className="media-left">
                    <img
                      src={
                        notif.invitation.user.img_url
                          ? notif.invitation.user.img_url.data
                          : ""
                      }
                      alt="user"
                      className="media-object"
                    />
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      {notif.invitation.user
                        ? notif.invitation.user.lastName
                        : ""}
                      &nbsp;
                      {notif.invitation.user
                        ? notif.invitation.user.username
                        : ""}
                    </h6>
                    <p>vous a envoyé une invitation</p>
                    <div className="f-s-11">
                      {formatDate(notif.creation_date)}
                    </div>
                  </div>
                </Dropdown.Item>
              )
            }
            if (notif.message) {
              return (
                <Dropdown.Item className="dropdown-item">
                  <div className="media-left">
                    <img
                      src={
                        notif.message.user.img_url
                          ? notif.message.user.img_url.data
                          : ""
                      }
                      alt="user"
                      className="media-object"
                    />
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      {notif.message.user
                        ? notif.message.user.lastName
                        : ""}{" "}
                      &nbsp;
                      {notif.message.user
                        ? notif.message.user.username
                        : ""}
                    </h6>
                    <p>vous a envoyé un message </p>
                    <div className="f-s-11">
                      {formatDate(notif.creation_date)}
                    </div>
                  </div>
                </Dropdown.Item>
              )
            }
            if (notif.couponid) {
              return (
                <Dropdown.Item className="dropdown-item">
                  <div className="media-left"></div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      Nouveau coupon ({notif.couponid.percent_off}%)
                    </h6>
                    <p>
                      id coupon :<big> {notif.couponid.id}</big>{" "}
                    </p>
                    <div className="f-s-11">
                      {formatDate(notif.creation_date)}
                    </div>
                  </div>
                </Dropdown.Item>
              )
            }
          })
        }
      </Dropdown.Menu>
    </Dropdown>
  )
};

export default NotifDropdown;
