import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { ToastsStore } from "react-toasts";
import apiService from "../../services/apiServices";
import AddUserAnnouncerModal from "../../components/offreAnnouncer/AddUserAnnouncerModal";

const NewAnnouncer = ({
  announcer,
  user,
  logo,
  coverImage,
  isLogoLoaded,
  isCoverImageLoaded,
  onChange,
  onChangeAddress,
  onChangelogo,
  onChangeCoverImage,
  onCancel,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [users, setUsers] = useState([]);
  const [usersChoosed, setUsersChoosed] = useState([]);


  const allInformations = (announcer) => {
    if (
      announcer.nom &&
      announcer.email &&
      announcer.activite &&
      announcer.phone &&
      announcer.siret &&
      announcer.tranche &&
      announcer.website &&
      announcer.description &&
      announcer.pictureUrl &&
      announcer.address.number &&
      announcer.address.street &&
      announcer.address.postcode &&
      announcer.address.city &&
      announcer.address.country
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    (async () => {
      try {
        let alreadyAdded = announcer.users;
        let usersFound = [];
        if (announcer?.user?._id === user._id) {
          setDisabled(false);
        }
        const getUsers = await apiService.getUsers(`_id!=${user._id}`);
        getUsers.map((usr) => {
          if (!alreadyAdded.includes(usr)) {
            usersFound.push(usr);
          }
        });
        setUsers(usersFound);
        setSaveDisabled(allInformations(announcer));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [announcer?.user?._id, announcer?.users, user?._id, announcer]);
  const addUsers = async (usr) => {
    let newArrayUsersChoosed = [...usersChoosed];
    let newArrayUsers = [...users];
    newArrayUsersChoosed.push(usr);
  
    setUsersChoosed(newArrayUsersChoosed);
    setUsers(newArrayUsers);
    ToastsStore.warning(
      "L'utilisateur a été ajouté, n'oubliez pas d'enregistrer vos changements !"
    );
  };
  

  const changeSettings = async () => {
    try {
      const newAnnouncer = announcer;
      if (usersChoosed.length !== 0) {
        newAnnouncer.users.push(usersChoosed);
      }
      console.log(usersChoosed);
      await apiService.updateAnnonceurDetails(newAnnouncer);
      ToastsStore.success("Les informations ont été changées");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="company-form">
      <Form.Group>
        <Form.Label>Nom
        <span className="text-danger"> *</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          id="nom"
          disabled={disabled}
          value={announcer ? announcer.nom : ""}
          onChange={(e) => onChange(e.target.id, e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Siret
        <span className="text-danger"> *</span>
        </Form.Label>
        <Form.Control
          type="text"
          id="siret"
          disabled={disabled}
          value={announcer ? announcer.siret : ""}
          onChange={(e) => onChange(e.target.id, e.target.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Activité
        <span className="text-danger"> *</span>
        </Form.Label>
        <Form.Control
          id="activite"
          disabled={disabled}
          value={announcer ? announcer.activite : ""}
          onChange={(e) => onChange(e.target.id, e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Téléphone
        <span className="text-danger"> *</span>
        </Form.Label>
        <Form.Control
          type="text"
          id="phone"
          disabled={disabled}
          value={announcer ? announcer.phone : ""}
          onChange={(e) => onChange(e.target.id, e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Email
        <span className="text-danger"> *</span>
        </Form.Label>
        <Form.Control
          type="text"
          id="email"
          disabled={disabled}
          value={announcer ? announcer.email : ""}
          onChange={(e) => onChange(e.target.id, e.target.value)}
        />
      </Form.Group>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Numéro 
            <span className="text-danger"> *</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="number"
              disabled={disabled}
              value={
                announcer
                  ? announcer?.address
                    ? announcer?.address.number
                    : ""
                  : ""
              }
              onChange={(e) => onChangeAddress(e.target.id, e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Rue 
            <span className="text-danger"> *</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="street"
              disabled={disabled}
              value={
                announcer
                  ? announcer?.address
                    ? announcer?.address?.street
                    : ""
                  : ""
              }
              onChange={(e) => onChangeAddress(e.target.id, e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Complément</Form.Label>
            <Form.Control
              type="text"
              id="additional"
              disabled={disabled}
              value={
                announcer
                  ? announcer?.address
                    ? announcer?.address?.additional
                    : ""
                  : ""
              }
              onChange={(e) => onChangeAddress(e.target.id, e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Code postal
            <span className="text-danger"> *</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="postcode"
              disabled={disabled}
              value={
                announcer
                  ? announcer?.address
                    ? announcer?.address?.postcode
                    : ""
                  : ""
              }
              onChange={(e) => onChangeAddress(e.target.id, e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Ville
            <span className="text-danger"> *</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="city"
              disabled={disabled}
              value={
                announcer
                  ? announcer?.address
                    ? announcer?.address?.city
                    : ""
                  : ""
              }
              onChange={(e) => onChangeAddress(e.target.id, e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Pays
            <span className="text-danger"> *</span>
            </Form.Label>
            <Form.Control
              as="select"
              id="country"
              disabled={disabled}
              value={
                announcer
                  ? announcer?.address
                    ? announcer?.address?.country
                    : ""
                  : ""
              }
              onChange={(e) => onChangeAddress(e.target.id, e.target.value)}
            >
              <option>Choisissez votre pays</option>
              <option>Allemagne</option>
              <option>Belgique</option>
              <option>France</option>
              <option>Italie</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group>
        <Form.Label>Site web
        <span className="text-danger"> *</span>
        </Form.Label>
        <Form.Control
          type="text"
          id="website"
          disabled={disabled}
          value={announcer ? announcer.website : ""}
          onChange={(e) => onChange(e.target.id, e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Tranche d'effectif salarié
        <span className="text-danger"> *</span>
        </Form.Label>
        <Form.Control
          as="select"
          id="tranche"
          disabled={disabled}
          value={announcer ? announcer.tranche : "0"}
          onChange={(e) => onChange(e.target.id, e.target.value)}
        >
          <option value="0">0 salarié</option>
          <option value="1-199">entre 01 et 199 salariés</option>
          <option value="200-1999">entre 200 et 1999 salariés</option>
          <option value="2000-9999">entre 2000 et 9999 salariés</option>
          <option value="10000 ou plus ">10000 et plus </option>
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>Description
        <span className="text-danger"> *</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          rows="3"
          disabled={disabled}
          id="description"
          value={announcer ? announcer.description : ""}
          onChange={(e) => onChange(e.target.id, e.target.value)}
        />
      </Form.Group>
      {!disabled ? (
        <div>
          <Form.Group>
            <Row style={{ margin: '20px 0 10px 0' }}>
              <Col style={{ maxWidth: 'fit-content', padding: 0 }}>
                <input
                  hidden
                  type="file"
                  id="pictureUrl"
                  name="pictureUrl"
                  className="form-control rounded-corner"
                  onChange={(e) => onChangelogo(e)}
                />
                <label
                  className="btn blue-btn f-s-12 rounded-corner"
                  htmlFor="pictureUrl"
                  style={{ marginLeft: 0 }}
                >
                  logo
                </label>
                {logo && logo.data && isLogoLoaded && (
                <div className="preview-img-container">
                  <img
                    className="preview-img"
                    src={logo.data}
                    onLoad={() => console.log('image loaded')}
                  ></img>
                </div>
                )}
              </Col>
              <Col style={{ maxWidth: 'fit-content', padding: 0 }}>
                <input
                  hidden
                  type="file"
                  id="imageFont"
                  name="imageFont"
                  className="form-control rounded-corner"
                  onChange={(e) => onChangeCoverImage(e)}
                />
                <label
                  className="btn blue-btn f-s-12 rounded-corner"
                  htmlFor="imageFont"
                >
                  photo de couverture
                </label>
                {coverImage && coverImage.data && (
                  <div className="preview-img-container">
                    <img
                      className="preview-img"
                      src={coverImage.data}
                      onLoad={() => console.log('image loaded')}
                    ></img>
                  </div>
                )}
              </Col>
              {!(users.length === 0) ? (
                <Col style={{ maxWidth: 'fit-content', padding: 0 }}>
                  <AddUserAnnouncerModal users={users} ajouterUsers={addUsers} />
                </Col>  
              ) : null}
            </Row>
          </Form.Group>
          <Form.Group>
            <Row style={{ float: "right", margin: '10px 0 20px 0' }}>
              <Col>
                <Button
                  type="submit"
                  className="btn btn-link f-s-12"
                  onClick={() => onCancel()}
                >
                  Annuler
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={saveDisabled}
                  type="submit"
                  className="yellow-btn f-s-12 rounded-corner"
                  onClick={() => changeSettings()}
                >
                  Enregistrer
              </Button>
              </Col>
            </Row>
          </Form.Group>
        </div>
      ) : null}
    </div>
  );
};

export default NewAnnouncer;
