import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import apiService from "../../../services/apiServices";
import Modal from "react-bootstrap/Modal";
import { Form, Alert } from "react-bootstrap";

const AddFriendModal = ({
  user,
  onCancel,
  inviter_contact,
  disabledButton,
}) => {
  const [search, setSearch] = useState(true);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [found, setFound] = useState(false);
  const [messageNotFound, setMessageNotFound] = useState();

  const closeModal = () => {
    setOpen(false);
    setUsers([]);
    onCancel();
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {
    const searchContact = async () => {
      try {
        const usrs = await apiService.getUsers(`keyword=${searchInput}`);
        if (usrs.length > 0) {
          setFound(true);
          setUsers(usrs);
        } else {
          setFound(false);
          setMessageNotFound("Not found user");
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (searchInput) {
      searchContact();
    }
  }, [searchInput]);

  return (
    <div>
      <button
        type="button"
        className="btn blue-btn rounded-corner"
        onClick={showModal}
        style={{ marginBottom: 8, marginLeft: 0 }}
      >
        Ajouter un ami
      </button>

      <Modal show={open} onHide={closeModal} aria-labelledby="ModalHeader">
        <Modal.Header closeButton>
          <Modal.Title id="ModalHeader">Inviter un ami </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Control
                className="rounded-corner"
                type="text"
                id="text"
                value={searchInput}
                placeholder="Email"
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {search ? (
            <div className="row">
              <div className="col flex-row">
                <button className="btn btn-link" onClick={closeModal}>
                  Annuler
                </button>
              </div>
            </div>
          ) : null}
        </Modal.Footer>
        {found ? (
          users.map((contact, i) => {
            return (
              <div className="col">
                <Alert>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                      <img
                        src={contact.img_url ? contact.img_url.data : null}
                        alt={contact.username}
                        height="60px"
                      ></img>
                      <div className="m-10">
                        <Link to={"/profile/" + contact._id}>
                          {contact.username}&nbsp; {contact.lastName}
                        </Link>
                        <h6>
                          {contact.activite ? contact.activite.categorie : ""}
                        </h6>
                      </div>
                    </div>
                    <button
                      onClick={() => inviter_contact(user._id, contact._id)}
                      key={i}
                      className="btn my-auto blue-btn rounded-corner"
                      disabled={disabledButton}
                    >
                      Inviter
                    </button>
                  </div>
                </Alert>
                <hr />
              </div>
            );
          })
        ) : (
          <center>
            <h5>{messageNotFound}</h5>
          </center>
        )}
      </Modal>
    </div>
  );
};

export default AddFriendModal;
