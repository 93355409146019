import React, { useState, useEffect, useCallback } from "react";
import TopHeader from "../../components/menu/TopHeader";
import Footer from "../../components/menu/Footer";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Offre from "../../components/offreAnnouncer/creatOffre";
import apiService from "../../services/apiServices";
import Promouvoir from "../../components/Promouvoir";
import AnnouncerPosts from "../../components/postAnnouncer/AnnouncerPost";
import Subscription from "./Subscription/subscription";
import Library from "../../components/AnnouncerLibrary/Library";
import Badge from "react-bootstrap/Badge";
import Paiement from "../../components/Settings/Paiement";
import { ToastsStore } from "react-toasts";
import moment from "moment";
import { useHistory } from 'react-router-dom';


const Announcer = ({ match }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [announcerId, setAnnouncerId] = useState(match.params.id);
  const [announcer, setAnnouncer] = useState();
  const [originalAnnouncer, setOriginalAnnouncer] = useState();
  const [documents, setDocuments] = useState([]);
  const [invoicePdfLinks, setInvoicePdfLinks] = useState([]);
  const [publications, setPublications] = useState([]);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const [isCoverImageLoaded, setIsCoverImageLoaded] = useState(false);
  const [logo, setLogo] = useState();
  const [coverImage, setCoverImage] = useState();
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const history = useHistory();

  if(announcerId !== localStorage.getItem('AnnouncerId')) {
    history.push('../');
  }

  const getdata = useCallback(async () => {
    try {
      let publishes = [];
      const annonceur = await apiService.getAnnonceur(announcerId);
      setAnnouncer(annonceur);
      if (annonceur) {
        const sponsPublications = await apiService.getSponsPublications(
          `annonceur=${announcerId}`
        );
        sponsPublications.forEach((pub) => {
          publishes.push({
            _id: pub._id,
            annonceur: pub.annonceur,
            title: pub.title,
            creationDate: moment(pub.creationDate).format("L"),
            nbViews: pub.nbViews,
            likes: pub.likes,
            nbComments: pub.nbComments?.length,
            comments: pub.comments,
            content: pub.content,
            image: pub.image,
          });
        });
      }
      const docs = await apiService.getDocuments(`id_annonceur=${announcerId}`);
      setPublications(publishes);
      setOriginalAnnouncer(annonceur);
      setDocuments(docs);
    } catch (error) {
      console.log(error);
    }
  }, [announcerId]);

  const handleChange = (id, value) => {
    setAnnouncer({
      ...announcer,
      [id]: value,
    });
  };

  const onChangelogo = (e) => {
    try {
      const images = e.target.files;
      const image = images[0];

      const reader = new FileReader();
      let img = {
        name: image.name,
        type: image.type,
        lastModified: image.lastModified,
      };
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        img.data = e.target.result;
        setIsLogoLoaded(true);
      };
      setLogo(img);
      handleChange("pictureUrl", img);
    } catch (error) {
      console.log("error reading image data", error);
    }
  };

  const onChangeCoverImage = (e) => {
    try {
      const images = e.target.files;
      const image = images[0];

      const reader = new FileReader();
      let img = {
        name: image.name,
        type: image.type,
        lastModified: image.lastModified,
      };
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        img.data = e.target.result;
        setIsCoverImageLoaded(true);
      };
      setCoverImage(img);
      handleChange("imageFont", img);
    } catch (error) {
      console.log("error reading image data", error);
    }
  };
  
  const onChange = (id, value) => {
    setAnnouncer({
      ...announcer,
      [id]: value,
    });
  };

  const onChangeAddress = (id, value) => {
    const newAddress = { ...announcer.address, [id]: value, };
    setAnnouncer({
      ...announcer, address: newAddress
    });
  };

  const onCancel = () => {
    setAnnouncer(originalAnnouncer);
  };

  const displaySubscriptionBadge = () => {
    if (announcer.subscription) {
      return <Badge className="subscription-badge">{announcer.subscription.label}</Badge>;
    }
    return <Badge className="no-subscription-badge">Aucun abonnement en cours</Badge>;
  };

  const deleteDocument = async (id) => {
    try {
      await apiService.deleteDocument(id);
      ToastsStore.success("Le document a été supprimé");
      await getdata();
    } catch (error) {
      console.log(error);
    }
  };

  const publishDocument = async (id) => {
    try {
      await apiService.updateDocument({ status: "PUBLISH" }, id);
      await getdata();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getdata();
    })();
  }, [getdata, announcerId]);

  const combinedFactures = invoicePdfLinks.map((invoice, index) => {
    return {
      number_invoices : invoice.number_invoices,
      creation_date: invoice.creation_date,
      start_date: invoice.start_date,
      amount: invoice.amount,
      fileUrl: invoice.fileUrl,
    };
  });

  return (
    <div>
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        {announcer ? <TopHeader user={announcer.user} /> : null}
        {announcer && (
          <div id="content" className="content content-full-width">
            <ScrollUpButton />
            <div className="profile announcer-profile">
              <div className="profile-header announcer-header">
                {/* <div className="profile-header-cover"/> */}
                <div className="profile-header-content">
                  <div className="profile-header-img">
                    <img
                      src={
                        announcer.pictureUrl ? announcer.pictureUrl.data : null
                      }
                      alt="Profile Pic"
                    />
                  </div>
                  <div className="profile-header-info">
                    <h4 className="profile-header-name">
                      <b>{announcer.nom}</b> • {announcer.activite}
                    </h4>
                    <p style={{ marginBottom: 8, fontStyle: "italic" }}>
                      {announcer.description}
                    </p>
                    <p style={{ marginBottom: 0, fontSize: 13 }}>
                      Abonnement : {displaySubscriptionBadge()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="profile-content">
                <ul className="profile-header-tab nav nav-tabs">
                  <Nav>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        PUBLICATIONS
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        MA BIBLIOTHEQUE
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        MON ENTREPRISE
                      </NavLink>
                    </NavItem>
                    {/*{(announcer.user._id === user._id)?*/}
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          toggle("4");
                        }}
                      >
                        ANALYSES
                      </NavLink>
                    </NavItem>
                    {/*          :null}*/}
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "5" })}
                        onClick={() => {
                          toggle("5");
                        }}
                      >
                        MON ABONNEMENT
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "6" })}
                        onClick={() => {
                          toggle("6");
                        }}
                      >
                        MES FACTURES
                      </NavLink>
                    </NavItem>
                  </Nav>
                </ul>
                {/* Affichage de la bonne tab */}
                <ScrollUpButton
                  style={{ backgroundColor: "#343a40", height: 25 }}
                />
                <TabContent activeTab={activeTab} className="announcer-tab-content">
                  <TabPane tabId="1">
                    <AnnouncerPosts
                      user={announcer.user}
                      announcer={announcer}
                      publications={publications}
                      getdata={getdata}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <Library
                      announcer={announcer}
                      allDocuments={documents}
                      user={announcer.user}
                      toogle={toggle}
                      deleteDocument={deleteDocument}
                      publishDocument={publishDocument}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <Offre
                      announcer={announcer}
                      user={announcer.user}
                      isLogoLoaded={isLogoLoaded}
                      isCoverImageLoaded={isCoverImageLoaded}
                      onChangeAddress={onChangeAddress}
                      onChangelogo={onChangelogo}
                      onChange={onChange}
                      onCancel={onCancel}
                      onChangeCoverImage={onChangeCoverImage}
                      getdata={getdata}
                      logo={logo}
                      coverImage={coverImage}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <Promouvoir
                      publications={publications}
                      user={announcer.user}
                      announcer={announcer}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <Subscription user={announcer.user} announcer={announcer} />
                  </TabPane>
                  <TabPane tabId="6">
                    <Paiement user={announcer} factures={combinedFactures} />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Announcer;
