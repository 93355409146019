import React  from 'react';
import { Form, DatePicker } from 'antd';

const { Item } = Form;

const BirthDateFormItem = ({ disabled }) => {
  return (
    <Item 
      label="Date de naissance"
      name="date_naissance"
    >
      <DatePicker disabled={disabled}/>
    </Item>
  );
};

export default BirthDateFormItem;
