import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { setMyself } from '../utils/getSetUser';
import config from '../config';

export const USER_FOUND = "[USER] FOUND";
export const USER_NOT_FOUND = "[USER] NOT FOUND";
export const USER_CONNECTED = "[USER] CONNECTED";
export const USER_NOT_CONNECTED = "[USER] NOT CONNECTED";
export const DATA_NOT_CHANGED = "[DATA] NOT CHANGED";
export const USER_SUCCESSFULLY_MODIFIED = "[USER] SUCCESSFULLY MODIFIED";

const axiosInstance = axios.create({
    baseURL: config.database_url,
    headers: {
        'Content-Type': 'application/json'
    }
});

class jwtService {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }


    axiosInstanceSecureUsers = axios.create({
        baseURL: config.database_url,
        headers: {
        "Content-Type": "application/json",
        // eslint-disable-next-line no-use-before-define
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`
        }
    });

    /*
    * Constructor
    */
    setInterceptors = () => {
        axiosInstance.interceptors.response.use(
            response => {
                return response;
            },
            err => {
                return new Promise((resolve, reject) => {
                    if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                        // if you ever get an unauthorized response, logout the user
                        this.emit('onAutoLogout', 'Invalid access_token');
                        this.setSession(null);
                    }
                    return err;
                });
            }
        );
    };
    handleAuthentication = () => {
        let access_token = this.getAccessToken();


        if (!access_token) {
            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit('onAutoLogin', true);
        } else {
            this.setSession(null);
            this.emit('onAutoLogout', 'access_token expired');
        }
    };



    /*
    * Login/Logout
    */
    signInWithEmailAndPassword = async (email, password) => {
        try {
          const { data } = await axiosInstance.post('/login', {
            auth: {
              email,
              password
            }
          });
          this.setUserData(data.user, data.annonceur_id, data.token); // Pass annonceur_id to setUserData
          this.setSession(data.token);
          return ({
            id: data.user._id,
            user: data.user
          })
        } catch (err) {
          this.setSession(null);
          return err;
        }
      };
      

    setUserData = async (user, annonceurId, token) => {
        this.axiosInstanceSecureUsers.defaults.headers.Authorization = `Bearer ${token}`;
        setMyself(user);
        localStorage.setItem("userId", user._id);
        localStorage.setItem("AnnouncerId", annonceurId);
        localStorage.setItem("jwt_access_token", token);
      };


    /*
    ** New user
    */

    submitUser = async (data) => {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post('/users', data)
                .then(response => {
                    if (response.data.user) {
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                });
        });
    };

    submitSubscription = async (data) => {
        return new Promise((resolve, reject) => {
            if (this.getAccessToken() !== null) {
            axiosInstance
                .post('/subscriptions', {
                  paymentMethodId: data.paymentMethod.id,
                  priceId: data.priceId,
                  userId: data.userId
                }, {
                  headers: {
                    Authorization: "Bearer " + this.getAccessToken(),
                    Accept: "application/json",
                    "Content-Type": "application/json"
                  },
                })
                .then(response => {
                    if (response.data.subscription) {
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                });
    }});
    };

    fetchSubscription = async (subscriptionId) => {
        return new Promise((resolve, reject) => {
          if (this.getAccessToken() !== null) {
            axiosInstance
              .get(`/subscriptions/${subscriptionId}`, {
                headers: {
                  Authorization: "Bearer " + this.getAccessToken(),
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                if (response.data.data) {
                  resolve(response.data);
                } else {
                  reject(response.data.error);
                }
              });
          }
        });
      };
      fetchSubscriptions = async (query) => {
        return new Promise((resolve, reject) => {
            const queryUrl = (query === undefined) ? "" : "?" + query;
            if (this.getAccessToken() !== null) {
              axiosInstance
                .get(`/subscriptions${queryUrl}`, {
                  headers: {
                    Authorization: "Bearer " + this.getAccessToken(),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                })
                .then((response) => {
                  if (response.data.data) {
                    resolve(response.data);
                  } else {
                    reject(response.data.error);
                  }
                });
            }
          });
      }
      fetchPlans = async (query) => {
        return new Promise((resolve, reject) => {
            const queryUrl = (query === undefined) ? "" : "?" + query;
            if (this.getAccessToken() !== null) {
              axiosInstance
                .get(`/subscriptions/item${queryUrl}`, {
                  headers: {
                    Authorization: "Bearer " + this.getAccessToken(),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                })
                .then((response) => {
                  if (response.data.data) {
                    resolve(response.data);
                  } else {
                    reject(response.data.error);
                  }
                });
            }
          });
      }
    fetchPlan = async (planId) => {
  return new Promise((resolve, reject) => {
    if (this.getAccessToken() !== null) {
      axiosInstance
        .get(`/subscriptions/item/${planId}`, {
          headers: {
            Authorization: "Bearer " + this.getAccessToken(),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

      fetchInvoicePdfLinks = async (announceurId) => {
        return new Promise((resolve, reject) => {
            if (this.getAccessToken() !== null) {
              axiosInstance
                .get(`/subscriptions/announcer/${announceurId}`, {
                  headers: {
                    Authorization: "Bearer " + this.getAccessToken(),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                })
                .then((response) => {
                  if (response.data.data) {
                    resolve(response.data);
                  } else {
                    reject(response.data);
                  }
                });
            }
          });
      }
      patchSubscription = (data) => {
        return new Promise((resolve, reject) => {
          if (this.getAccessToken() !== null) {
            axiosInstance
              .patch(`/subscriptions/${data.id}`, {
                headers: {
                  Authorization: "Bearer " + this.getAccessToken(),
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                data: {
                  announcerId: data.announcerId,
                  // Ajoutez ici d'autres propriétés à envoyer dans le corps de la requête PATCH, si nécessaire
                },
              })
              .then((response) => {
                if (response.status === 200) {
                  resolve(response.data);
                } else {
                  reject(response.data.error);
                }
              });
          }
        });
      };
      deleteSubscription = (data) => {
        return new Promise((resolve, reject) => {
          if (this.getAccessToken() !== null) {
            axiosInstance
              .delete(`/subscriptions/${data.id}`, {
                headers: {
                  Authorization: "Bearer " + this.getAccessToken(),
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                data: { // Ajoutez cette ligne
                  announcerId: data.announcerId, // Envoyez l'ID de l'annonceur dans le corps de la requête
                },
              })
              .then((response) => {
                if (response.status === 200) {
                  resolve(response.data);
                } else {
                  reject(response.data.error);
                }
              });
          }
        });
      };      
    

    signInWithToken = () => {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get('/api/auth/access-token', {
                    data: {
                        access_token: this.getAccessToken()
                    }
                })
                .then(response => {
                    if (response.data.user) {
                        this.setSession(response.data.access_token);
                        resolve(response.data.user);
                    } else {
                        reject(response.data.error);
                    }
                });
        });
    };
    setSession = access_token => {
        if (access_token) {
            localStorage.setItem('jwt_access_token', access_token);
            axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        } else {
            localStorage.removeItem('jwt_access_token');
            delete axiosInstance.defaults.headers.common['Authorization'];
        }
    };
    getSession = () => {
        return localStorage;
    };

    logout = () => {
        this.setSession(null);
        window.localStorage.removeItem('jwt_access_token');
    };
    isAuthTokenValid = access_token => {
        if (!access_token) {
            return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        } else {
            return true;
        }
    };
    getAccessToken = () => {
        return window.localStorage.getItem('jwt_access_token');
    };

     changePwd = async (token, body) => {
        return new Promise((resolve, reject) => {
          axiosInstance
            .post(`/reset-pwd/${token}`, body)
            .then((response) => {
              if (response.data) {
                resolve(response.data);
              } else {
                reject(new Error('Wrong token'));
              }
            })
            .catch((error) => {
                console.log(error);
              reject(error);
            });
        });
      };
      askForPwdReset = async (email, toggleShowForgotPwd) => {
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(`/reset-pwd/${email}`)
            .then((response) => {
              if (response.data && response.data.message) {
                toggleShowForgotPwd()
                resolve(response.data);
              } else {
                reject(new Error('Error sending reset password email'));
              }
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      };
      

    getUserId = () => {
        return window.localStorage.getItem('userId');
    };

    /*
    * Publications
    */
    getPublications = async (query) => {
        const queryUrl = (query === undefined) ? "" : "?populate=" + query;
        if (this.getAccessToken() !== null) {
            try {
                const publications = await this.axiosInstanceSecureUsers.get(`/publications${queryUrl}`);
                return publications;
            } catch (error) {
                console.log(error);
            }
        }
    };

    getPublication = async (id, population = '') => {
        try {
            let query = '?populate=';
            if (population && population.length !== 0) {
                query = `?populate=${population}`;
            }
            if (this.getAccessToken() !== null) {
                const { data } = await this.axiosInstanceSecureUsers.get(`/publications/${id}${query}`);
                return data;
            }
        } catch (error) {
            console.log(error);
        }
    };

    postPublication = async (data) => {
        try {
            if (this.getAccessToken() !== null) {
                const decoded = jwtDecode(this.getAccessToken());
                data.user = decoded.id;
                await this.axiosInstanceSecureUsers.post(`/publications`, data);
            }
        } catch (error) {
            return error;
        }
    };


    updatePublication = async (publication, id) => {
        try {
            const response = await this.axiosInstanceSecureUsers.patch(`/publications/${id}`, publication);
            return response;
        } catch (error) {
            console.log(error);
        }
    };


    deletePublication = async (id) =>{
        try {
            if (this.getAccessToken() !== null) {
                const response = await this.axiosInstanceSecureUsers.delete(`/publications/${id}`);
                return response;
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*
    * Publications sponsorisées
    */

   getSponsPublications = async (query) => {
        try {
            const queryUrl = (query === undefined) ? "" : "?" + query;
            if (this.getAccessToken() !== null) {
                const { data } = await this.axiosInstanceSecureUsers.get(`/sponspublications/${queryUrl}`);
                return data;
            }
        } catch (error) {
            return error;
        }
    };

    getSponsPublication = async (id) => {try {
            if (this.getAccessToken() !== null) {
                const { data } = await this.axiosInstanceSecureUsers.get(`/sponspublications/${id}`);
                return data;
            }
        } catch (error) {
            return error;
        }
    };

    postSponsPublication = async (data) => {try {
            if (this.getAccessToken() !== null) {
                await this.axiosInstanceSecureUsers.post(`/sponspublications/`, data);
            }
        } catch (error) {
            return error;
        }
    };


    deleteSponsPublication = async (id) =>{try {
            await this.axiosInstanceSecureUsers.delete(`/sponspublications/${id}`);
        } catch (error) {
            return error;
        }
    };

    updateSponsPublication = async (sponspublication, id) => {try {
            if (this.getAccessToken() !== null) {
                await this.axiosInstanceSecureUsers.patch(`/sponspublications/${id}`, sponspublication);
            }
        } catch (error) {
            return error;
        }
    };


     /*
    * Coupons
    */
   getCoupons = async  (query) => {
    const queryUrl = (query === undefined) ? "" : "?" + query;

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/coupons` + queryUrl,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();

            return response
        }
    };

    getCoupon = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/coupons/` + id,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();
            return response
        }
    };


    postCoupon = async  (data) => {
        if (this.getAccessToken() !== null) {

            const decoded = jwtDecode(this.getAccessToken());
            data.user = decoded.id;

            const rawResponse = await fetch(
                config.database_url + `/coupons/`,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data),
                }
            );

            const response = await rawResponse.json();
            return response
        }
    };


    updateCoupon = (coupon, id) => {

        return new Promise((resolve, reject) => {

        this.axiosInstanceSecureUsers
            .patch(`/coupons/${id}`, coupon)
            .then(response => {
            if (response.status === 200) {
                resolve({ type: USER_SUCCESSFULLY_MODIFIED });
            } else {
                reject({ error: response.data.error });
            }
            })
            .catch(error => {
            reject({
                error: error.response ? error.response.data.error : error.error,
                type: DATA_NOT_CHANGED
            });
            });
        });
    };


    deleteCoupon = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/coupons/` + id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }
    };



    /*
    * Users
    */
    getUsers = async  (query) => {
        try {
            const queryUrl = (query === undefined) ? "" : "?" + query;
            const { data } = await this.axiosInstanceSecureUsers.get(`/users/${queryUrl}`);
            return data;
        } catch (error) {
            console.log(error);
        }
    };
    getUser = async  (id) => {
        try {
            const { data } = await this.axiosInstanceSecureUsers.get(`/users/${id}`);
            return data;
        } catch (error) {
            console.log(error);
        }
    };

    updateUserDetails = async (user, id) => {
        try {
            const { data } = await this.axiosInstanceSecureUsers.patch(`/users/${id}`, user);
            return data;
        } catch (error) {
            return error;
        }
    };

    updateUserPassword = async (password, id) => {
        try {
            await this.axiosInstanceSecureUsers.patch(
                `/users/kalimasiriya/${id}`,
                password
            );
        } catch (error) {
            return error;
        }
      };

    deleteUser = async (id) =>{
        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/users/` + id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }

    };



    /*
    * Annonceurs
    */
    getAnnonceurs = async  (query) => {
        const queryUrl = (query === undefined) ? "" : "?" + query;

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/annonceurs` + queryUrl,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();
            return response
        }
    };


    getAnnonceur = async  (id) => {
        try {
            const { data } = await this.axiosInstanceSecureUsers.get(`/annonceurs/${id}`);
            return data; 
        } catch (error) {
            return error;
        }
    };


    postAnnonceur = async  (data) => {
        try {
            if (this.getAccessToken() !== null) {
                const response = await this.axiosInstanceSecureUsers.post(`/annonceurs/`, data);
                localStorage.setItem("AnnouncerId", response.data.announcerId);
            }
        } catch (error) {
            console.log(error);
        }
    };

    updateAnnonceurDetails = (annonceur) => {
        return new Promise((resolve, reject) => {
      
          this.axiosInstanceSecureUsers
            .patch(`/annonceurs/${annonceur._id}`, annonceur)
            .then((response) => {
              if (response.status === 200) {
                resolve({ type: USER_SUCCESSFULLY_MODIFIED });
              } else {
                reject({ error: response.data.error });
              }
            })
            .catch((error) => {
              console.error("Error:", error); // Debug: Affiche l'erreur
              reject({
                error: error.response
                  ? error.response.data.error
                  : error.error,
                type: DATA_NOT_CHANGED,
              });
            });
        });
      };
      

    deleteAnnonceur = async (id) =>{
        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/annonceurs/` + id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }

    };

    /*
    * Invitations
    */

    getInvitations = async (query) =>{
        const queryUrl = (query === undefined) ? "" : "?" + query;
        if(this.getAccessToken() != null) {
            const rawResponse = await fetch(
                config.database_url +`/invitations` +queryUrl,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application.json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }
    };

    getInvitation = async (id) =>{
        if(this.getAccessToken() != null) {
            const rawResponse = await fetch(
                config.database_url + `/invitations/` + id,
                {
                    method:"GET",
                    headers: {
                        Authorization: "Bearer" + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application.json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }
    };

    postInvitation = async(data)=>{
        if (this.getAccessToken() !== null) {
            const decoded = jwtDecode(this.getAccessToken());
            data.user = decoded.id;
            const rawResponse = await fetch(
                config.database_url + `/invitations/`,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data),
                }
            );

            const response = await rawResponse.json();
            return response
        }
    };

    updateInvitation = async (invitation,id) =>{
        return new Promise((resolve, reject) => {

            this.axiosInstanceSecureUsers
              .patch(`/invitations/${id}`, invitation)
              .then(response => {
                if (response.status === 200) {
                  resolve({ type: USER_SUCCESSFULLY_MODIFIED });
                } else {
                  reject({ error: response.data.error });
                }
              })
              .catch(error => {
                reject({
                  error: error.response ? error.response.data.error : error.error,
                  type: DATA_NOT_CHANGED
                });
              });
          });

    };

    deleteInvitation = async(id)=>{
        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/invitations/` + id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }

    };

        /*
        * Notification
        */

    getNotifications = async (query) =>{
        const queryUrl = (query === undefined) ? "" : "?" + query;
        if(this.getAccessToken() != null) {
            const rawResponse = await fetch(
                config.database_url +`/notifications` +queryUrl,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application.json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }
    };

    getNotification = async (id) =>{
        if(this.getAccessToken() != null) {
            const rawResponse = await fetch(
                config.database_url + `/notifications/` + id,
                {
                    method:"GET",
                    headers: {
                        Authorization: "Bearer" + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application.json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }
    };

    postNotification = async(data)=>{
        if (this.getAccessToken() !== null) {
            const decoded = jwtDecode(this.getAccessToken());
            data.user = decoded.id;
            const rawResponse = await fetch(
                config.database_url + `/notifications/`,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data),
                }
            );

            const response = await rawResponse.json();
            return response
        }

        // try {
        //     if (this.getAccessToken() !== null) {
        //         const decoded = jwtDecode(this.getAccessToken());
        //         data.user = decoded.id;
        //         const response = await this.axiosInstanceSecureUsers.post()
        //     }
        // } catch (error) {
        //     return error;
        // }
    };

    updateNotification = async (invitation,id) =>{
        return new Promise((resolve, reject) => {

            this.axiosInstanceSecureUsers
            .patch(`/notifications/${id}`, invitation)
            .then(response => {
                if (response.status === 200) {
                resolve({ type: USER_SUCCESSFULLY_MODIFIED });
                } else {
                reject({ error: response.data.error });
                }
            })
            .catch(error => {
                reject({
                error: error.response ? error.response.data.error : error.error,
                type: DATA_NOT_CHANGED
                });
            });
        });

    };

    deleteNotification = async(id)=>{
        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/notifications/` + id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }

    };



    /*
    * Bills
    */
    getBills = async  (query) => {
        const queryUrl = (query === undefined) ? "" : "?" + query;

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/bills` + queryUrl,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();

            return response
        }
    };
    getBill = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/bills/` + id,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();

            return response
        }
    };
    postBill = async  (data) => {
        return new Promise (async (resolve,reject)=>{
            if (this.getAccessToken() !== null) {

                const decoded = jwtDecode(this.getAccessToken());
                data.user = decoded.id;

                const rawResponse = await fetch(
                    config.database_url + `/bills/`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: "Bearer " + this.getAccessToken(),
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(data),
                    }
                );

                const response = await rawResponse.json();
                if (response.response.status_code === 201) {
                    resolve();
                } else {
                    reject(response.error);
                }
            }
        });
    };

    updateBill = (bill, id) => {

        return new Promise((resolve, reject) => {

            this.axiosInstanceSecureUsers
                .patch(`/bills/${id}`, bill)
                .then(response => {
                    if (response.status === 200) {
                        resolve({ type: USER_SUCCESSFULLY_MODIFIED });
                    } else {
                        reject({ error: response.data.error });
                    }
                })
                .catch(error => {
                    reject({
                        error: error.response ? error.response.data.error : error.error,
                        type: DATA_NOT_CHANGED
                    });
                });
        });
    };

    deleteBill= async(id)=>{
        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/bills/` + id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }

    };


    /*
    * Comments
    */
    getComments = async  (query) => {
        const queryUrl = (query === undefined) ? "" : "?" + query;

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/comments` + queryUrl,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();
            return response
        }
    };
    getComment = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/comments/` + id,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();

            return response
        }
    };


    postComment = async  (data) => {
        if (this.getAccessToken() !== null) {

            const decoded = jwtDecode(this.getAccessToken());
            data.user = decoded.id;

            const rawResponse = await fetch(
                config.database_url + `/comments/`,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data),
                }
            );

            const response = await rawResponse.json();
            return response
        }
    };

    updateComment = async (comment,id) =>{
        return new Promise((resolve, reject) => {

            this.axiosInstanceSecureUsers
              .patch(`/comments/${id}`, comment)
              .then(response => {
                if (response.status === 200) {
                  resolve({ type: USER_SUCCESSFULLY_MODIFIED });
                } else {
                  reject({ error: response.data.error });
                }
              })
              .catch(error => {
                reject({
                  error: error.response ? error.response.data.error : error.error,
                  type: DATA_NOT_CHANGED
                });
              });
          });

    };

    deleteComment = async  (id) => {
        try {
            if (this.getAccessToken() !== null) {
                const response = await this.axiosInstanceSecureUsers.delete(`/comments/${id}`);
                return response;
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*
    * Documents
    */
    getDocuments = async  (query) => {
        const queryUrl = (query === undefined) ? "" : "?" + query;

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/documents` + queryUrl,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();

            return response
        }
    };
    getDocument = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/documents/` + id,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();

            return response
        }
    };
    postDocument = async  (data) => {
        if (this.getAccessToken() !== null) {

            const decoded = jwtDecode(this.getAccessToken());
            data.user = decoded.id;

            const rawResponse = await fetch(
                config.database_url + `/documents/`,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data),
                }
            );

            const response = await rawResponse.json();
            return response
        }
    };

    updateDocument = (document, id) => {
        return new Promise((resolve, reject) => {
            this.axiosInstanceSecureUsers
                .patch(`/documents/${id}`, document)
                .then(response => {
                    if (response.status === 200) {
                        resolve({ type: USER_SUCCESSFULLY_MODIFIED });
                    } else {
                        reject({ error: response.data.error });
                    }
                })
                .catch(error => {
                    reject({
                        error: error.response ? error.response.data.error : error.error,
                        type: DATA_NOT_CHANGED
                    });
                });
        });
    };

    deleteDocument = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/documents/` + id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }
    };

    /*
    * Likes
    */
    getLikes = async  (query) => {
        const queryUrl = (query === undefined) ? "" : "?" + query;

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/likes` + queryUrl,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();

            return response
        }
    };
    getLike = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/likes/` + id,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();

            return response
        }
    };
    postLike = async  (data) => {
        if (this.getAccessToken() !== null) {

            const decoded = jwtDecode(this.getAccessToken());
            data.user = decoded.id;

            const rawResponse = await fetch(
                config.database_url + `/likes/`,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data),
                }
            );

            const response = await rawResponse.json();
            return response
        }
    };
    updateLike = async (like,id) =>{
        return new Promise((resolve, reject) => {

            this.axiosInstanceSecureUsers
              .patch(`/likes/${id}`, like)
              .then(response => {
                if (response.status === 200) {
                  resolve({ type: USER_SUCCESSFULLY_MODIFIED });
                } else {
                  reject({ error: response.data.error });
                }
              })
              .catch(error => {
                reject({
                  error: error.response ? error.response.data.error : error.error,
                  type: DATA_NOT_CHANGED
                });
              });
          });

    };

    deleteLike = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/likes/` + id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }
    };

    /*
    * Medias
    */
    getMedias = async  (query) => {
        const queryUrl = (query === undefined) ? "" : "?" + query;

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/medias` + queryUrl,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();

            return response
        }
    };
    getMedia = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/medias/` + id,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();


            return response
        }
    };
    postMedia = async  (data) => {
        if (this.getAccessToken() !== null) {

            const decoded = jwtDecode(this.getAccessToken());
            data.user = decoded.id;

            const rawResponse = await fetch(
                config.database_url + `/medias/`,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data),
                }
            );

            const response = await rawResponse.json();
            return response
        }
    };
    deleteMedia = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/medias/` + id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }
    };

    /*
    * PetitesAnnonces
    */
    getPetitesAnnonces = async  (query) => {
        try {
            const queryUrl = (query === undefined) ? "" : "?" + query;
            if (this.getAccessToken() !== null) {
                const { data } = await this.axiosInstanceSecureUsers.get(`/petiteannonces/${queryUrl}`);
                return data;
            }
        } catch (error) {
            return error;
        }
    };
    getPetitesAnnonce = async  (id) => {
        try {
            if (this.getAccessToken() !== null) {
                const { data } = await this.axiosInstanceSecureUsers.get(`/petiteannonces/${id}`);
                return data;
            }
        } catch (error) {
            return error;
        }
    };

    postPetitesAnnonce = async (data) => {
        try {
            await this.axiosInstanceSecureUsers.post(`/petiteannonces/`, data);
        } catch (error) {
            return error;
        }
    };

    updatePetiteAnnonceDetails = async (petiteannonce, id) => {
        try {
            await this.axiosInstanceSecureUsers.patch(`/petiteannonces/${id}`, petiteannonce);
        } catch (error) {
            return error;
        }
    };

    deletePetitesAnnonce = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/petiteannonces/` + id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }
    };

    /*
    * Shares
    */
    getShares = async  (query) => {
        const queryUrl = (query === undefined) ? "" : "?" + query;

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/shares` + queryUrl,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();


            return response
        }
    };

    getShare = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/shares/` + id,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();

            return response
        }
    };

    postShare = async  (data) => {
        try {
            if (this.getAccessToken() !== null) {
                const decoded = jwtDecode(this.getAccessToken());
                data.user = decoded.id;
                await this.axiosInstanceSecureUsers.post('/shares/', data);
            }
        } catch (error) {
            return error;
        }
    };

    updateShare = async (share,id) =>{
        return new Promise((resolve, reject) => {

            this.axiosInstanceSecureUsers
              .patch(`/shares/${id}`, share)
              .then(response => {
                if (response.status === 200) {
                  resolve({ type: USER_SUCCESSFULLY_MODIFIED });
                } else {
                  reject({ error: response.data.error });
                }
              })
              .catch(error => {
                reject({
                  error: error.response ? error.response.data.error : error.error,
                  type: DATA_NOT_CHANGED
                });
              });
          });

    };

    deleteShare = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/shares/` + id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response
        }
    };


//    SearchForm

    search = async (resource,keywords) =>{
        if (this.getAccessToken() !== null) {
            let query = "";
            switch (resource){
                case 'publications' :
                    query =  `/publications?filter={"$or":[{"content":{"$regex":".*${keywords}.*"}},{"creationDate":{"$regex":".*${keywords}.*"}},{"user.username":{"$regex":".*${keywords}.*"}},{"user.lastName":{"$regex":".*${keywords}.*"}}]}`;
                    break;
                case 'contact' :
                    query =  `/contact?filter={"$or":[{"username":{"$regex":".*${keywords}.*"}},{"email":{"$regex":".*${keywords}.*"}},{"lastName":{"$regex":".*${keywords}.*"}},{"civilite":{"$regex":".*${keywords}.*"}},{"description":{"$regex":".*${keywords}.*"}},{"ville":{"$regex":".*${keywords}.*"}},{"pays":{"$regex":".*${keywords}.*"}},{"region":{"$regex":"                                           .*${keywords}.*"}},{"website":{"$regex":".*${keywords}.*"}},{"date_naissance":{"$regex":".*${keywords}.*"}},{"langue":{"$regex":".*${keywords}.*"}}]}`;
                    break;
                case  'petiteannounces' :
                    query = `/petiteannounces?filter={"$or":[{"title":{"$regex":".*${keywords}.*"}},{"category":{"$regex":".*${keywords}.*"}},{"content":{"$regex":".*${keywords}.*"}},{"price":{"$regex":".*${keywords}.*"}},{"email":{"$regex":".*${keywords}.*"}},{"id_user.username":{"$regex":".*${keywords}.*"}},{"id_user.lastName":{"$regex":".*${keywords}.*"}}]}`;
                    break;
                case  'documents' :
                    query = `/documents?filter={"$or":[{"title":{"$regex":".*${keywords}.*"}},{"size":{"$regex":".*${keywords}.*"}},{"description":{"$regex":".*${keywords}.*"}},{"fileUrl":{"$regex":".*${keywords}.*"}},{"id_user.username":{"$regex":".*${keywords}.*"}},{"id_user.lastName":{"$regex":".*${keywords}.*"}}]}`;
                    break;
                default :
                    query =  `/publications?filter={"$or":[{"content":{"$regex":".*${keywords}.*"}},{"creationDate":{"$regex":".*${keywords}.*"}},{"user.username":{"$regex":".*${keywords}.*"}},{"user.lastName":{"$regex":".*${keywords}.*"}}]}`;
                    break;
            }
            const rawResponse = await fetch(
                config.database_url +query,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );
            const response = await rawResponse.json();
            return response;
        }
    };


    getMessagesByUser = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/messages/user/` + id,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();

            let conversations = {};
            if (response.length > 0) {
                response.forEach(message => {
                    const other =
                        message.id_sender._id === this.getUserId()
                            ? message.id_receiver
                            : message.id_sender;
                    const id_conv = other._id;
                    conversations[id_conv]
                        ? conversations[id_conv].messages.push(message)
                        : (conversations[id_conv] = {messages: [message], other});
                });
            }
            return conversations
        }
    };

    getMessage = async  (id) => {

        if (this.getAccessToken() !== null) {
            const rawResponse = await fetch(
                config.database_url + `/shares/` + id,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            const response = await rawResponse.json();

            return response
        }
    };

    postMessage = async  (data) => {
        if (this.getAccessToken() !== null) {

            const decoded = jwtDecode(this.getAccessToken());
            data.user = decoded.id;

            const rawResponse = await fetch(
                config.database_url + `/messages/`,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data),
                }
            );

            const response = await rawResponse.json();
            return response
        }
    };

    setMessageIsRead = async  (id) => {
        if (this.getAccessToken() !== null) {

            const decoded = jwtDecode(this.getAccessToken());

            const rawResponse = await fetch(
                config.database_url + `/messages/` + id,
                {
                    method: "PATCH",
                    headers: {
                        Authorization: "Bearer " + this.getAccessToken(),
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({isRead: true}),
                }
            );

            const response = await rawResponse.json();
            return response
        }
    }

    getNews = async (id) => {
        try {
            if (this.getAccessToken() !== null) {
                // ?isUserProfile=${isUserProfile}
                const { data } = await this.axiosInstanceSecureUsers.get(`/news/walls/${id}`);
                return data;
            }
        } catch (error) {
            return error;
        }
    };

    getMyNews = async (id) => {
        try {
            if (this.getAccessToken() !== null) {
                const { data } = await this.axiosInstanceSecureUsers.get(`/news/profiles/${id}`);
                return data;
            }
        } catch (error) {
            return error;
        }
    }

    register = async (body) => {
        try {
            const response = await axiosInstance.post('/users', body);
            return response;
        } catch (error) {
            return error;
        }
    }
}

const instance = new jwtService();

export default instance;
