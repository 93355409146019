const activities = [
  "Charpentier bois",
  "Conducteur d'engins",
  "Constructeur bois",
  "Constructeur en béton armé",
  "Constructeur en sols industriels",
  "Constructeur métallique",
  "Démolisseur",
  "Enduiseur façadier",
  "Grutier",
  "Maçon",
  "Monteur d'échafaudage",
  "Monteur levageur",
  "Tailleur de pierre",
  "Bardage",
  "Cordiste",
  "Couvreur",
  "Etancheur",
  "Enr",
  "Menuiserie ",
  "métallique",
  "Miroitier",
  "Storiste",
  "Electricien",
  "Instal. chauffage et climatisation",
  "Plombier",
  "Agenceur",
  "Carreleur",
  "Menuisier",
  "Métallier-Serrurier",
  "Peintre",
  "Plâtrier",
  "Isolations",
  "Solier moquettiste",
];

export default activities;
