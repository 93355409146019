import React from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import Register from "./Register/Register";
import Login from "./Login/Login";
import Feed from "./Feed/Feed";
import MyProfile from "./Profile/MyProfile";
import OtherProfile from "./Profile/OtherProfile";
import Announces from "./Announces/Announces";
import Library from "./Library/Library";
import DetailsLibraryModal from "./Library/DetailsLibraryModal";
import NewLibrary from "./Library/NewLibrary";
import Notifications from "./Notifications/Notifications";
import Partners from "./Partners/Partners";
import NewAnnounce from "./Announces/NewAnnounce";
import DetailsAnnounce from "./Announces/DetailAnnounce";
import EditAnnounce from "./Announces/EditAnnounce";
import Announcer from "./Announcer/Announcer";
import NewPublication from "./NewPublicationSponsorise/NewPublication";
import Promouvoir from "../components/Promouvoir";
import CGV from "./CGV/CGV";
import ML from "./MentionsLegales/mentionsLegales";
import Offre from "../components/offreAnnouncer/creatOffre";
import PostNew from "../components/postAnnouncer/Postnew";
import Facture from "../components/Facture";
import Adresses from "./AdressesUtiles/Adresses";
import apiServices from "../services/apiServices";
import NotFound from "../notfound";
import Settings from "./Settings/Settings";
import Marketplace from "./MarkatePlace/Marketeplace";
import CreateTemplate from "./Documents/Templates/CreateTemplate";
import Subscription from "./Stripe/Subscription";
import ChangePasswordPage from "./Login/ChangePasswordPage";

import 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/font-awesome/css/font-awesome.css';
import '../css/material/style.css';
import '../css/material/style-responsive.css';
import '../css/material/theme/default.css';
import '../scss/index.scss';


const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        apiServices.getAccessToken() &&
        apiServices.isAuthTokenValid(apiServices.getAccessToken()) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/register" component={Register}/>
        <Route path="/login" component={Login}/>
        <Route path="/cgv" component={CGV}/>
        <Route path={"/mentionslegales"} component={ML} />
        <Route path="/change-password/:id" component={ChangePasswordPage} />
        <PrivateRoute exact path="/" component={Feed}/>
        <PrivateRoute exact path="/home" component={Feed}/>
        <PrivateRoute path="/profile/me" component={MyProfile}/>
        <PrivateRoute path="/profile/:id" component={OtherProfile}/>
        <PrivateRoute path="/announces" component={Announces}/>
        <PrivateRoute path="/newAnnounce" component={NewAnnounce}/>
        <PrivateRoute path="/detailsAnnounce/:id" component={DetailsAnnounce}/>
        <PrivateRoute path="/editAnnounce/:id" component={EditAnnounce}/>
        <PrivateRoute path="/library" component={Library}/>
        <PrivateRoute path="/detailslibrary" component={DetailsLibraryModal}/>
        <PrivateRoute path="/newLibrary" component={NewLibrary}/>
        <PrivateRoute path="/announcer/:id" component={Announcer}/>
        <PrivateRoute path="/newPublication" component={NewPublication}/>
        <PrivateRoute path="/promouvoir" component={Promouvoir}/>
        <PrivateRoute path="/notifications" component={Notifications}/>
        <PrivateRoute path="/partners" component={Partners}/>
        <PrivateRoute path="/adresses" component={Adresses}/>
        <PrivateRoute path="/facture/:id" component={Facture}/>
        <PrivateRoute path="/offre" component={Offre}/>
        <PrivateRoute path="/market" component={Marketplace}/>
        <PrivateRoute path="/postnew" component={PostNew}/>
        <PrivateRoute path="/settings" component={Settings}/>
        <PrivateRoute path="/subscription/:id" component={Subscription} />
        <Route exact path="/documents/templates/create" component={CreateTemplate} />
        <Route path="*" component={NotFound}/>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
