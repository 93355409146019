import React  from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

const TitleFormItem = () => {
  return (
    <Item 
      name="title"
      label="Titre"
      rules={[
        {
          required: true,
          message: "Merci d'entrer un titre."
        }
      ]}
    >
      <Input/>
    </Item>
  );
};

export default TitleFormItem;
