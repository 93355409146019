import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import apiService from "../../services/apiServices";
import { Form } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";

const MaViePrivee = ({ history }) => {
  const [newPassword, setNewPassword] = useState();
  const [newPasswordConfirm, setNewPasswordConfirm] = useState();
  const [passwordErrorMsg, setPasswordErrorMsg] = useState();

  const updatePassword = async () => {
    if (newPassword.length > 3 && newPasswordConfirm.length > 3) {
      if (newPassword === newPasswordConfirm) {
        try {
          const userId = apiService.getUserId();
          await apiService.updateUserPassword(
            { password: newPassword },
            userId
          );
          setPasswordErrorMsg();
        } catch (error) {
          if (error.response) toast.error(error.response.status);
        }
      } else {
        setPasswordErrorMsg("Les mots de passes ne correspondent pas");
      }
    } else {
      setPasswordErrorMsg(
        "Les mots de passes doivent contenir au moins 4 caractères"
      );
    }
  };

  const onCancel = () => {
    history.push("/");
  };

  return (
    <div id="page-container" className="user-profile-wrapper">
      <Toaster position="top-center" />
      <Row>
        <Col xs={12} md={3}></Col>

        <Col xs={12} md={5}>
          <center>
            <h4>Changement de mot de passe</h4>
          </center>
          <Form.Group style={{ margin: '32px 0 20px 0' }}>
            <Form.Label>Nouveau mot de passe </Form.Label>
            <Form.Control
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Confirmer le mot de passe </Form.Label>
            <Form.Control
              type="password"
              id="newPsswordConf"
              value={newPasswordConfirm}
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col flex-row" style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <button className="btn btn-link" onClick={onCancel}>
                Annuler
              </button>
            </div>
            <div className="col flex-row" style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <button
                className="btn blue-btn rounded-corner"
                onClick={updatePassword}
                style={{ marginLeft: 0 }}
              >
                Valider
              </button>
            </div>
          </div>
          <div className="col-sm-12" style={{ marginTop: 20 }}>
            <p style={{ color: "red" }}> {passwordErrorMsg} </p>
          </div>
        </Col>
        <Col xs={12} md={2} />
      </Row>
    </div>
  );
};

export default withRouter(MaViePrivee);
