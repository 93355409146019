import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Alert } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const AddUserAnnouncerModal = ({ users, ajouterUsers }) => {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button
        type="button"
        className="f-s-12 rounded-corner blue-btn"
        onClick={showModal}
      >
        ajouter employe
      </Button>
      <Modal show={open} onHide={closeModal} aria-labelledby="ModalHeader">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {users ? (
            users.map((user, i) => {
              return (
                <div className="col" key={i} >
                  <Alert className="add-employee">
                    <div className="d-flex justify-content-between add-employee-card">
                      <div className="d-flex justify-content-between">
                        <img
                          src={user.img_url ? user.img_url.data : null}
                          alt={user.username}
                          height="50px"
                          className="employee-avatar"
                        ></img>
                        <div className="m-10">
                          <Link to={"/profile/" + user._id}>
                            {user.username}&nbsp;{user.lastName}
                          </Link>
                          <h6>{user?.activite?.categorie}</h6>
                        </div>
                      </div>
                      <button
                        key={i}
                        onClick={() => ajouterUsers(user._id)}
                        className="btn my-auto rounded-corner blue-btn"
                      >
                        Ajouter
                      </button>
                    </div>
                  </Alert>
                </div>
              );
            })
          ) : (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: 290, marginBottom: 350 }}
            >
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default withRouter(AddUserAnnouncerModal);
