import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";

const MesInviationsModal = ({
  invitations,
  accept_contact,
  reject_contact,
}) => {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  return (
    <div>
      <button
        type="button"
        className="btn blue-btn rounded-corner"
        onClick={showModal}
        style={{ marginBottom: 8, marginLeft: 0 }}
      >
        Mes invitations ({invitations.length}){" "}
      </button>
      <Modal show={open} onHide={closeModal} aria-labelledby="ModalHeader">
        <Modal.Header closeButton>
          <Modal.Title id="ModalHeader">Mes invitations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Array.isArray(invitations) && invitations.length > 0 ? (
            invitations.map((invitation, i) => {
              return (
                <div className="col">
                  <Alert key={i}>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-between">
                        <img
                          src={
                            invitation.sender.img_url
                              ? invitation.sender.img_url.data
                              : null
                          }
                          alt={invitation.sender.username}
                          height="40px"
                        />
                        <div className="m-10">
                          <Link to={"/contact/" + invitation.sender._id}>
                            {invitation.sender
                              ? invitation.sender.username
                              : null}{" "}
                            {invitation.sender
                              ? invitation.sender.lastName
                              : null}
                          </Link>
                          <h6>
                            {invitation.sender.activite
                              ? invitation.sender.activite.categorie
                              : ""}
                          </h6>
                        </div>
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            accept_contact(invitation);
                            closeModal();
                          }}
                          className="btn blue-btn my-auto rounded-corner"
                        >
                          accepter
                        </button>
                        <button
                          onClick={() => {
                            reject_contact(invitation)
                            closeModal();
                          }}
                          className="btn my-auto link-btn"
                        >
                          refuser
                        </button>
                      </div>
                    </div>
                  </Alert>
                  <hr />
                </div>
              );
            })
          ) : (
            <h6> Vous n'avez aucune nouvelle invitation </h6>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default withRouter(MesInviationsModal);
