import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Form, Input, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import apiServices from '../../services/apiServices';

const ChangePwdForm = ({ token }) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const changePwd = async (body) => {
    setLoading(true);
    try {
      await apiServices.changePwd(token, body);
      message.success('success');
      history.push('../');
    } catch (e) {
      console.log(e);
      message.error('erreur lors du changement de mot de passe');
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    await changePwd(values);
  };

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: 'password missing' }
        ]}
        hasFeedback
      >
        <Input.Password
          prefix={
            <LockOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder='login password'
        />
      </Form.Item>
      <Form.Item
        dependencies={['password']}
        hasFeedback
        name="confirm"
        rules={[
          {
            required: true,
            message: 'password missing'
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('password not match');
            }
          })
        ]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder='confirmer le mot de passe'
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          Réinitialiser le mot de passe
        </Button>
      </Form.Item>
    </Form>
  );
};

ChangePwdForm.propTypes = {
  token: PropTypes.string,
  switchForm: PropTypes.func.isRequired
};

ChangePwdForm.defaultProps = {
  token: null
};

export default ChangePwdForm;
