import React from "react";
import TopHeader from "../../components/menu/TopHeader";
import ScrollToTop from "../../components/menu/ScrollToTop";
import Footer from "../../components/menu/Footer";
import Wall from "../../components/Wall";

const Notifications = () => {
  return (
    <div>
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        <TopHeader />
        <div id="content" className="content content-full-width">
        </div>
        <Footer />
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Notifications;
