const ProfileInfos = ({ announcer }) => {
  return (
    <div className="profile">
      <div className="profile-header user-header">
        <div className="profile-header-content">
          <div
            className="profile-header-img"
            style={{ marginBottom: 10 }}
          >
            <img
              src={announcer.img_url ? announcer.img_url.data : null}
              alt="Profile Pic"
            />
          </div>
          <div
            className="profile-header-info"
            style={{ marginLeft: 110, marginTop: 15 }}
          >
            <h4 className="profile-header-name">
              <b>
                {announcer.username ? announcer.username : null}{" "}
                {announcer.lastName ? announcer.lastName : null}
              </b>
            </h4>
            <p style={{ margin: "8px 0", fontStyle: "italic" }}>
              {announcer.description ? announcer.description : null}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfos;