import React, { useEffect, useState } from 'react';
import ActivityFormItem from './ActivityFormItem/ActivityFormItem';
import SubActivityFormItem from './SubActivityFormItem/SubActivityFormItem';

const Activity = ({ disabled, user }) => {
  const [category, setCategory] = useState(user.activite?.categorie);
  const [subCategory, setSubCategory] = useState();

  useEffect(() => {
    setSubCategory('');
  }, [category]);

  return (
    <>
      <ActivityFormItem setCategory={setCategory} disabled={disabled} />
      {category
        ? <SubActivityFormItem category={category} subCategory={subCategory} disabled={disabled} />
        : ''
      }
    </>
  );
};

export default Activity;