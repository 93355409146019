import React  from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

const LastNameFormItem = ({ disabled }) => {
  return (
    <Item 
      name="lastName"
      label="Nom"
      rules={[
        {
          required: true,
          message: "Merci d'entrer votre nom."
        }
      ]}
    >
      <Input disabled={disabled}/>
    </Item>
  );
};

export default LastNameFormItem;
