import React from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

const PhoneNumberFormItem = () => {
  return (
    <Item
      name="telephone"
      label="Numéro de téléphone"
    >
      <Input/>
    </Item>
  );

};

export default PhoneNumberFormItem;