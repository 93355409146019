import React, { useState } from "react"
import apiService from "../../services/apiServices";
import toast, { Toaster } from "react-hot-toast";

const PostNew = ({ getdata, reload, setReload }) => {
  const [content, setContent] = useState("");
  const [new_, setNew_] = useState(false);
  const [url, setUrl] = useState();
  const [image, setImage] = useState();

  const formOk = () => {
    if (content && content.length === 0) {
      return true;
    }
    return false;
  };

  const handleChange = () => {
    setNew_(!new_);
  };

  const onCancel = () => {
    setNew_(false);
  };

  const onChangeImage = (e) => {
    try {
      const images = e.target.files;
      const reader = new FileReader();
      const image = images[0];
      let img = {
        name: image.name,
        type: image.type,
        lastModified: image.lastModified,
      };
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        img.data = e.target.result;
      };
      setImage(img);
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  const addPublication = () => {
    try {
      apiService.postPublication({
        user: apiService.getUserId(),
        annonceur: apiService.getUserId(),
        content,
        url,
        signal: 0,
        image,
        creationDate: Date.now(),
      });
      window.location.reload();
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  return (
    <div>
      {/* begin timeline-body */}
      <Toaster position="top-center" />
      <div className="timeline-body timeline-comment-box timeline-new-post">
        <h4 style={{ color: "#0100B9", marginBottom: 15 }}>
          Nouvelle publication
        </h4>
        <div className="input">
          <div className="input-group">
            <textarea
              rows="1"
              type="text"
              className="form-control"
              placeholder="Rédiger..."
              value={content}
              onChange={(e) => setContent(e.target.value)}
              style={{ width: "100%", marginBottom: 16 }}
            />
            <span
              className="input-group-btn p-l-10"
              style={{
                width: "100%",
                display: "inline-flex",
                justifyContent: "flex-end",
                flexWrap: 'wrap'
              }}
            >
              <input
                hidden
                type="file"
                id="image"
                name="image"
                className="form-control rounded-corner"
                multiple={false}
                onChange={(e) => onChangeImage(e)}
              />
              <label
                className="btn btn-secondry f-s-12 rounded-corner blue-btn"
                htmlFor="image"
                style={{ marginBottom: 8 }}
              >
                <i className="material-icons">photo_camera</i>
                photo
              </label>
              {image && <img className="preview-img" src={image.data}></img>}
              <button
                className="btn btn-secondry f-s-12 rounded-corner blue-btn"
                onClick={handleChange}
                style={{ marginBottom: 8 }}
              >
                <i className="material-icons">movie_creation</i>
                Video
              </button>
              <button
                className="btn f-s-12 rounded-corner yellow-btn"
                onClick={addPublication}
                disabled={formOk()}
                style={{ marginBottom: 8 }}
              >
                <i className="material-icons">send</i>
                Publier
              </button>
            </span>
          </div>
          {new_ ? (
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Url..."
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          ) : null}
        </div>
      </div>

      {/* end timeline-body */}
    </div>
  );
};

export default PostNew;
