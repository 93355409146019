import React from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

const SiretNumberFormItem = ({ disabled }) => {
  return (
    <Item
      name="siret_number"
      label="Numéro SIRET"
    >
      <Input disabled={disabled} type="number"/>
    </Item>
  );

};

export default SiretNumberFormItem;