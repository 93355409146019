import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';
import apiService from "../services/apiServices";
import { useBootstrapPrefix } from "react-bootstrap/esm/ThemeProvider";

const Post = ({
  user,
  userId,
  id,
  publication,
  userConnected,
  getdata,
  date,
  lastName,
  userName,
  content,
  image,
  video,
  nbComments,
  nbLikes,
  nbShares,
  comments
}) => {
  const [userDir, setUserDir] = useState(`/profile/${userId?._id}`);
  const [comment, setComment] = useState();
  const [nbViews, setNbViews] = useState(0);
  const [cookies, setCookie] = useCookies([userId?._id]);
  
  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const publi = await apiService.getPublication(id, 'user,comments.userId');
          if (publi) {
            let userId = cookies?.user_id?.id;
            const now = new Date().getTime();

            // Vérifier si l'utilisateur a déjà visité la publication récemment
            if (!userId || (userId && now - userId.timestamp > 24 * 60 * 60 * 1000)) {
              publi.nbViews += 1;
              apiService.updatePublication({ nbViews: publi.nbViews }, publi._id);
              setNbViews(publi.nbViews);

              // Stocker l'ID de l'utilisateur dans un cookie
              setCookie('user_id', { id: publi.user._id, timestamp: now });
            } else {
              setNbViews(publi.nbViews);
            }
          }
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [id, nbViews, cookies, setCookie, publication.nbViews]);

  const addLike = async () => {
    try {
      const getPublication = await apiService.getPublication(id, 'user,comments.userId');
      const alreadyLike = getPublication?.likes.filter(l => l._id === userId._id);
      const notif = {};
      const like = {};
      like.publication = publication;
      like.user = userConnected;
      notif.receiver = publication.user;
      notif.like = like;
      if (alreadyLike.length > 0) {
        const likeIndex = getPublication.likes.findIndex(l => l._id === userId._id);
        getPublication.likes.splice(likeIndex, 1);
        await apiService.updatePublication(getPublication, id);
        await apiService.postNotification(notif);
        // ToastStore.success("Like added successfully");
      } else {
        getPublication.likes.push(userConnected);
        await apiService.updatePublication(getPublication, id);
        await apiService.postNotification(notif);
      }
      setUserDir(`/profile/${userId._id}`);
      await getdata();
    } catch (err) {
      console.log(err);
    }
  };

  const addShare = async () => {
    try {
      const notif = {};
      const share = {};
      share.publication = publication;
      share.user = userConnected;
      notif.receiver = publication.user;
      notif.share = share;
      await apiService.postShare({
        id_publication: publication,
        id_user: userConnected,
        creationDate: Date.now(),
      });
      await apiService.postNotification(notif);
      setUserDir(`/profile/${userId._id}`);
      await getdata();
    } catch (err) {
      console.log(err);
    }
  };

  const addComment = async () => {
    try {
      const pub = publication;
      const newComment = {};
      const notif = {};
      newComment.userId = userConnected;
      newComment.content = comment;
      newComment.creationDate = new Date();
      notif.receiver = pub.user;
      pub.comments.push(newComment);
      await apiService.updatePublication(pub, pub._id);
      await apiService.postNotification(notif);
      setUserDir(`/profile/${userId._id}`);
      setComment('');
      await getdata();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteComment = async (commentId) => {
    try {
      const publi = await apiService.getPublication(id, 'user,comments.userId');
      const comments = publi.comments;
      comments.map((comment, index) => {
        if (comment._id === commentId) {
          comments.splice(index, 1);
        }
      });
      publi.comments = comments;
      await apiService.deleteComment(commentId);
      await apiService.updatePublication(publi, publi._id);
      setUserDir(`/profile/${userId._id}`);
      await getdata();
    } catch (err) {
      console.log(err);
    }
  };

  const formatDate = () => {
    const fullDate = new Date(date);
    try {
      if (fullDate.getMinutes() < 0) {
        return fullDate.getHours() + ":0" + fullDate.getMinutes();
      }
      return fullDate.getHours() + ":" + fullDate.getMinutes();
    } catch (e) {
      console.log(e);
    }
  };

  const deletePublication = async () => {
    try {
      await apiService.deletePublication(id);
      setUserDir(`/profile/${userId._id}`);
      await getdata();
    } catch (e) {
      console.log(e);
    }
  };

  const signalPublication = async () => {
    try {
      // to do
    } catch (e) {
      console.log(e);
    }
  };

  const imageUrl = (url) => {
    const img = url.split("watch?v=");
    return `https://img.youtube.com/vi/${img[1]}/mqdefault.jpg`;
  };

  return (
    <li>
      <div className="timeline-icon">
        <span>&nbsp;</span>
      </div>

      {/* begin timeline-body */}
      <div className="timeline-body">
        <div className="timeline-header">
          <span>
            <span className="userimage">
              <img src={userId?.img_url ? userId?.img_url.data : null} alt="user" />
            </span>
            <span className="username">
              <Link to={userDir}>
                {" "}
                {lastName && lastName} {userName && userName}{" "}
              </Link>{" "}
              <small />
            </span>
            <span className="post-date">
              <span>{new Date(date).toLocaleDateString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</span>
              <span>{`${new Date(date).getHours()}:${new Date(date).getMinutes()}`}</span>
            </span>  
          </span>
          <span className="pull-right ">
            <span className="btn-link mr-4" onClick={signalPublication}>
              <i className="fa fa-flag 2x" />
            </span>
            {userConnected?._id === userId?._id ? (
              <button className="btn-link" onClick={deletePublication}>
                <i className="fa fa-trash 2x" />{" "}
              </button>
            ) : null}
          </span>
        </div>

        <div className="timeline-content">
          <p>{content}</p>
          {image ? (
            <div className="col-md-8 col-sm-4 m-b-2" style={{ paddingLeft: 0 }}>
              <img
                src={image ? image.data : null}
                className="width-full"
                alt="media-element"
              />
            </div>
          ) : video ? (
            <div className="col-md-8 col-sm-4 m-b-2" style={{ paddingLeft: 0 }}>
              <a href={video} data-lity>
                <img
                  src={imageUrl(video)}
                  className="width-full"
                  alt="media-element"
                />
              </a>
            </div>
          ) : null}
        </div>

        <div className="timeline-likes">
          <div className="stats-right">
            <span className="stats-text">
              {nbComments}<span style={{ marginLeft: '4px' }}>commentaire</span>{nbComments > 1 ? "s" : ""}
            </span>
            <span className="stats-text">
              {nbViews}<span style={{ marginLeft: '4px' }}>vue</span>{nbViews > 1 ? "s" : ""}
            </span>
          </div>
          <div className="stats-left">
            <div className="stats">
              <a onClick={addLike} title="Aimer">
                <i className="fa fa-thumbs-up fa-fw fa-lg m-r-3" />
              </a>
              <span className="stats-total">{nbLikes}</span>
            </div>
            <div className="stats">
              <a onClick={addShare} title="Partager">
                <i className="fa fa-share fa-fw fa-lg m-r-3" />
              </a>
              <span className="stats-total">{nbShares}</span>
            </div>
          </div>
        </div>
        <div className="timeline-comment-box">
          <div className="input">
            <div className="input-group">
              <input
                type="text"
                className="form-control rounded-corner"
                value={comment}
                placeholder="Rédiger un commentaire..."
                onChange={(e) => setComment(e.target.value)}
              />
              <span className="input-group-btn p-l-10">
                <button
                  className="btn f-s-12 rounded-corner yellow-btn"
                  onClick={addComment}
                >
                  Commenter
                </button>
              </span>
            </div>
          </div>
          <div className="timeline-comment-list">
            {comments
              ? comments.map((comment, i) => {
                  return (
                    <div key={i} className="timeline-comment">
                      <div className="user">
                        <img
                          src={
                            comment.userId
                              ? comment.userId.img_url
                                ? comment.userId.img_url.data
                                : null
                              : null
                          }
                          alt="user"
                        />
                      </div>
                      <span className="timeline-comment-user">
                        {comment && comment.userId
                          ? comment.userId.lastName
                          : null}{" "}
                        {comment && comment.userId
                          ? comment.userId.username
                          : null}{" "}
                        :{" "}
                      </span>
                      <p>{comment && comment.content}</p>
                      {userConnected?._id === comment.userId?._id ? (
                        <button
                          className="pull-right btn-link"
                          onClick={() => deleteComment(comment._id)}
                        >
                          <i className="fa fa-trash 2x"></i>{" "}
                        </button>
                      ) : null}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </li>
  );
};

export default Post;
