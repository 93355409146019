import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer text-right">
            <span>
                <Link to="/cgv">CGV</Link>
            </span>
            <span>
                <Link to="/mentionslegales">Mentions légales</Link>
            </span>
            <span>© 2023 MySocialBat Tous droits réservés - Powered by <a href="https://strateg.in" target="_blank" rel="noopener noreferrer">Strateg.in</a></span>
        </div>
    );
};

export default Footer;