import React from "react";
import TopHeader from "../../components/menu/TopHeader";
import Footer from "../../components/menu/Footer";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import { Card } from "react-bootstrap";
import addressList from "./AddressList";
import { Button } from "react-bootstrap";

const Adresses = ({ location }) => {

  return (
    <div>
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        <TopHeader user={location && location.state && location.state.user} annonceur={location && location.state && location.state.announcer} />
        <div
          id="content"
          className="profile-content content-full-width library-wrapper addresses-wrapper"
        >
          <ScrollUpButton user={location.state.user} />
          <h4>Adresses utiles</h4>
          <div className="library-content-wrapper addresses-content-wrapper">
            <div className="row">
              {addressList.map((address) => {
                return (
                  <div className="col">
                    <Card className="address-card">
                      <Card.Body>
                        <Card.Title>{address.title}</Card.Title>
                        <Card.Subtitle>{address.address}</Card.Subtitle>
                        <Card.Text>{`Téléphone: ${address.tel}`}</Card.Text>
                        <Card.Text>{`E-mail: ${address.mail}`}</Card.Text>
                        <a href={`http://${address.link}`} target="_blank" rel="noreferrer">
                          {address.link}
                        </a>
                      </Card.Body>
                    </Card>
                  </div>  
              )})}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Adresses;
