import React from 'react';
import { Form, Select } from 'antd';

const { Item } = Form;

const VisibilityFormItem = ({ disabled }) => {
  return (
    <Item
      name="visibilite"
      label="Visibilité"
      required
    >
      <Select
        disabled={disabled}
        options={[{"value": true, "label": "Tout"}, {"value": false, "label": "Contacts"}]}
      />
    </Item>
  );
};

export default VisibilityFormItem;
