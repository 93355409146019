import React, { useState, useEffect } from "react";
import PostMedia from "./PostMedia";
import PostNewMedia from "./PostNewMedia";

const ProfileMedia = ({ publications }) => {
  const [new_, setNew_] = useState(false);
  const [nbImage, setNbImage] = useState();
  const [nbVideo, setNbVideo] = useState(0);

  const imageUrl = (url) => {
    const img = url.split("watch?v=");
    return "https://img.youtube.com/vi/" + img[1] + "/mqdefault.jpg";
  };

  const filterPublicationVid = publications.reduce((acc, current) => {
    const x = acc.some(item => item?.url === current?.url);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);


  const videosMedia = () => {
    let numberVideo = 0;
    const renderVideo = filterPublicationVid.map((publication, i) => {
      if (publication && publication.url) {
        numberVideo++;
        return (
          <div className="col-md-3 col-sm-4 m-b-2">
            <a href={publication.url} data-lity>
              <img
                src={imageUrl(publication.url)}
                className="width-full"
                alt="media-element"
              />
            </a>
          </div>
        );
      }
    });
    if (numberVideo === 0) {
      return <h6>Pas de données</h6>
    }
    return renderVideo;
  };


  const filterPublicationImg = publications.reduce((acc, current) => {
    const x = acc.some(item => item?.image?.name === current?.image?.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const photosMedia = () => {
    let numberPhotos = 0;
    const renderPhotos = filterPublicationImg.map((publication, i) => {
      if (publication.image) {
        numberPhotos++;
        return (
          <div className="col-md-3 col-sm-4 m-b-2">
            <img
              src={publication.image.data}
              className="width-full"
              alt="photo"
            />
          </div>
        );
      }
    });
    if (numberPhotos === 0) {
      return <h6>Pas de données</h6>
    }
    return renderPhotos;
  };

  return (
    <div>
      <div id="page-container" className="user-profile-wrapper">
        {/* begin #profile-videos tab */}
        <div className="tab-pane fade in show active" id="profile-media">
          <center>
            <h4 className="m-t-0 m-b-20">Vidéos</h4>
          </center>
          {/* begin row */}
          <div className="row row-space-2">
            {publications ? (
               videosMedia() 
            ) : (
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: 400, marginBottom: 350 }}
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
          {/* end row */}
        </div>
        {/* end #profile-videos tab */}
        <hr />
        {/* begin #profile-photo tab */}
        <div className="tab-pane fade in show active" id="profile-media">
          <center>
            <h4 className="m-t-0 m-b-20">Photos</h4>
          </center>
          {/* begin row */}
          <div className="row row-space-2">
            {publications ? (
              photosMedia()
            ) : (
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: 400, marginBottom: 350 }}
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
          {/* end row */}
        </div>
        {/* end #profile-videos tab */}
      </div>
    </div>
  );
};

export default ProfileMedia;
