import { message } from 'antd';
import apiService from '../../services/apiServices';
import {ToastsStore} from "react-toasts";


const getRedirectionFlow = async ( user, amount, label, interval) => {
  const response = await apiService.axiosInstanceSecureUsers.post('/payment-system/gocardless', {user: user._id, amount, label, interval}, {headers: {'Content-Type': 'application/json' }});
  if (response.data) return response.data.redirect_url;
  else {
    message.error('payment.gc.errors.getdirectionflow');
  }
};

const completeRedirectionFlow = async (
    user,
    redirect_flow_id,
) => {
  const response = await apiService.axiosInstanceSecureUsers.patch('/payment-system/gocardless', {user: user, redirect_flow_id: redirect_flow_id},{headers: {'Content-Type': 'application/json' }});
  if (!response.data.errors) {
    const newUser = response.data.user;

   /* if (newUser !== undefined) {

      const response = await apiService.axiosInstanceSecureUsers.patch( `annonceurs/${newUser._id}`, newUser ,{headers: {'Content-Type': 'application/json' }});
      return newUser;
    } else message.error('payment.gc.errors.userUndefined');*/

     return (newUser);
  } else {
    message.error('payment.gc.errors.confirmdirectionflow');
  }
};

const launchPayment = async (dispatchAPI, user, amount, t, ref) => {
  const response = await dispatchAPI('POST', {
    url: '/payment-system/gocardless/payment',
    body: { user, amount: amount, reference: ref }
  });
  if (response.data) {
    message.success('payment.gc.success.paymentSuccess');
    return response.data;
  } else {
    message.error('payment.gc.errors.paymentFailed');
    return null;
  }
};

const launchSubscription = async ( user, amount, label,interval, ref) => {
  const response = await apiService.axiosInstanceSecureUsers.post('/payment-system/gocardless/subscription', { user, amount: amount,label: label, interval: interval,reference: ref },{headers: {'Content-Type': 'application/json' }});
  if (response.data) {
    ToastsStore.success('Votre abonnement est bien crée');
    window.location.reload(false);
    return response.data;
  } else {
    ToastsStore.error('Erreur ! Veuilliez réessayer s\'il vous plaît');
    return null;
  }
};

const pay = (type, user, amount,label,interval, ref) => {
  if (type === 'payment') launchPayment( user, amount,label, ref);
  else if (type === 'subscription')
    launchSubscription( user, amount, label,interval, ref);
  else message.error('payment.gc.errors.gcTypeMissing');
};

export { getRedirectionFlow, completeRedirectionFlow, pay };
