import React  from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

const AddressStreetFormItem = () => {
  return (
    <Item 
      name="address.street"
      label="Rue"
      wrapperCol={{ span: 15 }}
      labelCol={{ span: 9 }}
    >
      <Input/>
    </Item>
  );
};

export default AddressStreetFormItem;
