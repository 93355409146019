import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Row, Col, Button } from "react-bootstrap";
import { ToastsStore } from "react-toasts";
import apiService from "../../services/apiServices";
import NewAnnouncer from "./NewAnnouncer";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import ErrorList from "antd/lib/form/ErrorList";
import { isValidPhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css'

const AnnouncerModal = ({ user, refresh, setRefresh, show, loading, openModal }) => {
  const [announcer, setAnnouncer] = useState();
  const [open, setOpen] = useState(true);
  const [address, setAddress] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [errors, setErrors] = useState({
    nom: null,
    siret: null,
    phone: null,
    website: null,
    email: null
  });

  const closeModal = () => {
    setOpen(false);
    openModal(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const filledField = () => {
    if (announcer) {
      for (const [key, value] of Object.entries(errors)) {
        if (value !== null) {
          return false;
        }
      }
      if (
        announcer.nom &&
        announcer.email &&
        announcer.activite &&
        announcer.phone &&
        announcer.tranche &&
        announcer.website &&
        announcer.description &&
        address.number &&
        address.street &&
        address.postcode &&
        address.city &&
        address.country
      ) {
        return true;
      } else return false;
    }
  };

  const handleSetNumber = (value) => {
    if (!value || !isValidPhoneNumber(String(value))) {
      setErrors({
        ...errors,
        phone: "Veuillez ajouter un numéro correct"
      });
    } else {
      setErrors({
        ...errors,
        phone: null
      });
    }
    setAnnouncer({
      ...announcer,
      phone: value
    });
  }

  const handleChange = (id, value) => {
    setAnnouncer({
      ...announcer,
      [id]: value,
    });
  };

  const onChangeLogo = (event) => {
    try {
      const images = event.target.files;
      const image = images[0];

      const reader = new FileReader();
      let img = {
        name: image.name,
        type: image.type,
        lastModified: image.lastModified,
      };
      reader.readAsDataURL(image);
      reader.onload = (event) => {
        img.data = event.target.result;
      };
      handleChange("pictureUrl", img);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeCoverImage = (event) => {
    try {
      const images = event.target.files;
      const image = images[0];

      const reader = new FileReader();
      let img = {
        name: image.name,
        type: image.type,
        lastModified: image.lastModified,
      };
      reader.readAsDataURL(image);
      reader.onload = (event) => {
        img.data = event.target.result;
      };
      handleChange("imageFont", img);
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (id, value) => {
    setAddress({
      ...address,
      [id]: value,
    });
  };

  const emailValidation = (mail) => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!mail || regex.test(mail) === false) {
      setErrors({
        ...errors,
        email: "Email is not a valid email"
      });
    } else {
      setErrors({
        ...errors,
        email: null
      });
    }
  };

  const websiteValidation = (url) => {
    const regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    if (!url || regex.test(url) === false) {
      setErrors({
        ...errors,
        website: "Veuillez fournir une url correct" 
      });
    } else {
      setErrors({
        ...errors,
        website: null
      });
    }
  }

  const siretValidation = (event) => {
    if (!event || event === '' || event.length !== 14) {
      setErrors({
        ...errors,
        siret: "Veuillez remplir par un siret"
      });
    } else {
      setErrors({
        ...errors,
        siret: null
      });
    }
  }

  const handleInputSiret = (event) => {
    setAnnouncer({
      ...announcer,
      siret: event.target.value
    });
    siretValidation(event.target.value);
  }

  const handleInputEmail = (event) => {
    setAnnouncer({
      ...announcer,
      email: event.target.value
    });
    emailValidation(event.target.value);
  };

  const handleInputWebsite = (event) => {
    setAnnouncer({
      ...announcer,
      website: event.target.value
    });
    websiteValidation(event.target.value);
  }

  const addAnnouncer = async () => {
    try {
      const newAnnouncer = announcer;
      newAnnouncer.user = user._id;
      newAnnouncer.address = address;
      await apiService.postAnnonceur(newAnnouncer);
      closeModal();
      setRefresh(!refresh);
      // }
      // if (postAnnouncer.response.status_code < 300) {
      //   ToastsStore.success("L'entreprise a été créée");
      // } else {
      //   ToastsStore.error("Erreur: essayez à nouveau");
      // }
    } catch (error) {
      // ToastsStore.error("L'entreprise n'a pas été créée");
      console.log(error);
    }
  };

  return (
    <div>
      <Modal show={open} onHide={closeModal} aria-labelledby="ModalHeader" className="announcer-modal">
        <Modal.Header closeButton>
          <Modal.Title id="ModalHeader">
            Devenez un annonceur sur MySocialBat, créez votre espace entreprise
            maintenant !{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginRight: "10px", marginLeft: "10px" }}>
          <Form>
            <Form.Group>
              <Form.Label>
                Nom <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                type="text"
                id="nom"
                value={announcer ? announcer.nom : ""}
                onChange={(e) => handleChange(e.target.id, e.target.value)}
                isInvalid={ !!errors?.nom }
              />
              <Form.Control.Feedback type="invalid">{ errors?.nom }</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Siret <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                type="text"
                id="siret"
                value={announcer ? announcer.siret : ""}
                onChange={handleInputSiret}
                isInvalid={ !!errors?.siret }
              />
              <Form.Control.Feedback type="invalid">{ errors?.siret }</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Activité <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                id="activite"
                value={announcer ? announcer.activite : ""}
                onChange={(e) => handleChange(e.target.id, e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label >
                Téléphone <span className="text-danger">*</span>
              </Form.Label>
              <PhoneInput
                placeholder="Entrer votre numéro de téléphone" 
                value={announcer ? announcer.phone : null}
                onChange={handleSetNumber}
              />
              <p style={{
                    width: '100%',
                    marginTop: '0.25rem',
                    fontSize: '.875em',
                    color: '#cc4946'
                  }}>
                    { errors?.phone }
              </p>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Email <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                id="email"
                value={announcer ? announcer.email : ""}
                onChange={handleInputEmail}
                isInvalid={ !!errors?.email }
              />
              <Form.Control.Feedback type="invalid">{ errors?.email }</Form.Control.Feedback>
            </Form.Group>
            <Row>
              <Col xs={12} lg={4}>
                <Form.Group>
                  <Form.Label>
                    Numéro <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="number"
                    value={address ? address.number : ""}
                    onChange={(e) => onChange(e.target.id, e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group>
                  <Form.Label>
                    Rue <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="street"
                    value={address ? address.street : ""}
                    onChange={(e) => onChange(e.target.id, e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group>
                  <Form.Label>Complément</Form.Label>
                  <Form.Control
                    type="text"
                    id="additional"
                    value={address ? address.additional : ""}
                    onChange={(e) => onChange(e.target.id, e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <Form.Group>
                  <Form.Label>
                    Code Postal <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="postcode"
                    value={address ? address.postcode : ""}
                    onChange={(e) => onChange(e.target.id, e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group>
                  <Form.Label>
                    Ville <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="city"
                    value={address ? address.city : ""}
                    onChange={(e) => onChange(e.target.id, e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group>
                  <Form.Label>
                    Pays <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    id="country"
                    value={address ? address.country : ""}
                    onChange={(e) => onChange(e.target.id, e.target.value)}
                  >
                    <option>Choisissez votre pays</option>
                    <option>Allemagne</option>
                    <option>Belgique</option>
                    <option>France</option>
                    <option>Italie</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group>
              <Form.Label>
                Website <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                type="text"
                id="website"
                value={announcer ? announcer.website : ""}
                onChange={handleInputWebsite}
                isInvalid={ !!errors?.website }
              />
              <Form.Control.Feedback type="invalid">{ errors?.website }</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Tranche d'effectif salarié{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                id="tranche"
                value={announcer ? announcer.tranche : "0"}
                onChange={(e) => handleChange(e.target.id, e.target.value)}
              >
                <option value="0">0 salarié</option>
                <option value="1-199">entre 1 et 199 salariés</option>
                <option value="200-1999">entre 200 et 1999 salariés</option>
                <option value="2000-9999">entre 2000 et 9999 salariés</option>
                <option value="10000 ou plus ">10000 et plus</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Description <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                id="description"
                value={announcer ? announcer.description : ""}
                onChange={(e) => handleChange(e.target.id, e.target.value)}
              />
            </Form.Group>

            <Form.Group style={{ marginTop: 20 }}>
              <Row>
                <input
                  hidden
                  type="file"
                  id="pictureUrl"
                  name="pictureUrl"
                  className="form-control rounded-corner"
                  onChange={(e) => onChangeLogo(e)}
                />
                <label
                  className="btn blue-btn f-s-12 rounded-corner"
                  htmlFor="pictureUrl"
                >
                  logo
                </label>
                &nbsp;
                <input
                  hidden
                  type="file"
                  id="imageFont"
                  name="pictureUrl"
                  className="form-control rounded-corner"
                  onChange={(e) => onChangeCoverImage(e)}
                />
                <label
                  className="btn blue-btn f-s-12 rounded-corner"
                  htmlFor="imageFont"
                >
                  photo de couverture
                </label>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn-link" onClick={closeModal}>
            Annuler
          </Button>
          <Button
            type="button"
            className="btn f-s-12 rounded-corner yellow-btn"
            onClick={addAnnouncer}
            disabled={!filledField()}
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AnnouncerModal;
