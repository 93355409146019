import React from 'react'
import { Form, Upload, Button, } from 'antd';

const { Item } = Form;

const PictureUpload = ({ draggerProps, disabled, hidden }) => {
  return (
    <Item name="photo" label="Photo de profil" hidden={hidden}>
      <Upload {...draggerProps} disabled={disabled}>
        <Button type="primary" disabled={disabled}>
          Ajouter une photo
        </Button>
      </Upload>
    </Item>
  )
};

export default PictureUpload;
