import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Spin } from 'antd';
import apiService from "../../services/apiServices";
import announce3 from "../../img/annonces/empty.jpg";
import { getMyself } from "../../utils/getSetUser";

const MesAnnonces = ({ user }) => {
  const me = getMyself();
  const [announces, setAnnounces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAnnounces = useCallback(async () => {
    try {
      const announces = await apiService.getPetitesAnnonces(`id_user=${user._id}`);
      setAnnounces(announces);
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getAnnounces();
      setIsLoading(false);
    })();
  }, [getAnnounces]);

  return (
    <div className="user-profile-wrapper">
      <div className="tab-pane fade in show active" id="profile-Amis">
        <div className="row row-space-2"></div>
        <center>
          <h4 className="m-t-0 m-b-20">Petites annonces</h4>
        </center>
        <div className="row ">
          <div className="col flex-row align-right">
            {user ? (
              user?._id === me?._id ? (
                <div>
                  <Link
                    to="/newAnnounce"
                    className="btn btn-ms blue-btn rounded-corner"
                  >
                    Nouvelle annonce
                  </Link>
                </div>
              ) : null
            ) : (
              <div className="d-flex justify-content-right">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <br />
        <br />
        <Spin spinning={isLoading}>
          <div className="row">
            {announces ? (
              announces.map((announce, i) => {
                const date = new Date(announce.date_creation).toLocaleString();
                return (
                  <div key={i} className="col-md-6 m-b-20">
                    <div className="bg-white announce-card">
                      <div className="media media-xs overflow-visible">
                        <Link
                          to={"/detailsAnnounce/" + announce._id}
                          className="media-left"
                        >
                          <img
                            src={
                              announce.imgurls[0]
                                ? announce.imgurls[0].data
                                : announce3
                            }
                            height="200"
                            alt="img-friend"
                          />
                        </Link>
                        <div className="media-body valign-middle">
                          <h3>{announce.title ? announce.title : "title"}</h3>
                          <p className="announce-card-meta"> Postée le {date ? `${date.split(' ')[0]} à ${date.split(' ')[1]}` : ""}</p>
                          <p className="announce-card-meta">
                            {announce.address
                              ? (
                                <>
                                  <i class="fa fa-map-marker 2x" />
                                  <span>{announce.address.state}</span>
                                </>)
                              : ""}
                            {announce.address
                              ? announce.address.postcode
                              : ""}
                          </p>
                          <div className="row">
                            <div className="col flex-row">
                              <p  className="announce-card-category">
                                {announce.category ? announce.category : ""}
                              </p>
                            </div>
                            <div className="col flex-row">
                              <p className="announce-card-price">
                                <span>{announce.price ? announce.price : 0}€</span> HT
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>Aucune publication</p>
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default MesAnnonces;
