import React from "react";
import { Card } from "react-bootstrap";
import Cake from "../assets/img/cake.png";

const PostBirthday = ({ user }) => {
  return (
    <div>
      {/* begin timeline-body */}
      <div className="timeline-body timeline-comment-box">
        <Card
          className="text-center"
          style={{ backgroundColor: "#ADD8E6" }}
          text="white"
        >
          <Card.Body>
            <Card.Title>
              {" "}
              Joyeux anniversaire <b>{user?.username} </b>
            </Card.Title>
            <Card.Img
              className="mx-auto"
              src={Cake}
              style={{ resizeMode: "contain", width: "100px", height: "100px" }}
            />
            <Card.Text>Passez une superbe journée !</Card.Text>
          </Card.Body>
        </Card>
      </div>

      {/* end timeline-body */}
    </div>
  );
};

export default PostBirthday;
