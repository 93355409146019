import React from "react";
import { Link, withRouter } from "react-router-dom";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import SearchField from "react-search-field";
import DetailsLibraryModal from "../../pages/Library/DetailsLibraryModal";
import Button from "react-bootstrap/Button";
import DefaultDocImg from "../../assets/img/default-document.jpg";

const Library = ({
  user,
  allDocuments,
  announcer,
  toogle,
  publishDocument,
  deleteDocument,
}) => {

  return (
    <div>
      <div
        id="page-container"
        className="page-container page-without-sidebarpage-with-top-menu"
      >
        <div id="content" className="profile-content content-full-width">
          <div className="tab-pane fade in show active" id="profile-Amis">
            <ScrollUpButton />
            {/* begin row */}
            <div className="row ">
              <div
                className="col flex-row align-right "
                style={{ margin: '24px 0 20px' }}
              >
                <h3
                  style={{
                    fontSize: 21,
                    color: "#0100B9",
                    marginBottom: 15,
                    display: "inline-block",
                  }}
                >
                  Mes documents publiés
                </h3>
                {announcer.subscription ? (
                  <Link
                    to={{
                      pathname: "/NewLibrary",
                      state: { announcer: announcer },
                    }}
                    className="btn blue-btn rounded-corner"
                    style={{ float: "right" }}
                  >
                    Nouveau document
                  </Link>
                ) : (
                  <Button
                    onClick={() => toogle("5")}
                    className="btn blue-btn rounded-corner"
                    style={{ float: "right" }}
                  >
                    Abonnez-vous pour publier des documents
                  </Button>
                )}
              </div>
            </div>
            <div className="row" style={{ paddingBottom: "8px" }}>
              <div className="col">
                <SearchField
                  placeholder="Entrez votre mot de  recherche..."
                  classNames="form-control p-0"
                />
              </div>
            </div>

            {/* begin row */}
            <div className="row announcer-library" style={{ margin: "20px 0" }}>
              {/* begin col-6 */}
              {allDocuments ? (
                allDocuments.length !== 0 ? (
                  allDocuments.map((document, i) => {
                    return (
                      <div key={i} className="media-left col-xxl-4 col-xl-6 col-lg-6 col-sm-6 col-xs-12 m-b-2">
                        <div className="library-document-card">
                          <div className="media media-xs overflow-visible">
                            <div
                              className="media-body valign-middle"
                            >
                              <img
                                src={DefaultDocImg}
                                alt=""
                              />
                            </div>
                            <div className="document-info">
                              <div className="row">
                                <span className="status-document">
                                  {document.status === "DRAFT"
                                    ? "Brouillon"
                                    : "Public"}
                                </span>
                                <div className="row">
                                  {document.status === "DRAFT" && (
                                    <button
                                      className="btn-link"
                                      onClick={() => publishDocument(document._id)}
                                    >
                                      <i className="fa fa-send 10px"></i>
                                      Publier
                                    </button>
                                  )}
                                  <button
                                    className="btn-link"
                                    onClick={() => deleteDocument(document._id)}
                                  >
                                    <i className="fa fa-trash 10px"></i>
                                  </button>
                                </div>
                              </div>
                              <p className="document-name">{document.title}</p>
                              <p className="document-description">
                                {document.description}
                              </p>
                              <div
                                className="media-body valign-middle text-right overflow-visible"
                                style={{ marginTop: 20 }}
                              >
                                <DetailsLibraryModal
                                  user={announcer}
                                  document={document}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex justify-content-center">
                    <h6>Aucun document trouvé</h6>
                  </div>
                )
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}

              {/* end col-6 */}
            </div>
            {/* end row */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Library);
