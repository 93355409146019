import React  from 'react';
import { Form, Select } from 'antd';
import categories from '../../../../CentresInteret/activites.json';

const { Item } = Form;

const SubActivityFormItem = ({ disabled, category, subCategory }) => {
  const subCategories = categories.find(categoryCurrent => categoryCurrent.label === category).children;
  return (
    <Item
      label="Sous-Catégorie"
      name="activite.sous_categorie"
    >
      <Select
        disabled={disabled}
        allowClear
        options={subCategories.map((categorie) => ({label: categorie.label, value: categorie.label}))}
      />
    </Item>
  );
};

export default SubActivityFormItem;
