import React from 'react';
import { Form, Select } from 'antd';
import listCountries from '../../../../utils/listCountries';

const { Item } = Form;

const CountryFormItem = ({ disabled }) => {
  return (
    <Item
      name="pays"
      label="Pays"
      required
    >
      <Select showSearch disabled={disabled}>
        {listCountries}
      </Select>
    </Item>
  );
};

export default CountryFormItem;