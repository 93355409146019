import React, { useState, useEffect } from "react";
import { Button, Row, Form } from 'antd';
import moment from 'moment';
import toast, { Toaster } from "react-hot-toast";
import apiService from "../../../services/apiServices";
import PictureUpload from "./PictureUpload/PictureUpload";
import VisibilityFormItem from "./VisibilityFormItem/VisibilityFormItem";
import CivilityFormItem from "./CivilityFormItem/CivilityFormItem";
import FirstNameFormItem from "./FirstNameFormItem/FirstNameFormItem";
import LastNameFormItem from "./LastNameFormItem/LastNameFormItem";
import PhoneNumberFormItem from "./PhoneNumberFormItem/PhoneNumberFormItem";
import DescriptionFormItem from "./DescriptionFormItem/DescriptionFormItem";
import CountryFormItem from "./CountryFormItem/CountryFormItem";
import CityFormItem from "./CityFormItem/CityFormItem";
import StateFormItem from "./StateFormItem/StateFormItem";
import WebsiteFormItem from "./WebsiteFormItem/WebsiteFormItem";
import BirthDateFormItem from "./BirthdateFormItem/BirthDateFormItem";
import LanguageFormItem from "./LanguageFormItem/LanguageFormItem";
import Activity from './Activity/Activity';
import EmailFormItem from "./EmailFormItem/EmailFormItem";
import SiretNumberFormItem from "./SiretNumberFormItem/SiretNumberFormItem";
import { getMyself } from "../../../utils/getSetUser";


const ProfileAbout = ({ user, getdata, disabled, onCancel, appendInput }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const me = getMyself();

  const draggerProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
      } else {
        toast.error("Not a PNG or JPG file");
        return true;
      }
      return false;
    },
    fileList,
  };

  const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = e => reject(e);
  });

  
  const updateUser = async body => {
    try {
      if (fileList.length > 0) {
        let img_url = {
          name: fileList[0].name,
          lastModified: fileList[0].lastModified,
          size: fileList[0].size,
          type: fileList[0].type,
        }
        img_url.data = await fileToBase64(fileList[0]);
        body.img_url = img_url;
      }
      if (body.photo) delete body.photo;
      const data = await apiService.updateUserDetails(body, apiService.getUserId());
      localStorage.setItem('user', JSON.stringify(data));
      await getdata();
    } catch (e) {
      if (e.response) toast.error(e.response.status);
    }
  };
  
  const handleSubmit = async (values) => {
    await updateUser(values);
    appendInput();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 18 },
      sm: { span: 9 },
      md: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 9 },
      md: { span: 8 },
      lg: { span: 6 },
    },
  };


  useEffect(() => {
    form.setFieldsValue({
      visibilite: user.visibilite ? "Tout" : "Contacts",
      civilite: user.civilite,
      username: user.username,
      lastName: user.lastName,
      telephone: user.telephone,
      email: user.email,
      siret_number: user.siret_number,
      description: user.description,
      pays: user.pays || "France",
      ville: user.ville,
      region: user.region,
      website: user.website,
      date_naissance: moment(user.date_naissance),
      langue: user.langue,
      "activite.categorie": user.activite?.categorie,
      "activite.sous_categorie": user.activite?.sous_categorie
    });
  });

  return (
    <div className="user-profile-wrapper">
      <Toaster position="top-center" />
      <Form
        onFinish={handleSubmit}
        {...formItemLayout}
        form={form}>
        <PictureUpload draggerProps={draggerProps} disabled={disabled} hidden={!(user?._id === me?._id)}/>
        <VisibilityFormItem disabled={disabled}/>
        <CivilityFormItem disabled={disabled}/>
        <FirstNameFormItem disabled={disabled}/>
        <LastNameFormItem disabled={disabled}/>
        <PhoneNumberFormItem disabled={disabled}/>
        <EmailFormItem disabled={disabled}/>
        <SiretNumberFormItem disabled={disabled}/>
        <DescriptionFormItem disabled={disabled}/>
        <CountryFormItem disabled={disabled}/>
        <CityFormItem disabled={disabled}/>
        <StateFormItem disabled={disabled}/>
        <WebsiteFormItem disabled={disabled}/>
        <BirthDateFormItem disabled={disabled}/>
        <LanguageFormItem disabled={disabled}/>
        <Activity disabled={disabled} user={user}/>
        <Row justify="end" style={{gap: 10, margin: "32px 0 12px 0"}}>
          <Button type="primary" htmlType="submit" disabled={disabled}>Enregistrer</Button>
          <Button type="primary" onClick={onCancel} disabled={disabled}>Annuler</Button>
        </Row>
      </Form>
    </div>
  )
};

export default ProfileAbout;
