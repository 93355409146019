import React from 'react';
import { useParams } from 'react-router-dom';
import ChangePwdForm from './ChangePwdForm';

const ChangePasswordPage = () => {
  const { id } = useParams();

  const handleSwitchForm = () => {
    // Vous pouvez implémenter une fonction pour basculer entre différents formulaires, si nécessaire
    console.log('Switch form');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ width: '300px', padding: '2rem', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '2rem' }}>Changer le mot de passe</h2>
        <ChangePwdForm token={id} switchForm={handleSwitchForm} />
      </div>
    </div>
  );
};

export default ChangePasswordPage;
