import React  from 'react';
import { Form, Select } from 'antd';

const { Item } = Form;

const LanguageFormItem = ({ disabled }) => {
  const languages = ["Francais", "Anglais", "Allemand", "Arabe"];
  return (
    <Item
      name="langue"
      label="Langue"
      >
      <Select
        allowClear
        options={languages.map(language => ({"value": language.toLowerCase(), "label": language}))}
        disabled={disabled}
      />
    </Item>
  );
};

export default LanguageFormItem;
