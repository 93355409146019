import React, { useEffect, useState, useCallback } from "react";
import { Link, withRouter } from "react-router-dom";
import apiService from "../../../services/apiServices";
import Modal from "react-bootstrap/Modal";
import { Form, Alert } from "react-bootstrap";
import { debounce } from 'lodash';


const AddConversationModal = ({ user, onCancel, sendMessage }) => {
  const [search, setSearch] = useState(true);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [emailInput, setEmailInput] = useState();
  const [found, setFound] = useState(false);
  const [messageNotFound, setMessageNotFound] = useState();
  const [message, setMessage] = useState();
  const [searchResults, setSearchResults] = useState([]);

  const closeModal = () => {
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleChange = (value) => {
    setEmailInput(value);
    debouncedSearch(value);
  };
  
  const searchUser = async () => {
    let userFound = [];
    
    const results = await handleSearch(emailInput);
    
    results.map((usr) => {
      if (
        usr.username.includes(emailInput) ||
        usr.email.includes(emailInput)
      ) {
        if (
          usr._id
        ) {
          userFound = userFound.concat(usr);
        }
        
      }
    });
    if (userFound.length > 0) {
      setFound(true);
      setUsers(userFound);
    } else {
      setFound(false);
      setMessageNotFound("No result");
    }
  };
  
  
  const debouncedSearch = useCallback(
    debounce((query) => handleSearch(query), 300),
    []
  );
  
  const handleSearch = async (query) => {
    const results = await apiService.getUsers(query);
    const userWithEmail = results.find(user => user.email === query);
    return userWithEmail ? [userWithEmail] : [];
  };
  
  



  return (
    <div>
      <button
        type="button"
        className="btn blue-btn rounded-corner"
        onClick={showModal}
      >
        Créer une discussion
      </button>

      <Modal show={open} onHide={closeModal} aria-labelledby="ModalHeader">
        <Modal.Header closeButton>
          <Modal.Title id="ModalHeader">Créer une discussion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
            <Form.Control
  className="rounded-corner"
  type="email"
  id="text"
  placeHolder="Chercher par email..."
  value={emailInput}
  onChange={(e) => handleChange(e.target.value)}
/>
  {searchResults.length > 0 && (
    <ul>
      {searchResults.map((result) => (
        <li key={result.id}>{result.email}</li>
      ))}
    </ul>
  )}
</Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          {search ? (
            <div className="row">
              {/* <button className='btn btn-primary' > Télécharger  &nbsp; <i className="fa fa-download"></i> </button> */}
              <div className="col flex-row" style={{ display: 'flex', alignItems: 'center' }}>
                <button className="btn btn-link" onClick={onCancel}>
                  Annuler
                </button>
              </div>
              <div className="col flex-row" style={{ display: 'flex', alignItems: 'center' }}>
                <button
                  className="btn blue-btn rounded-corner"
                  onClick={searchUser}
                  onChange={handleChange}
                >
                  Chercher
                </button>
              </div>
            </div>
          ) : null}
        </Modal.Footer>
        {found ? (
  users.map((contact) => {
    return (
      <div className="col">
        <Alert>
          <div>
            <img
              src={contact.img_url ? contact.img_url.data : null}
              alt={contact.username}
              width="40px"
            ></img>
            &nbsp; &nbsp;&nbsp;
            <Link to={"/profile/" + contact._id}>
              {contact.username}&nbsp; {contact.lastName}
            </Link>
            &nbsp; &nbsp;
            <input
              rows="1"
              type="text"
              className="form-control rounded-corner"
              placeholder="Votre message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="pull-right">
              <button
                onClick={() => {
                  sendMessage(contact._id, message);
                  closeModal();
                }}
                className="btn yellow-btn rounded-corner"
              >
                Envoyer
              </button>
            </div>
          </div>
        </Alert>
        <hr />
      </div>
    );
  })
) : (
  <center>
    <h5>{messageNotFound}</h5>
  </center>
)}

      </Modal>
    </div>
  );
};

export default withRouter(AddConversationModal);
