import React, { useState } from "react";
import { Alert } from "antd";
import "antd/dist/antd.css";
import apiService from "../../services/apiServices";
import { ToastsStore } from "react-toasts";
import { withRouter } from "react-router-dom";

const Desinscription = ({ user, history }) => {
  const unsuscribe = async () => {
    try {
      await apiService.deleteUser(user._id);
      ToastsStore.success("Désinscription réussie");
      setTimeout(() => {
        history.push("/login");
      }, 2000);
    } catch (error) {
      ToastsStore.error(error.error.message);
    }
  };

  return (
    <div id="page-container" className="user-profile-wrapper">
      <Alert
        message="Attention"
        description="La désinscription est définitive !"
        type="error"
        showIcon
      />

      <center>
        <button
          type="button"
          className="btn btn-danger width-150 rounded-corner"
          style={{ marginTop: 20 }}
          onClick={() => unsuscribe()}
        >
          Me désinscrire
        </button>
      </center>
    </div>
  );
};

export default withRouter(Desinscription);
