import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import apiServices from '../../services/apiServices';

const ForgotPwdForm = ({ toggleShowForgotPwd }) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const askForPwdReset = async (email) => {
    setLoading(true);
    try {
      await apiServices.askForPwdReset(email, toggleShowForgotPwd);
      message.success("L'email a été envoyé avec succès.");
      
    } catch (e) {
      console.error('Erreur lors de la réinitialisation du mot de passe:', e);
      message.error('Erreur lors de la réinitialisation du mot de passe.');
      setLoading(false);
    }
  };
  

  const handleSubmit = async (values) => {
    await askForPwdReset(values.email);
  };

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'email missing' }]}
      >
        <Input
          prefix={
            <UserOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder="Adresse mail"
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          Réinitialiser le mot de passe
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="link"
          block
          style={{ width: '100%' }}
          onClick={toggleShowForgotPwd}
        >
          Revenir en arrière
        </Button>
      </Form.Item>
    </Form>
  );
};

ForgotPwdForm.propTypes = {
  switchForm: PropTypes.func.isRequired,
  toggleShowForgotPwd: PropTypes.func.isRequired
};

export default ForgotPwdForm;
