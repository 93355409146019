import React, { useState } from "react";
import PostS from "../postAnnouncer/Post";
import { Modal } from "antd";
import PublicationForm from "../../pages/NewPublicationSponsorise/PublicationForm";
import apiService from "../../services/apiServices";
import toast, { Toaster } from "react-hot-toast";

const AnnouncerPosts = ({ user, announcer, publications, getdata, history }) => {
  const [visible, setVisible] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [errMsgCoupon, setErrMsgCoupon] = useState();
  const [publication, setPublication] = useState();
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [image, setImage] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [childValue, setChildValue] = useState([null, null]);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  
  const closeModal = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const onChangeShow1 = () => {
    setShow1(false);
  };

  const onChangeShow2 = () => {
    setShow2(false);
  };
  const handleChangeParent = (id,value) => {
    setSelectedOption(value);
    setChildValue([id, childValue[1]]);
    setPublication({
      ...publication,
      perimetre: [id.value, childValue[1]],
    });
  };
  
  const handleChangeChild = (value) => {
    setChildValue([childValue[0], value]);
    setPublication({
      ...publication,
      perimetre: [childValue[0], value],
    });
  };
  

  const handleChange = (id, value) => {
    setPublication({
      ...publication,
      [id]: value,
    });

  };

  const addPublication = async () => {
    try {
      const newPublication = {
        ...publication,
        annonceur: announcer
      };
      await apiService.postSponsPublication(newPublication);
      closeModal();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };
  const onChangeImage = async (e) => {
    try {
      const images = e.target.files;
      const image = images[0];

      const reader = new FileReader();
      let img = {
        name: image.name,
        type: image.type,
        lastModified: image.lastModified,
      };
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        img.data = e.target.result;
        setIsImgLoaded(true);
      };
      setImage(img);
      setPublication({
        ...publication,
        image: img,
      });
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  return (
    <div className="page-header-fixed" style={{ padding: '24px 1vw' }}>
      {/* begin #profile-post tab */}
      <div className="tab-pane fade show active" id="profile-post">
        {/* begin timeline */}
        {announcer && announcer.subscription && (
          <div
            className="btn btn-lg rounded-corner announcer-new-post-btn"
            style={{ margin: "0 5vw 24px" }}
            onClick={showModal}
          >
            <i className="fa fa-plus" aria-hidden="true" style={{ marginRight: 10 }} />
            Nouvelle publication
          </div>
        )}
        {publications ? (
          <ul className="timeline">
            {publications.length !== 0 ? (
              publications
                .sort(
                  (publication1, publication2) =>
                    new Date(publication2.creationDate).getTime() -
                    new Date(publication1.creationDate).getTime()
                      )
                      .map((publication, idx) => {
                      return (
                      <div key={idx}>
                      <PostS publication={publication} getdata={getdata} />
                      </div>
                      );
                      })
                      ) : announcer?.user && announcer.user._id === user._id ? (
                      <div className="d-flex justify-content-center">
                      <div className="card text-center">
                      <div className="card-body">
                      <h5>Aucune publication n'a été trouvée !</h5>
                      <p className="card-text">
                      Vous devez avoir un abonnement afin de pouvoir faire des publications sponsorisé</p>
                      {/* <a href="" className="btn" style={{backgroundColor: '#448cb1', color:'#FFF'}}>Nouvelle publication</a> */}
                      </div>
                      </div>
                      </div>
                      ) : (
                      <div className="d-flex justify-content-center">
                      <div className="card text-center">
                      <div className="card-body">
                      <h5>Aucune publication n'a été trouvée !</h5>
                      </div>
                      </div>
                      </div>
                      )}        <li>
                      {/* begin timeline-icon */}
                      <div className="timeline-icon">
                        <span>&nbsp;</span>
                      </div>
                      {/* end timeline-icon */}
                    </li>
                  </ul>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                {/* end timeline */}
              </div>
              {/* end #profile-post tab */}
              <Modal
                visible={visible}
                onCancel={closeModal}
                title="Nouvelle publication"
                footer={null}
                className="announcer-post-modal"
              >
                <PublicationForm
                  show2={show2}
                  show1={show1}
                  publication={publication}
                  selectedOption={selectedOption}
                  childValue={childValue}
                  isImgLoaded={isImgLoaded}
                  onChangeShow1={onChangeShow1}
                  onChangeShow2={onChangeShow2}
                  handleChange={handleChange}
                  handleChangeParent={handleChangeParent}
                  handleChangeChild={handleChangeChild}
                  onChangeImage={onChangeImage}
                  image={image}
                />
                <button
                  className="btn yellow-btn rounded-corner"
                  onClick={addPublication}
                  style={{ float: "right" }}
                >
                  <i className="material-icons">send</i>
                  Publier
                </button>
                <button
                  className="btn blue-btn rounded-corner "
                  onClick={closeModal}
                  style={{ float: "right" }}
                >
                  Annuler
                </button>
              </Modal>
            </div>
);
};

export default AnnouncerPosts;            