import React from "react";
import LogImg from "../../components/reglog/LogImg";
import ConnectRight from "../../components/reglog/ConnectRight";

const Register = () => {
  return (
    <div className="pace-top bg-white">
      {/* <!-- ================== BEGIN BASE JS ================== --> */}
      {/* <script src="assets/plugins/pace/pace.min.js"></script> */}
      {/* <!-- ================== END BASE JS ================== --> */}

      <div id="page-container" className="fade show">
        <div className="register register-with-news-feed">
          <div className="news-image lock-image">
            <LogImg />
            <ConnectRight reglog="register" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
