import React  from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

const WebsiteFormItem = ({ disabled }) => {
  return (
    <Item 
      name="website"
      label="Site web"
    >
      <Input disabled={disabled}/>
    </Item>
  );
};

export default WebsiteFormItem;
