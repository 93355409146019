import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import FriendsDropdown from "./FriendsDropdown";
import apiService from "../../../services/apiServices";
import AddFriendModal from "./AddFriendModal";
import { ToastsStore } from "react-toasts";
import MesInvitationsModal from "./MesInvitationsModal";
import { getMyself } from "../../../utils/getSetUser";

const ProfileFriends = ({ user, getdata, invitations }) => {
  const me = getMyself();
  const [meTome, setMeTome] = useState(false);
  const [users, setUsers] = useState();
  const [disabledButton, setDisabledButton] = useState(false);

  const getData = useCallback(async () => {
    try {
      if (user._id !== me._id) {
        let filteredContacts = user.contact.filter((cont) =>
          me.contact.some((cont1) => cont._id === cont1._id)
        );
        setUsers(filteredContacts);
      } else {
        setUsers(user.contact);
      }
    } catch (error) {
      console.log(error);
    }
  }, [me, user]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  const inviter_contact = async (senderId, receiverId) => {
    try {
      const notif = {};
      const invitation = {};
      const receiver = await apiService.getUser(receiverId);
      invitation.user = me;
      notif.receiver = receiver;
      notif.invitation = invitation;
      setDisabledButton(true);
      if (senderId !== receiverId) {
        await apiService.postInvitation({
          sender: senderId,
          receiver: receiverId,
          date: new Date(),
        });
        await apiService.postNotification(notif);
      } else {
        setMeTome(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const accept_contact = async (invitation) => {
    try {
      await apiService.updateUserDetails(
        { $push: { contact: invitation.sender._id } },
        user._id
      );
      await apiService.updateUserDetails(
        { $push: { contact: user._id } },
        invitation.sender._id
      );
      await apiService.deleteInvitation(invitation._id);
      ToastsStore.success("Invitation acceptée");
      await getData();
    } catch (err) {
      ToastsStore.error(err.error.message);
    }
  };

  const reject_contact = async (invitation) => {
    try {
      await apiService.deleteInvitation(invitation._id);
      ToastsStore.success("Invitation has been deleted successfully");
      await getData();
    } catch (err) {
      ToastsStore.error(err.error.message);
    }
  };

  const deleteFriend = async (friend) => {
    try {
      const filteredContacts = user?.contact.filter(
        (contact) => contact !== friend
      );
      await apiService.updateUserDetails(
        { contact: filteredContacts },
        user._id
      );
      await getdata();
    } catch (err) {
      ToastsStore.error(err.error.message);
    }
  };

  const onCancel = async () => {
    await getData();
  };

  return (
    <div>
      <div id="page-container" className="user-profile-wrapper">
        <center>
          <h4 className="m-t-0 m-b-20">Réseau ({users ? users.length : 0})</h4>
        </center>
        {user ? (
          user._id === me._id ? (
            <div className="row">
              <div className="col" style={{ minWidth: 'fit-content'}}>
                <AddFriendModal
                  inviter_contact={inviter_contact}
                  onCancel={onCancel}
                  user={user}
                  disabledButton={disabledButton}
                />
              </div>
              <div className="col" style={{ minWidth: 'fit-content'}}>
                <MesInvitationsModal
                  invitations={invitations}
                  onCancel={onCancel}
                  accept_contact={accept_contact}
                  reject_contact={reject_contact}
                />
              </div>
            </div>
          ) : (
            <div className="row">
              <button
                type="button"
                className="btn btn-secondry rounded-corner blue-btn"
                onClick={() => inviter_contact(me._id, user._id)}
              >
                Envoyer une invitation
              </button>
            </div>
          )
        ) : null}
        {/* begin #profile-Amis tab */}
        <div className="tab-pane fade in show active" id="profile-Amis">
          {/* begin row */}
          <div className="row" style={{ marginTop: 20 }}>
            {users ? (
              users.map((cont, idx) => {
                return (
                  <div className="col-xs-6 col-sm-6 col-lg-4 col-xxl-3 m-b-2" key={idx}>
                    <div className="user-friend-card">
                      <div className="media media-xs overflow-visible">
                        <Link
                          to={"/profile/" + cont._id}
                          className="media-left"
                        >
                          <div className="user-avatar">
                            <img
                              src={cont.img_url ? cont.img_url.data : null}
                              className="media-object img-circle"
                              alt="img-friend"
                            />
                          </div>
                          <span>
                            {cont.lastName ? cont.lastName : null}{' '}
                            {cont.username ? cont.username : cont.email}
                          </span>
                        </Link>
                        {user._id === me._id ? (
                          <div className="media-body valign-middle text-right overflow-visible">
                            <FriendsDropdown
                              show={false}
                              contact={cont}
                              deleteFriend={deleteFriend}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: 400, marginBottom: 350 }}
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
          {/* end row */}
        </div>
        {/* end #profile-Amis tab */}
      </div>
    </div>
  );
};

export default ProfileFriends;
