import React, { useState, useEffect } from "react";
import TopHeader from "../../components/menu/TopHeader";
import Footer from "../../components/menu/Footer";
import { Link } from "react-router-dom";
import SearchField from "react-search-field";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import "react-pagination-library/build/css/index.css";
import announce3 from "../../img/annonces/empty.jpg";
import apiService from "../../services/apiServices";
import { getMyself } from "../../utils/getSetUser";

const Announces = () => {
  const [announces, setAnnounces] = useState();
  const [user, setUser] = useState();
  const [searchAnnounce, setSearchAnnounce] = useState();
  const [announcer, setAnnouncer] = useState();

  useEffect(() => {
    (async () => {
      try {
        const petitesAnnonces = await apiService.getPetitesAnnonces();
        const me = getMyself();
        const annonceurs = await apiService.getAnnonceurs(`user=${me._id}`);
        setAnnounces(petitesAnnonces);
        setUser(me);
        if (annonceurs.length > 0) {
          setAnnouncer(annonceurs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleChange = (event) => {
    setSearchAnnounce(event);
  };

  const onSearch = async () => {
    try {
      let searchAnnounces = [];
      if (searchAnnounce) {
        searchAnnounces = await apiService.getPetitesAnnonces(`keyword=${searchAnnounce}`);
      } else {
        searchAnnounces = await apiService.getPetitesAnnonces();
      }
      setAnnounces(searchAnnounces);
      setSearchAnnounce();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        {/* {user ? <TopHeader user={user} annonceur={announcer} /> : null} */}
        <TopHeader user={user} annonceur={announcer} />
        <div
          id="content"
          className="content-full-width library-wrapper announces-wrapper"
        >
          <div className="tab-pane fade in show active" id="profile-Amis">
            <ScrollUpButton />
            {/* begin row */}
            <h4 className="m-t-0 m-b-20">Petites annonces</h4>
            <div className="library-content-wrapper">
              <div className="row" style={{ margin: '0 0 16px 0' }}>
                <div className="col" style={{ paddingLeft: 0 }}>
                  <SearchField
                  searchText={searchAnnounce}
                  placeholder="Entrez un mot-clé"
                  classNames="form-control p-0"
                  onChange={(e) => handleChange(e)}
                  onSearchClick={onSearch}
                  />
                </div>
                <div className="col" style={{ paddingRight: 0 }}>
                  <Link
                    to="/newAnnounce"
                    className="btn btn-ms blue-btn rounded-corner"
                    style={{ marginLeft: 0, float: 'right' }}
                  >
                    Nouvelle annonce
                  </Link>
                </div>
              </div>
              {/* begin row */}
              {announces ? (
                <div className="row" style={{alignItems: 'stretch', rowGap: 20}}>
                  {announces ? (
                    announces.map((announce, i) => {
                      const date = new Date(
                        announce.date_creation
                      ).toLocaleString();
                      return (
                        <Link
                          to={"/detailsAnnounce/" + announce._id}
                          className="media-left col-xl-3 col-lg-4 col-md-6 col-xs-12 m-b-2"
                        >
                          <div className="bg-white announce-card">
                            <div className="media media-xs overflow-visible">
                              <div className="media-left">
                                <img
                                  src={
                                    announce.imgurls[0]
                                      ? announce.imgurls[0].data
                                      : announce3
                                  }
                                  height="200"
                                  alt="img-annonce"
                                />
                              </div>
                              <div className="media-body valign-middle">
                                <h3>
                                  {announce.title ? announce.title : "title"}
                                </h3>
                                <p className="announce-card-meta">
                                  Postée le {date ? `${date.split(' ')[0]} à ${date.split(' ')[1]}` : ""} 
                                  {announce.id_user ? (
                                    <Link to={"/profile/" + announce.id_user._id} className="announce-card-user">
                                      <span>par </span>
                                      {announce.id_user.username}{" "}
                                      {announce.id_user.lastName}
                                    </Link>
                                  ) : null}
                                </p>
                                <p className="announce-card-meta">
                                  {announce.address
                                    ? (
                                      <>
                                        <i class="fa fa-map-marker 2x" />
                                        <span>{announce.address.state}</span>
                                      </>)
                                    : ""}
                                  {announce.address
                                    ? announce.address.postcode
                                    : ""}
                                </p>
                                <div className="row">
                                  <div className="col flex-row">
                                    <p  className="announce-card-category">
                                      {announce.category ? announce.category : ""}
                                    </p>
                                  </div>
                                  <div className="col flex-row">
                                    <p className="announce-card-price">
                                      <span>{announce.price ? announce.price : 0}€</span> HT
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })
                  ) : (
                    <p>Aucune publication</p>
                  )}
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: 290, marginBottom: 350 }}
                >
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
            {/* end row */}
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Announces;
