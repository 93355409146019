import React from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

const EmailFormItem = () => {
  return (
    <Item 
      name="email"
      label="Adresse mail"
    >
      <Input/>
    </Item>
  );
};

export default EmailFormItem;
