import React, { useState, useEffect } from 'react';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { notification } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import apiService from '../../services/apiServices';
import TopHeader from "../../components/menu/TopHeader";

const stripePromise = loadStripe('pk_test_51MqAImAzgPU58G24pn8aGE7Pkyve6aNYubL6L9SAbXzja2epGlBDfEa30s2eD2SPClq97TRLO4DtDLhLxhR8LZRV003JGepAMS');
const PaymentForm = ({ priceId }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [announcer, setAnnouncer] = useState(null);
    const history = useHistory();
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      
      if (!stripe || !elements) {
        return;
      }
    
      const cardElement = elements.getElement(CardElement);
      if(!announcer?.subscription?.id == undefined) {
        history.push('../');
      }
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      });
    
      if (error) {
        console.error(error);
        notification.error({
          message: "Erreur de paiement",
          description: "Le paiement a échoué. Veuillez réessayer.",
        });
      } else {
        notification.success({
          message: "Paiement réussi",
          description: "Le paiement a été validé avec succès !",
          onClose: () => {
            history.push("../");
          },
        });
      
      const userId = apiService.getUserId();
      const fetchAnnonceurs = async () => {
        const announcers = await apiService.getAnnonceurs(); // Ajoutez "await" ici si getAnnonceurs est asynchrone
        if (Array.isArray(announcers)) {
          const announcerId = announcers.map(announcer => {
            if (announcer.user._id == userId) {
              setAnnouncer(announcer._id);
            }
          });
        } else {
          console.error('Announcers is not an array:', announcers);
        }
      };
      fetchAnnonceurs();
      
      const response = await apiService.submitSubscription({ 
          paymentMethod: paymentMethod,
          priceId: priceId,
          userId: userId 
      });

if (error) {
console.error(error);
} else {
// Envoyez l'ID utilisateur, l'ID de la méthode de paiement et l'ID du plan tarifaire au serveur pour créer un abonnement
const userId = apiService.getUserId();
const response = await apiService.submitSubscription({ 
    paymentMethod: paymentMethod,
    priceId: priceId,
    userId: userId 
});

const subscription = await response.json();
};
      // Gérez les différents états de l'abonnement (par exemple, actif, en attente, etc.)
      // Vous pouvez également rediriger l'utilisateur vers une page de remerciement ou afficher un message de succès
    }
};

return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button
        type="submit"
        disabled={!stripe}
        className="rounded-corner blue-btn"
      >
        S'abonner
      </button>
    </form>
    );
};

const Subscription = () => {
    const priceId = useParams();
    const [user, setUser] = useState();
    const [plan, setPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const planId = useParams().id;


    useEffect(() => {
      const fetchPlans = async () => {
        try {
          const response = await apiService.fetchPlans();
          response.data.data.map((plan) => {
            if (plan.default_price == planId) {
              setPlan(plan);
              setIsLoading(true);
            }
          });
        } catch (error) {
          console.error("Error fetching plans:", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchPlans();
    
      (async () => {
        try {
          const usr = await apiService.getUser(apiService.getUserId());
          setUser(usr);
        } catch (err) {
          console.log(err);
        }
      })();
    }, [planId]);
  
    return (
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu payment-wrapper"
      >
        {user ? <TopHeader user={user} /> : null}
        <h4>Finaliser votre demande d'abonnement</h4>
        <div className="stripe-form-wrapper">
          <p className="payment-subscription-name">{plan?.name}</p>
          <p className="payment-total-price">Montant total à régler : <strong>{plan?.metadata?.price} €</strong></p>
          <Elements stripe={stripePromise}>
            <PaymentForm priceId={priceId.id} />
          </Elements>
        </div>
      </div>
    );
  };
  
  export default Subscription;
  