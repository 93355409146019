import React, { useEffect, useState, useCallback } from "react";
import Post from "../../Post";
import PostNew from "../../postAnnouncer/Postnew";
import PostBirthday from "../../PostBirthday";
import PostShare from "../../PostShares";
import { getMyself } from "../../../utils/getSetUser";

const ProfilePosts = ({ publications, user, getdata }) => {
  const [birthday, setBirthday] = useState(false);

  const me = getMyself();

  const handleBirthday = useCallback(async () => {
    try {
      const date = new Date();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      if (me) {
        const userMonth = new Date(me.date_naissance).getMonth() + 1;
        const userDay = new Date(me.date_naissance).getDate();
        if (month === userMonth && day === userDay) {
          setBirthday(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [me]);

  useEffect(() => {
    (async () => {
      await handleBirthday();
    })();
  }, [handleBirthday]);

  return (
    <div className="user-profile-wrapper">
      <div className="tab-pane fade show active" id="profile-post">
        {publications ? (
          <ul className="timeline">
            {user?._id === me?._id ? <PostNew getdata={getdata} /> : null}
            {birthday === true ? (
              <div>
                <PostBirthday />
              </div>
            ) : (
              ""
            )}
            {publications ? (
              publications
                .sort(
                  (publication1, publication2) =>
                    new Date(publication2.creationDate).getTime() -
                    new Date(publication1.creationDate).getTime()
                )
                .map((publication) => {
                  if (publication)
                    return publication.id_publication ? (
                      <PostShare
                        key={publication._id}
                        publication={publication}
                      />
                    ) : (
                      <Post
                        key={publication._id}
                        userConnected={me}
                        publication={publication}
                        id={publication._id}
                        date={publication.creationDate}
                        userId={publication.user}
                        userName={publication.user.username}
                        nbOfViews={publication.nbviews}
                        nbLikes={
                          publication.likes ? publication.likes.length : 0
                        }
                        nbShares={
                          publication.nbShares ? publication.nbShares : 0
                        }
                        nbComments={
                          publication.comments ? publication.comments.length : 0
                        }
                        content={publication.content}
                        image={publication.image}
                        video={publication.url}
                        comments={publication.comments}
                        getdata={getdata}
                      />
                    );
                })
            ) : (
              <p>Aucune publication</p>
            )}

            <li>
              <div className="timeline-icon">
                <span>&nbsp;</span>
              </div>
            </li>
          </ul>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePosts;
