import React, { useState } from 'react';
import { Row, Col, Upload, Button, TreeSelect } from 'antd';
import {InboxOutlined} from '@ant-design/icons'
import useAuthContext from '../../../contexts/AuthContext';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

const { Dragger } = Upload;
const { TreeNode } = TreeSelect;

const CreateTemplate = () => {

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [type, setType] = useState();


  const props = {
    name: 'file',
    multiple: false,
    disabled: false,
    action: `http://localhost:8081/files`,
    headers : {
      Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`
    },
   /* headers: {

      Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      "Content-Type": "application/json"
    },*//*
    onChange(info) {
      const { status } = info.file;
      setDisabled(true);

      if (status === 'error') {
        console.error(`${info.file.name} file upload failed.`);
      }
    },*/
    showUploadList: {
      showRemoveIcon: false
    }
  };


  return (
    <>

        <Row>
          <Col span={8} offset={8}>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
              <InboxOutlined />
              </p>
              <p className="ant-upload-text">{'files.create.action'}</p>
              <p className="ant-upload-hint">{'files.create.formats'}</p>
            </Dragger>
          </Col>
          <Col span={8} offset={8}>
            <Button
              type="primary"
              style={{
                color: '#fff',
                float: 'right',
                backgroundColor: 'rgb(71, 210, 103)',
                border: 'rgb(71, 210, 103)',
                marginTop: 40
              }}
              onClick={() => history.goBack()}
            >
              Terminé
            </Button>
          </Col>
        </Row>
    </>
  )
};

CreateTemplate.propTypes = {
  typeSelector: PropTypes.bool,
  callback: PropTypes.func
};

CreateTemplate.defaultProps = {
  typeSelector: false,
  callback: (history) => history.push('/documents/templates')
}

export default CreateTemplate;
