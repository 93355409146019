import nexity from "../../assets/img/partners/nexity.png";
import weber from "../../assets/img/partners/Weber.png";
import tereal from "../../assets/img/partners/tereal.jpg";
import velux from "../../assets/img/partners/velux.png";
import schneider from "../../assets/img/partners/schneider.png";
import nicoll from "../../assets/img/partners/nicoll.jpg";
import manomano from "../../assets/img/partners/mano_mano.png";
import daikin from "../../assets/img/partners/daikin.png";
import cmesmat from "../../assets/img/partners/cmesmat.png";
import brico from "../../assets/img/partners/brico_prive.png";
import ffb from "../../assets/img/partners/ffb.png";
import capeb from "../../assets/img/partners/capeb.png";

const PartnersList = [
    {
        title: "Artisan/Entreprise",
        enterprises: [
            {
                name: "NEXITY",
                logo: nexity,
                link: "www.nexity.fr"
            }
        ]
    },
    {
        title: "Fabricant/Industriel",
        enterprises: [
            {
                name: "WEBER",
                logo: weber,
                link: "www.weber.fr"
            },
            {
                name: "TERREAL",
                logo: tereal,
                link: "www.terreal.com"
            },
            {
                name: "VELUX",
                logo: velux,
                link: "www.velux.fr"
            },
            {
                name: "SCHNEIDER ELECTRIC",
                logo: schneider,
                link: "www.se.com"
            },
            {
                name: "NICOLL",
                logo: nicoll,
                link: "www.nicoll.fr"
            },
        ]
    },
    {
        title: "Distributeur",
        enterprises: [
            {
                name: "DAIKIN",
                logo: daikin,
                link: "www.daikin.fr"
            }
        ]
    },
    {
        title: "Autres",
        enterprises: [
            {
                name: "FFB",
                logo: ffb,
                link: "www.ffbatiment.fr"
            },
            {
                name: "CAPEB",
                logo: capeb,
                link: "www.capeb.fr"
            }
        ]
    }
];

export default PartnersList;