import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import apiService from "../../services/apiServices";
import toast, { Toaster } from "react-hot-toast";

const Post = ({ getdata, publication, announcer, user }) => {
  const [userConnected, setUserConnected] = useState();
  const [pub, setPub] = useState(publication);
  const [comment, setComment] = useState();
  
  useEffect(() => {
    (async () => {
      try {
        const getUserId = apiService.getUserId();
        const getUser = await apiService.getUser(getUserId);
        await apiService.updateSponsPublication(
          { nbViews: (pub.nbViews += 1) },
          publication?._id
        );
        setUserConnected(getUser);
      } catch (error) {
        if (error.response) toast.error(error.response.status);
      }
    })();
  }, [pub, publication?._id]);

  const addLike = async () => {
    try {
      const alreadyLike = publication.likes.findIndex(like => like === userConnected._id);
      let verb = {
                    $addToSet: { likes: userConnected._id },
                  };
      if (alreadyLike > -1) {
        verb = {
                  $pull: { likes: userConnected._id },
                };
      }
      await apiService.updateSponsPublication(verb, publication._id);
      setPub(publication);
      await getdata();
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  const addComment = async () => {
    try {
      const newComment = {};
      newComment.id_user = userConnected._id;
      newComment.content = comment;
      newComment.creationDate = new Date();
      await apiService.updateSponsPublication(
        {
          $push: { comments: newComment },
        },
        pub._id
      );
      setComment();
      await getdata();
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  const deleteComment = async (id) => {
    try {
      const comments = publication.comments;
      comments.map((com, index) => {
        if (com._id === id) {
          comments.splice(index, 1);
        }
      });
      await apiService.updateSponsPublication(publication, publication._id);
      setPub(publication);
      await getdata();
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  const formatDate = () => {
    const fullDate = new Date(publication.creationDate);
    try {
      if (fullDate.getMinutes() < 10) {
        return `${fullDate.getHours()}:0${fullDate.getMinutes()}`;
      }
      return `${fullDate.getHours()}:${fullDate.getMinutes()}`;
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  const deletePublication = async () => {
    try {
      await apiService.deleteSponsPublication(publication._id);
      setPub(publication);
      toast.success("La publication a été bien supprimée");
      await getdata();
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  const imageUrl = (url) => {
    const img = url.split("watch?v=");
    return `https://img.youtube.com/vi/"${img[1]}"/mqdefault.jpg`;
  };

  return (
    <>
      <Toaster position="top-center" />
      {publication ? (
        <li>

          <div className="timeline-icon">
            <span>&nbsp;</span>
          </div>

          {/* begin timeline-body */}
          <div className="timeline-body">
            <div className="timeline-header">
              <span>
                <span className="userimage">
                  <img
                    src={
                      publication.annonceur
                        ? publication.annonceur.pictureUrl
                          ? publication.annonceur.pictureUrl.data
                          : null
                        : null
                    }
                    alt="user"
                  />
                </span>
                <span className="username">
                  <Link
                    to={{
                      pathname: "/announcer",
                      state: { announcer: announcer },
                    }}
                  >
                    {" "}
                    {publication.annonceur ? publication.annonceur.nom : ""}{" "}
                  </Link>{" "}
                  <small />
                </span>
                
                <span className="post-date">
                  <span>{new Date(publication.creationDate).toLocaleDateString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                  <span>{formatDate()}</span>
                </span>
              </span>
              
              {/* <span className="pull-right text-muted">{props.nbOfViews} vue{(props.nbOfViews > 1) ? "s" : ""}</span> */}

              <span>
                <span
                  className="sponsored-post-tag"
                  style={{
                    marginRight:
                      userConnected?._id === publication?.annonceur?.user ? 8 : 0,
                  }}
                >
                  Publication sponsorisée
                </span>
                {userConnected?._id === publication?.annonceur?.user ? (
                <button
                  className="pull-right btn-link"
                  onClick={deletePublication}
                >
                  <i className="fa fa-trash 2x"></i>{" "}
                </button>
              ) : null}
              </span>
              
            </div>

            <div className="timeline-content">
              <h4>{publication.title}</h4>
              <p>{publication.content} </p>
              {publication.image ? (
                <div
                  className="col-md-8 col-sm-4 m-b-2"
                  style={{ paddingLeft: 0 }}
                >
                  <img
                    src={publication.image ? publication.image.data : null}
                    className="width-full"
                    alt="media-element"
                  />
                </div>
              ) : publication.url ? (
                <div
                  className="col-md-8 col-sm-4 m-b-2"
                  style={{ paddingLeft: 0 }}
                >
                  <a href={publication.url} data-lity>
                    <img
                      src={imageUrl(publication.url)}
                      className="width-full"
                      alt="media-element"
                    />
                  </a>
                </div>
              ) : null}
            </div>

            <div className="timeline-likes">
              <div className="stats-right">
                <span className="stats-text">
                  {publication.nbComments ? publication.nbComments : 0}{" "}
                  commentaire{publication.nbComments > 1 ? "s" : ""}
                </span>
                <span className="stats-text">
                  {publication.nbViews} vue{publication.nbViews > 1 ? "s" : ""}
                </span>
              </div>
              <div className="stats-left">
                <div className="stats">
                  <a onClick={addLike} title="Aimer">
                    <i className="fa fa-thumbs-up fa-fw fa-lg m-r-3" />
                  </a>
                  <span className="stats-total">
                    {publication.likes ? publication.likes?.length : 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="timeline-comment-box">
              <div className="input">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control rounded-corner"
                    value={comment}
                    placeholder="Rédiger un commentaire..."
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <span className="input-group-btn p-l-10">
                    <button
                      className="btn f-s-12 rounded-corner yellow-btn"
                      onClick={addComment}
                    >
                      Commenter
                    </button>
                  </span>
                </div>
              </div>
              <div className="timeline-comment-list">
                {publication.comments
                  ? publication.comments.map((comment, i) => {
                      return (
                        <div key={i} className="timeline-comment">
                          <div className="user">
                            <img
                              src={
                                comment?.id_user?.img_url
                                  ? comment?.id_user?.img_url?.data
                                  : null
                              }
                              alt="user"
                            />
                          </div>
                          <span className="timeline-comment-user">
                            {comment?.id_user?.lastName}{" "}
                            {comment?.id_user?.username} :{" "}
                          </span>
                          <p>{comment.content}</p>
                          {userConnected?._id === comment?.id_user?._id ? (
                            <button
                              className="pull-right btn-link"
                              onClick={() => deleteComment(comment?._id)}
                            >
                              <i className="fa fa-trash 2x"></i>{" "}
                            </button>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </li>
      ) : (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Post;
