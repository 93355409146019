import React from "react";
import Logo from '../../assets/img/logo.svg';

const LogImg = () => {
  return (
    <div className="news-feed">
      <div className="news-caption">
        <p>
          Le réseau social des acteurs du BTP.
          <br />
          Entrez en relation avec vos collègues pour échanger sur vos bonnes
          pratiques, trouver de bons plans, recruter... vous rendre la vie plus
          facile !
        </p>
      </div>
    </div>
  );
};

export default LogImg;
