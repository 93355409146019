import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import MaterialTable from "material-table";

const columns = [
  { title: "Titre", field: "title" },
  { title: "Date de création", field: "creationDate" },
  {
    title: "Vues",
    field: "nbViews",
  },
  { title: "Commentaires", field: "nbComments" },
  { title: "J'aimes", field: "nbLikes" },
];

const Promouvoir = ({ publications }) => {
  return (
    <Container>
      <Row>
        <Col xs={12} md={1} />
        <Col xs={12} md={10}>
          <div className="announcer-promote-wrapper">
            <center>
              <h3 style={{ marginBottom: 25 }}>Promouvoir</h3>
            </center>
            <MaterialTable
              title="Mes publications sponsorisées"
              columns={columns}
              data={publications}
            />
          </div>
        </Col>

        <Col xs={12} md={1}></Col>
      </Row>
    </Container>
  );
};

export default withRouter(Promouvoir);
