import React from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

const DurationFormItem = ({ disabled }) => {
  return (
    <Item
      name="duration"
      label="Durée (jours)"
      rules={[
        {
          required: true,
          message: "Merci d'entrer une durée."
        }
      ]}
    >
      <Input type="number"
        max={30}
        min={3}
        disabled={disabled}
      />
    </Item>
  );

};

export default DurationFormItem;