import React from 'react'
import { Form, Upload, Button, } from 'antd';

const { Item } = Form;

const PictureUpload = ({ draggerProps }) => {
  return (
    <Item name="photo" label="Image">
      <Upload {...draggerProps}>
        <Button type="primary">
          Ajouter une photo
        </Button>
      </Upload>
    </Item>
  )
};

export default PictureUpload;
