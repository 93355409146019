import React  from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

const StateFormItem = ({ disabled }) => {
  return (
    <Item 
      name="region"
      label="Région"
    >
      <Input disabled={disabled}/>
    </Item>
  );
};

export default StateFormItem;
