import React, { useState, useEffect } from "react";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Form, Button, Row, Spin } from 'antd';
import "mdbreact/dist/css/mdb.css";
import toast, { Toaster } from "react-hot-toast";
import TopHeader from "../../components/menu/TopHeader";
import Footer from "../../components/menu/Footer";
import apiService from "../../services/apiServices";
import { getMyself } from "../../utils/getSetUser";
import ProfileInfos from "./ProfileInfos/ProfileInfos";
import TitleFormItem from "./TitleFormItem/TitleFormItem";
import CategoryFormItem from "./CategoryFormItem/CategoryFormItem";
import PriceFormItem from "./PriceFormItem/PriceFormItem";
import AddressFormItem from "./AddressFormItem/AddressFormItem";
import PhoneNumberFormItem from "./PhoneNumberFormItem/PhoneNumberFormItem";
import EmailFormItem from "./EmailFormItem/EmailFormItem";
import DescriptionFormItem from "./DescriptionFormItem/DescriptionFormItem";
import DurationFormItem from "./DurationFormItem/DurationFormItem";
import PictureUpload from "./PictureUpload/PictureUpload";
import { useCallback } from "react";

const CreateUpdateAnnounce = ({ history, id }) => {
  const announcer = getMyself();
  const [announce, setAnnounce] = useState();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAnnounce = async () => {
    try {
      const announce = await apiService.getPetitesAnnonce(id);
      setAnnounce(announce);
    } catch (err) {
      console.log(err);
    }
  };

  
  const formItemLayout = {
    labelCol: {
      xs: { span: 18 },
      sm: { span: 9 },
      md: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 20 },
      sm: { span: 12 },
      md: { span: 10 },
      lg: { span: 7 },
    },
  };
  
  const onCancel = () => {
    history.push({ pathname: "/profile/me", state: "announces" });
  };
  
  const draggerProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension !== 'png' &&
        fileExtension !== 'PNG' &&
        fileExtension !== 'jpg' &&
        fileExtension !== 'JPG'
        ) {
          toast.error("Not a PNG or JPG file");
          return true;
      }
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };
  
  const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = e => reject(e);
  });
  
  const postOrPatchAnnounce = async body => {
    try {
      let img_url;
      if (fileList.length > 0) {
        img_url = {
          name: fileList[0].name,
          lastModified: fileList[0].lastModified,
          size: fileList[0].size,
          type: fileList[0].type,
          data: await fileToBase64(fileList[0]),
        }
      } else {
        img_url = announcer.img_url;
      }
      if (body.photo) delete body.photo;
      body.imgurls = [img_url];
      body.id_user = announcer._id;
      
      if (id) {
        await apiService.updatePetiteAnnonceDetails(body, id);
      } else {
        body.date_creation = new Date();
        await apiService.postPetitesAnnonce(body);
      };
      
      history.push({ pathname: "/profile/me", state: "announces" })
    } catch (e) {
      if (e.response) toast.error(e.response.status);
    }
  };
  
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    id && await getAnnounce();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    announce && (
      form.setFieldsValue({
        title: announce.title,
        category: announce.category,
        price: announce.price,
        "address.number": announce.address?.number,
        "address.street": announce.address?.street,
        "address.additional": announce.address?.additional,
        "address.postcode": announce.address?.postcode,
        "address.city": announce.address?.city,
        "address.country": announce.address?.country,
        telephone: announce.telephone,
        email: announce.email,
        content: announce.content,
        duration: announce.duration,
      })
    );
  }, [announce, form, id]);

  return (
    <div>
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        {announcer ? <TopHeader user={announcer} /> : null}

        {announcer ? (
          <div id="content" className="content content-full-width" style={{height: 'calc(100vh - 100px)', overflowY: 'auto', overflowX: 'hidden'}}>
            <ProfileInfos announcer={announcer} />
            <Container fluid style={{ lineHeight: "32px", padding: 0 }}>
              <ScrollUpButton />
              <div
                className="profile-content announce-form-wrapper"
                style={{
                  width: "100%",
                }}
              >
                <div id="page-container" className="user-profile-wrapper">
                  <center>
                    {id ? <h4>Modifier l'annonce</h4> : <h4>Nouvelle annonce</h4>}
                  </center>
                    <Toaster position="top-center" />
                    <Spin spinning={isLoading}>
                      <Form
                        {...formItemLayout}
                        onFinish={postOrPatchAnnounce}
                        form={form}>
                        <TitleFormItem/>
                        <CategoryFormItem/>
                        <PriceFormItem/>
                        <AddressFormItem/>
                        <PhoneNumberFormItem/>
                        <EmailFormItem/>
                        <DescriptionFormItem/>
                        <DurationFormItem disabled={id ? true : false}/>
                        <PictureUpload draggerProps={draggerProps}/>
                        <Row justify="end" style={{gap: 10, margin: "32px 0 12px 0"}}>
                          <Button type="primary" htmlType="submit" >Enregistrer</Button>
                          <Button type="primary" onClick={onCancel}>Annuler</Button>
                        </Row>
                      </Form>
                    </Spin>
                </div>
              </div>
            </Container>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 400, marginBottom: 350 }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
};

export default withRouter(CreateUpdateAnnounce);
