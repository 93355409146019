import React, { useEffect, useState } from 'react';
import { Row, Button, message } from 'antd';
import { Route } from 'react-router-dom';
import { Form } from 'antd/lib/form';
import { useLocation } from 'react-router-dom';
import { getRedirectionFlow, completeRedirectionFlow, pay } from './GCUtils';

const PaymentGocardless = props => {

    const params = useLocation().search;
    const isGCCallback = useLocation().search !== '';
    const [redirectURI, setRedirectURI] = useState(null);

    const chooseGCAction = async () => {
        if (props.user.gcMandate === undefined) {
            const redirection = await getRedirectionFlow( props.user, props.amount, props.label, props.interval);
            setRedirectURI(redirection);
            if (redirection !== undefined) window.location.href = redirection;
        } else {
            await pay(
                props.type,
                props.user,
                props.amount,
                props.label,
                props.interval,
                props.reference
            );
        }
    };



    return redirectURI === null ? (
        <>
            <Row justify={'center'}>
                <Button
                    type="primary"
                    className="rounded-corner yellow-btn"
                    onClick={() => chooseGCAction()}
                >
                    Payer
                </Button>
            </Row>
        </>
    ) : (
        <Route
            path="/payment-system"
            component={() => {
                window.location.href = redirectURI;
                return null;
            }}
        />
    );
};

export default PaymentGocardless;
