import React from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

const PhoneNumberFormItem = ({ disabled }) => {
  return (
    <Item
      name="telephone"
      label="Numéro"
      rules={[
        {
          required: true,
          message: "Merci d'entrer votre numéro."
        }
      ]}
    >
      <Input disabled={disabled}/>
    </Item>
  );

};

export default PhoneNumberFormItem;