import React, { useState, useEffect } from "react";
import TopHeader from "../../components/menu/TopHeader";

import Footer from "../../components/menu/Footer";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import apiService from "../../services/apiServices";
import partnersList from "./PartnersList";

const Partenaire = () => {
  const [announcer, setAnnouncer] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    (async () => {
      try {
        const usr = await apiService.getUser(apiService.getUserId());
        const annonceurs = await apiService.getAnnonceurs(`user=${usr._id}`);
        setUser(usr);
        if (annonceurs.length !== 0) {
          setAnnouncer(annonceurs[0]);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div>
      <div
        id="page-container"
        className="page-container page-without-sidebar page-header-fixed page-with-top-menu"
      >
        <TopHeader user={user} annonceur={announcer} />

        <div
          id="content"
          className="profile-content content-full-width library-wrapper partners-wrapper"
        >
          <ScrollUpButton />
          <h4>Entreprises partenaires</h4>
          <div className="library-content-wrapper partners-content-wrapper">
            <div className="row">
              {partnersList.map((partner) => {
                return (
                  <div className="col">
                    <h5>{partner.title}</h5>
                  <>
                    {
                    partner.enterprises && partner.enterprises.length > 0 && partner.enterprises.map((enterprise) => {
                      return (
                      <Card className="partner-card">
                        <Card.Img variant="top"  src={enterprise.logo} />
                        <Card.Body>
                          <Card.Title>{enterprise.name}</Card.Title>
                          <a href={`http://${enterprise.link}`} target="_blank" rel="noreferrer">
                            <Button
                              className="btn rounded-corner blue-btn"
                              style={{ marginLeft: 0 }}
                            >
                              {enterprise.link}
                            </Button>
                          </a>
                        </Card.Body>
                      </Card>
                    )})
                    }
                  </>
                  </div>
                )    
              })}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Partenaire;
