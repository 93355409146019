import React from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;
const { TextArea } = Input;

const DescriptionFormItem = () => {
  return (
    <Item
      name="content"
      label="Description"
      rules={[
        {
          required: true,
          message: "Merci d'entrer une description."
        }
      ]}
    >
      <TextArea/>
    </Item>
  );
};

export default DescriptionFormItem;