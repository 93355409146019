import React, { useState } from "react";
import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";
import PropTypes from "prop-types";
import Logo from '../../assets/img/logo.svg';

const ConnectRight = ({ reglog }) => {
  const [type, setType] = useState(reglog);

  return (
    <div className="right-content">
      {/* <!-- begin register-header --> */}
      <div className="logo-div">
        <img src={Logo} alt="My SocialBat" />
        MySocialBat 
      </div>
      <h1 className="register-header">
        {type === "register" ? "Inscription" : "Connexion"}
        {/* <small>
                  {(this.state.type === 'register')
                      ? 'Create your Color Admin Account. It’s free and always will be.'
                      : 'Create your Color Admin Account. It’s free and always will be.'
                  }
              </small> */}
      </h1>
      {/* <!-- end register-header --> */}

      {type === "register" ? <RegisterForm /> : <LoginForm />}
    </div>
  );
};

ConnectRight.defaultProps = {
  type: null,
};

ConnectRight.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ConnectRight;
