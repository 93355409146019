import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import apiService from "../../../services/apiServices";
import AddConversationModal from "./AddConversationModal";
import { ToastsStore } from "react-toasts";
import "react-chat-elements/dist/main.css";
import { ChatItem } from "react-chat-elements";
import { MessageList } from "react-chat-elements";
import { Input } from "react-chat-elements";
import { Navbar } from "react-chat-elements";
import { Avatar } from "react-chat-elements";
import { Button } from "react-chat-elements";
import io from "socket.io-client";
import config from "../../../config";

const ProfileMessaging = ({ user, onCancel }) => {
  const [id, setId] = useState();
  const [message, setMessage] = useState();
  const [to, setTo] = useState();
  const [from, setFrom] = useState();
  const [conversations, setConversations] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [loading, setLoading] = useState();
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(`${config.database_url}?id_user=${user._id}`);
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [user]);
  
  const connect = useCallback(() => {
    if (socket) {
    socket.on(`chat message`, async (msg) => {
      let conversation = conversations[msg.id_sender];
      if (id === msg.id_sender) {
        await apiService.setMessageIsRead(msg._id);
        msg.isRead = true;
      }
      conversation.messages.push(msg);
      setConversations({
        ...conversations,
        [msg.id_sender]: conversation,
      });
    });
  }
  }, [id, socket, conversations, apiService]);

  const getData = useCallback(async () => {
    try {
      connect(apiService.getUserId());
      const messages = await apiService.getMessagesByUser(
        apiService.getUserId()
      );
      if (messages) {
        setConversations(messages);
        if (!id) {
          setId(messages.length !== 0 ? Object.keys(messages)[0] : null);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [connect, id]);


  const newConversation = async (id, message) => {
    if (message) {
      let new_message = {
        id_sender: apiService.getUserId(),
        id_receiver: id,
        isRead: false,
        content: message,
        sendDate: Date.now().toString(),
      };
      socket.emit(`chat message`, new_message);
      await apiService.postMessage(new_message);
      await getData();
    } else {
      ToastsStore.error("Veuillez saisir un message à envoyer");
    }
  };

  const sendMessage = async () => {
    const notif = {};
    const msg = {};
    const getUser = await apiService.getUser(id);
    const getUserId = await apiService.getUserId();
    const getUser2 = await apiService.getUser(getUserId);
    msg.user = getUser2;
    notif.receiver = getUser;
    notif.message = msg;
    if (message) {
      let new_message = {
        id_sender: apiService.getUserId(),
        id_receiver: id,
        isRead: false,
        content: message,
        sendDate: new Date().toISOString(),
      };
      let conversation = conversations[id] || { messages: [], other: id };
      conversation.messages.push(new_message);
      setConversations({
        ...conversations,
        [id]: conversation,
      });
      socket.emit("chat message", new_message);
      apiService.postMessage(new_message);
      await apiService.postNotification(notif);
      setMessage();
    } else {
      ToastsStore.error("Veuillez saisir un message à envoyer");
    }
  };

  const itemsEvent = (id_conversation) => {
    let newConversations = { ...conversations };
    newConversations[id_conversation].messages.forEach((message) => {
      if (
        !message.isRead &&
        message.id_receiver._id === apiService.getUserId()
      ) {
        message.isRead = true;
        apiService.setMessageIsRead(message._id);
      }
    });
    setConversations(newConversations);
    setId(id_conversation);
};


  const handleChangeMessageInput = (message) => {
    setMessage(message);
  };

  useEffect(() => {
    (async () => {
      await getData();
      setLoading(false);
    })();
  }, [getData]);

  useEffect(() => {
    if (id) {
      const data = [];
      conversations[id].messages.forEach((message) => {
        data.push({
          position: message.id_sender._id
            ? message.id_sender._id === id
              ? "left"
              : "right"
            : message.id_sender === id
            ? "left"
            : "right",
          type: "text",
          text: message.content,
          date: new Date(message.sendDate.toString()),
        });
      });
      setDataSource(data);
    }
  }, [id, conversations]);

  return (
    <div id="page-container" className="user-profile-wrapper">
      <center>
        <h4 className="m-t-0 m-b-20">Messagerie</h4>
      </center>
      <div className="row">
        <div className="col-sm-4" style={{ paddingBottom: 10 }}>
          <AddConversationModal
            sendMessage={newConversation}
            onCancel={onCancel}
            user={user}
          />
        </div>
        {id ? (
          <div className="col-sm-8" style={{ padding: 0, margin: 0 }}>
            <Navbar
              left={
                <div className="friend-message-title">
                  <Avatar
                    src={
                      conversations[id].other.img_url
                        ? conversations[id].other.img_url.data
                        : null
                    }
                    alt={"logo"}
                    size="large"
                    type="circle flexible"
                  />

                  <span>
                    {id ? conversations[id].other.lastName : "Alice"}{" "}
                    {id ? conversations[id].other.username : ""}
                  </span>
                </div>
              }
            />
          </div>
        ) : null}
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-4">
          {Object.keys(conversations).map((id_conv) => {
            const conversation = conversations[id_conv];
            return (
              <ChatItem
                avatar={
                  conversation.other.img_url
                    ? conversation.other.img_url.data
                    : null
                }
                alt={"Photo"}
                title={<>{conversation.other.lastName}{' '}{conversation.other.username}</>}
                key={id_conv}
                subtitle={
                  conversation.messages[conversation.messages.length - 1]
                    .content
                }
                onClick={() => itemsEvent(id_conv)}
                date={conversation.messages[
                  conversation.messages.length - 1
                ].sendDate.toLocaleString()}
                value={id_conv}
                unread={
                  conversation.messages.filter(
                    (message) =>
                      message.id_receiver._id === apiService.getUserId() &&
                      !message.isRead
                  ).length
                }
              />
            );
          })}
        </div>

        <div className="col-xs-12 col-sm-8 message-wrapper">
          <MessageList
            className="message-list"
            lockable={true}
            toBottomHeight={"100%"}
            dataSource={dataSource ? dataSource : []}
          />
        </div>
      </div>
      {!loading ? (
        id ? (
          <div className="row">
            <div className="col-xs-12 col-sm-4" />
            <div
              className="col-xs-12 col-sm-8"
              style={{
                padding: 0,
                margin: 0
              }}
            >
              <Input
                placeholder="Tapez votre message ici..."
                multiline={false}
                onChange={(e) => handleChangeMessageInput(e.target.value)}
                rightButtons={
                  <Button
                    onClick={() => {
                      sendMessage();
                    }}
                    text={"Envoyer"}
                  />
                }
              />
            </div>
          </div>
        ) : (
          <div style={{ marginTop: 32 }}>
            <h5 style={{ fontSize: 18 }}>
              Vous n'avez pas de message à afficher.
            </h5>
            <h5 style={{ fontSize: 16 }}>
              Contactez un annonceur à partir d'une annonce pour commencer une
              discussion.
            </h5>
          </div>
        )
      ) : (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 350, marginBottom: 350 }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileMessaging;
