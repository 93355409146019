import React from "react";
import { withRouter } from "react-router-dom";
import apiService from "../../services/apiServices";
import Dropdown from "react-bootstrap/Dropdown";


const NotifDropdown = ({ user, history }) => {

  const sender = async () => {
    try {
      await apiService.logout();
      history.push("/login");
    } catch (err) {
      console.log(err);
    }
  };

  const profileIcon = () => {
    return (
    user ? (
      <span className="dropdown-toggle" data-toggle="dropdown">
        <img src={user.img_url ? user.img_url.data : null} alt="profile" />
      </span>
    ) : null)
  }

  return (
    <Dropdown drop="start">
      <Dropdown.Toggle className="dropdown navbar-user show" id="profile-dropdown">
        {profileIcon()}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="/profile/me" className="dropdown-item">
          Profil
        </Dropdown.Item>
        <Dropdown.Item href="/settings" className="dropdown-item">
          Paramètres
        </Dropdown.Item>
        <Dropdown.Item onClick={sender} className="dropdown-item">
          Déconnexion
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default withRouter(NotifDropdown);
