import React from 'react';
import CreateUpdateAnnounce from '../CreateUpdateAnnounce/CreateUpdateAnnounce';

const EditAnnounce = ({ match, history }) => {
  const id = match.params.id;
  return <CreateUpdateAnnounce history={history} id={id} />;
};

export default EditAnnounce;

