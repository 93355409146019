import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { withRouter } from "react-router-dom";
import apiService from "../../services/apiServices";
import { Redirect } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const RegisterForm = ({ history }) => {
  const [email, setEmail] = useState();
  const [pwd, setPwd] = useState();
  const [maClasse, setMaClasse] = useState();
  const [alertInfos, setAlertInfos] = useState("rien");
  const [confirmPwd, setConfirmPwd] = useState();
  const [terms, setTerms] = useState(false);
  const [formOk, setFormOk] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [activity, setActivity] = useState();
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [success, setSuccess] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [category, setCategory] = useState();
  const [tryValid, setTryValid] = useState(false);

  const passwordVerification = () => {
    if (pwd !== confirmPwd) {
      setMaClasse("infos");
      setAlertInfos("alertInfos");
      setFormOk(false);
      return false;
    } else {
      setAlertInfos("rien");
      setFormOk(true);
      return true;
    }
  };

  const filledFields = () => {
    if (
      firstName &&
      lastName &&
      email &&
      pwd &&
      confirmPwd &&
      formOk &&
      terms
    ) {
      return true;
    }
    return false;
  };

  const sender = async () => {
    try {
      const newUser = {
        username: firstName,
        lastName,
        password: pwd,
        email,
        "activite.categorie": category,
      };
      const response = await apiService.register(newUser);
      if (response.status === 400) {
        setTryValid(false);
      } else {
        setTryValid(true);
        setSuccess(true);
        await apiService.signInWithEmailAndPassword(email, pwd);
        history.push('/home');
      }
    } catch (error) {
      if (error.response) toast.error(error.response.status);
    }
  };

  return (
    <>
      {success ? (
        <Redirect to="/home" />
      ) : (
        <div className="register-content">
          <form className="margin-bottom-0">
            <Toaster position="top-center" />
            <label className="control-label">
              Nom <span className="text-danger">*</span>
            </label>
            <div className="row row-space-10">
              <div className="col-md-6 m-b-15">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Prénom"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="col-md-6 m-b-15">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nom"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </div>
            <label className="control-label">
              Email <span className="text-danger">*</span>
            </label>
            <div className="row m-b-15">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Adresse email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <label className="control-label">
              Mot de passe (4 caractères minimum){" "}
              <span className="text-danger">*</span>
            </label>
            <div className="row m-b-15">
              <div className="col-md-12">
                <input
                  type="password"
                  min="4"
                  className={"form-control " + maClasse}
                  name="pwd"
                  placeholder="Mot de passe"
                  // onKeyUp={passwordVerification}
                  onChange={(e) => setPwd(e.target.value)}
                />
              </div>
            </div>

            <label className="control-label">
              Confirmer mot de passe <span className="text-danger">*</span>
            </label>
            <div className="row m-b-15">
              <div className="col-md-12">
                <input
                  type="password"
                  className={"form-control " + maClasse}
                  placeholder="Mot de passe"
                  name="confirmPwd"
                  onKeyUp={passwordVerification}
                  onChange={(e) => setConfirmPwd(e.target.value)}
                />
              </div>
              <span className={alertInfos}>Mots de passe non identiques</span>
            </div>

            <div className="checkbox checkbox-css m-b-30">
              <div className="checkbox checkbox-css m-b-30">
                <input
                  type="checkbox"
                  id="agreement_checkbox"
                  value=""
                  name="terms"
                  onClick={(e) => setTerms(e.target.checked)}
                />
                <label htmlFor="agreement_checkbox">
                  J'accepte les <Link to="/cgv">CGV</Link>
                </label>
              </div>
            </div>
            <br />
            <div className="register-buttons">
              <button
                type="button"
                className={"btn blue-btn rounded-corner btn-block btn-lg"}
                style={{ justifyContent: "center" }}
                disabled={!filledFields()}
                onClick={sender}
              >
                S'inscrire
              </button>
            </div>
            <div className="m-t-20 m-b-10 p-b-10 text-light">
              Déjà membre ?{" "}
              <Link to="/login">Cliquez ici pour vous connecter.</Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default withRouter(RegisterForm);
