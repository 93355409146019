import React from 'react';
import { Link } from 'react-router-dom';
import PageNotFound from './img/gallery/404.jpg';



const NotFound = () => (
<div>
<img src={PageNotFound} style={{ position:'static' ,height:'100vh' ,width:'100vw' }} />
<center><Link to="/">Return to Home Page</Link></center>
</div>
);
export default NotFound;