import React from 'react';
import { useParams } from 'react-router-dom';
import Profile from './Profile';

const OtherProfile = ({ match , history }) => {
  const { id } = useParams();
  return (
    <Profile match={match} history={history} userId={id} />
  );
};

export default OtherProfile;
