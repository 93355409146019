import React from 'react';

const ScrollToTop = () => {
    return (
        <div className="btn btn-icon btn-circle btn-success btn-scroll-to-top fade " data-click="scroll-top">
            <i className="material-icons">keyboard_arrow_up</i>
        </div>
    );
};

export default ScrollToTop;