import React, {useState} from "react";
import { Link } from "react-router-dom";
import NotifDropdown from "./NotifDropdown";
import ProfileDropdown from "./ProfileDropdown";
import { Container, Nav, Navbar } from "react-bootstrap";
import Logo from '../../assets/img/logo.svg';
import { ContainerOutlined } from '@ant-design/icons'
import AnnouncerModal from "../../pages/Announcer/announcerModal";
import { getMyself } from "../../utils/getSetUser";


const TopHeader = ({ annonceur, notifications, getdata, refresh, setRefresh }) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = getMyself();
   
  return (
    <>
    <Navbar collapseOnSelect bg="light" expand="xl" className="header navbar-default" >
      <Container fluid>
        <Navbar.Brand href="/home">
          <img src={Logo} alt="My SocialBat" />
          MySocialBat
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Nav.Link as={Link} to="/home">
              Fil d'actualité
            </Nav.Link>
            <Nav.Link as={Link} to="/announces">
              Petites annonces
            </Nav.Link>
            <Nav.Link as={Link} to="/library">
              Bibliothèque
            </Nav.Link>
            <Nav.Link as={Link} to={{ pathname: "/partners", state: { user: user } }}>
              Entreprises partenaires
            </Nav.Link>
            <Nav.Link as={Link} to="/market">
              Boutique
            </Nav.Link>
            <Nav.Link as={Link} to={{ pathname: "/adresses", state: { user: user, announcer: annonceur } }}>
              Adresses utiles
            </Nav.Link>
          </Nav>
          <Nav className="d-flex">
            {annonceur ? (
              annonceur.user ? (
                <Nav.Link
                  as={Link}
                  to={{
                    pathname: `/announcer/${annonceur._id}`,
                    state: { announcer: annonceur, user: user },
                  }}
                  className="announcer-btn"
                >
                  Espace Annonceur
                </Nav.Link>
              ) : (
              <div onClick={() => setOpenModal(true)}>
                <ContainerOutlined/>
              </div>
              )
            ) : null}
            {user ? (
              <NotifDropdown
                user={user}
                notifications={notifications}
                getdata={getdata}
              />
            ) : null}
            <ProfileDropdown user={user} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      { openModal === true  ? <AnnouncerModal loading={loading} user={user} getdata={getdata} refresh={refresh} openModal={setOpenModal} setRefresh={setRefresh} show={user && annonceur && annonceur?.user?._id === user?._id ? false : true} /> : null} 
      </>
);
};

export default TopHeader;
