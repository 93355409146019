import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import NewAnnouncer from "../../pages/Announcer/NewAnnouncer";

const Offre = ({
  user,
  announcer,
  logo,
  coverImage,
  isLogoLoaded,
  isCoverImageLoaded,
  onChangelogo,
  onChange,
  onChangeAddress,
  onChangeCoverImage,
  onCancel,
}) => {
  const allInformations = (announcer) => {
    if (
      announcer.nom &&
      announcer.email &&
      announcer.activite &&
      announcer.phone &&
      announcer.siret &&
      announcer.tranche &&
      announcer.website &&
      announcer.description &&
      announcer.pictureUrl &&
      announcer.address.number &&
      announcer.address.street &&
      announcer.address.postcode &&
      announcer.address.city &&
      announcer.address.country
    ) {
      return false;
    }
    return true;
  };

  return (
    <Row>
      <Col xs={12}>
        {announcer ? (
          <div className="announcer-info-wrapper">
            <center>
              <h3>Mes informations</h3>
            </center>
            <NewAnnouncer
              announcer={announcer}
              user={user}
              isLogoLoaded={isLogoLoaded}
              isCoverImageLoader={isCoverImageLoaded}
              onChangelogo={onChangelogo}
              onChange={onChange}
              onChangeAddress={onChangeAddress}
              onChangeCoverImage={onChangeCoverImage}
              onCancel={onCancel}
              logo={logo}
              coverImage={coverImage}
            />
          </div>
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 290, marginBottom: 350 }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </Col>
      <Col>
        {allInformations(announcer) && (
          <div className="alert alert-warning" role="alert">
            Attention ! Veuillez remplir toutes les informations requises et
            votre logo pour pouvoir prendre un abonnement sur MySocialBat.
          </div>
        )}
      </Col>
    </Row>
  );
};

export default Offre;
