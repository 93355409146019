import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Pdf from "react-to-pdf";
import apiService from "../services/apiServices";

const ref = React.createRef();

const Facture = ({ match }) => {
  const [factureId, setFactureId] = useState(match.params.id || null);
  const [facture, setFacture] = useState();
  const [announcer, setAnnouncer] = useState();

  useEffect(() => {
    (async () => {
      try {
        const bill = await apiService.getBill(factureId);
        const annonceur = await apiService.getAnnonceur(bill.announcer);
        setFacture(bill);
        setAnnouncer(annonceur);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [factureId]);

  const formatDate = () => {
    let day = new Date(facture.creation_date);
    let date = `${day.getDate()}/${parseInt(
      day.getMonth() + 1
    )}/${day.getFullYear()}`;
    return date;
  };

  return (
    <div>
      <div style={{ margin: "20px" }} ref={ref}>
        <Card style={{ padding: "50px" }}>
          <Card.Body>
            <Card.Title style={{ fontSize: "50px", color: "#0A298A" }}>
              FACTURE
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Mysocialbat
            </Card.Subtitle>

            <Container>
              <Row style={{ marginTop: "50px", marginBottom: "50px" }}>
                <Col>
                  <h2 style={{ color: "#0A298A" }}>Facturé A</h2>
                  <p>{announcer.nom}</p>
                </Col>
                <Col>
                  <h2 style={{ color: "#0A298A" }}>Facture N°</h2>
                  <h2 style={{ color: "#0A298A" }}>Date</h2>
                </Col>
                <Col>
                  <p>{facture._id}</p>
                  <p>{formatDate()}</p>
                </Col>
              </Row>
              <Row>
                <Table responsive>
                  <thead>
                    <tr>
                      <th style={{ color: "#0A298A" }}>DESIGNATION</th>
                      <th style={{ color: "#0D1E4F" }}>MONTANT (€)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{facture.description}</td>
                      <td>{facture.amount}</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
              <Row style={{ marginTop: "50px", marginBottom: "50px" }}>
                <Col lg={{ span: 6, offset: 6 }}>
                  <Table responsive>
                    <tfoot>
                      <tr>
                        <td>
                          <h2 style={{ color: "#0A298A" }}>TOTAL</h2>
                        </td>
                        <td>
                          <h2 style={{ color: "#0A298A" }}>
                            {facture.amount}€
                          </h2>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </div>
      <div className="d-flex justify-content-center">
        <Pdf targetRef={ref} filename="facture.pdf">
          {({ toPdf }) => (
            <button className="btn btn-primary" onClick={toPdf}>
              Télécharger
            </button>
          )}
        </Pdf>
      </div>
    </div>
  );
};

export default Facture;
